import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { format } from 'date-fns';
import Image1 from "../../../assets/images/DSC_7293-17169.jpg";
import Image2 from "../../../assets/images/132566552488339083.jpg";
import Image3 from "../../../assets/images/login-right-bg.png";
import "../proof_card/index.css";
import { PROOF_OF_PLAY, PROOF_OF_PLAY_ASSERTS } from "../../../Graphql/Queries";
import { useQuery } from "@apollo/client";

interface ProofOfPlay {
    id: string;
    name: string;
    dealId: string;
    dsp: string;
    bookingSource: string;
    campaignInventories: CampaignInventory[];
}

interface ProofOfPlayProps {
    campaignId: string;
}

interface CampaignInventory {
    id: string;
    inventoryName: string;
    inventoryId: string;
    inventoryType: string;
    inventoryAddress: string;
    inventoryVenueType: string;
    inventoryThumbnailUrl: string;
    inventoryPrice: number;
    inventoryReports: {
        totalPotentialViews: number;
        averageFrequency: number;
        cpm: number;
        totalReach: number;
    };
    inventoryLatitude: number;
    inventoryLongitude: number;
}

const ProofOfPlay: React.FC<ProofOfPlayProps> = ({ campaignId }) => {
    const [mainImage, setMainImage] = useState(Image1);
    const [showModal, setShowModal] = useState(false);
    const [redirect, setRedirect] = useState<string | null>(null);
    const [loadingMedia, setLoadingMedia] = useState(true);
    const selectedCampaignId = localStorage.getItem('selectedCampaignId');
    const selectedInventoryId = localStorage.getItem('selectedInventoryId');
    const [images, setSelectedImage] = useState<string[]>([]); // To hold image URLs
    const [proofValues, setProofValues] = useState<ProofOfPlay | null>(null);
    const token = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyId');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [createDate, setCreateDate] = useState<string[]>([]);  // Change this to an array


    // Use the useLocation hook to access passed state
    const locations = useLocation();
    const { startDate: passedStartDate, endDate: passedEndDate } = locations.state || {};

    const formatDate = (date: Date) => {

        return format(date, 'yyyy-MM-dd');
    };



    useEffect(() => {
        if (passedStartDate && passedEndDate) {
            setStartDate(formatDate(passedStartDate));
            setEndDate(formatDate(passedEndDate));
        }

        console.log(startDate, "selva c");

    }, [passedStartDate, passedEndDate]);

    const location = useLocation();
    const userData = location.state;

    // Proof Of Play Asserts Api
    const { data: assertsData, loading: assertsLoading, error: assertsError } = useQuery(PROOF_OF_PLAY_ASSERTS, {
        variables: {
            accessToken: token,
            metadataCampaignId: campaignId,
            metadataMediaOwnerId: userId,
            metadataType: "LMX_POC",
            source: "LMX_COMMERCE",
            startDate: startDate,
            endDate: endDate || null,
            page: 0,
            size: 8,
            sort: "lastModifiedDate,desc",
            billboardId: userData?.newInventoryId || '',
        }


    });

    useEffect(() => {
        if (assertsData?.proofOfPlayAssets) {
            console.log('User Data from Location:', userData);

            // Always show all assets, no need for filtering
            const thumbnails = assertsData.proofOfPlayAssets.map((asset: { thumbnail: any; }) => asset.thumbnail);

            // Set unique creation dates for each asset
            const uniqueDates = assertsData.proofOfPlayAssets.map((item: any) => {
                const date = new Date(item.createdDate);
                return date.toLocaleDateString(); // Format date as 'MM/DD/YYYY'
            });

            setCreateDate(uniqueDates); // Store dates for each asset
            setSelectedImage(thumbnails); // Set the images to be displayed
            console.log(assertsData, "thumbnailsthumbnails");

        } else {
            console.log("No proofOfPlayAssets found.");
        }
    }, [assertsData]); // Re-run when assertsData changes

    const { data, loading, error } = useQuery(PROOF_OF_PLAY, {
        variables: {
            accessToken: token,
            page: 0,
            campaignId: campaignId,
            metadataType: "LMX_POCLMX_COMMERCE",
            metadataCampaignId: campaignId,
            metadataMediaOwnerId: userId,
            source: "LMX_COMMERCE",
            startDate: startDate,
            endDate: endDate,
            size: 8,
            sort: "lastModifiedDate,desc",
        },
        skip: !token || !userId || !companyId,
    });

    useEffect(() => {
        if (loading) return;
        if (error) {
            console.error("Error fetching campaigns:", error);
            return;
        }

        if (data && data.proofOfPlay) {
            setProofValues(data.proofOfPlay);
            setStartDate(data.proofOfPlay.startDate.date);
            setEndDate(data.proofOfPlay.endDate.date);
        }
    }, [data, loading, error]);

    if (loading || assertsLoading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: '40vh' }}>
                <span className="loading-circle sp1">
                    <span className="loading-circle sp2">
                        <span className="loading-circle sp3"></span>
                    </span>
                </span>
            </div>
        );
    }

    const handleImageClick = (filePath: string) => {

        console.log("File clicked:", filePath); // Log the clicked file path
        setMainImage(filePath);  // Set the clicked file path
        console.log("Main Image:", filePath);  // Verify the value of mainImage
        setShowModal(true);  // Show the modal when a file is clicked
    };






    const closeModal = () => {
        setShowModal(false);
    };

    const scrollThumbnails = (direction: "left" | "right") => {
        const container = document.getElementById("thumbnailsContainer");
        const scrollAmount = 150;

        if (container) {
            container.scrollBy({ left: direction === "left" ? -scrollAmount : scrollAmount, behavior: "smooth" });
        }
    };

    if (redirect) {
        return <Navigate to={redirect} />;
    }

    return (
        <div className="container">
            <div className="row proof-card-scroll">
                <h1 className="proof-img-header">
                    Choose from {images.length > 0 ? images.length : 'No'} sites


                </h1>

                <h1 style={{ paddingTop: '20px' }} className="proof-img-header">{images.length > 0 ? images.length : 'No'} available content</h1>


                {images.map((asset, index) => (
                    <div className="col-md-4 mb-4 proof-cards" key={index}>
                        <div className="card border-0">
                            <img
                                src={asset}
                                className="card-img-top proof-img-size"
                                alt={`Campaign Inventory ${index + 1}`}
                            />
                            <div className="card-body proof-card-body">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h5 className="card-title proof-card-title Campaign-Inventory">

                                            {data?.proofOfPlay?.campaignInventories
                                                .find((inventory: {
                                                    id: any; inventoryId: string
                                                }) =>
                                                    inventory.id === assertsData?.proofOfPlayAssets[index]?.metadata?.billboardId)
                                                ?.inventoryName || 'Unknown Inventory'}
                                        </h5>




                                        <p className="proof-card-data Uploaded-Inventory">
                                            {createDate.length > 0 && createDate[index]
                                                ? `Uploaded on ${new Date(createDate[index]).toLocaleDateString('en-US', {
                                                    year: 'numeric', month: 'short', day: 'numeric'
                                                })}`
                                                : "No date available"}
                                        </p>
                                    </div>
                                    <div className="col-md-4 img-view-button">
                                        <button
                                            className="btn btn-primary proof-card-btn"
                                            onClick={() => handleImageClick(assertsData.proofOfPlayAssets[index].filePath)}
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {showModal && (
                <div className="modal fade show proof-popup-back" tabIndex={-1} role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content proof-popup-con">
                            <div className="modal-body">
                                {/* Close button */}
                                <div className="btn-close1">
                                    <button
                                        type="button"
                                        className="btn-close btn-close-white"
                                        onClick={closeModal}  // Close the modal when clicked
                                        aria-label="Close"
                                    ></button>
                                </div>

                                {/* Render video or image based on file type */}
                                {mainImage && (
                                    console.log("Rendering media:", mainImage),
                                    mainImage.toLowerCase().endsWith(".mp4") ? (
                                        <video controls autoPlay muted className="modal-img img-fluid">
                                            <source src={mainImage} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <img src={mainImage} alt="Selected media" className="modal-img img-fluid" />
                                    )
                                )}



                                {/* Thumbnails container inside the modal */}
                                <div className="thumbnails-container-wrapper proof-popup-con2">
                                    <div className="thumbnails-container" id="thumbnailsContainer">
                                        {assertsData.proofOfPlayAssets.map((asset: any, index: any) => (
                                            <img
                                                src={asset.thumbnail}  // Thumbnail image here
                                                className="thumbnail-img"
                                                alt={`Thumbnail ${index + 1}`}
                                                key={index}
                                                onClick={() => handleImageClick(asset.filePath)}  // Pass the filePath to handleImageClick
                                            />
                                        ))}
                                    </div>
                                </div>

                                {/* Arrow buttons for scrolling thumbnails */}
                                <div className="arrow-buttons-container">
                                    <span className="arrow-icon-left" onClick={() => scrollThumbnails("left")}>&lt;</span>
                                    <span className="arrow-icon-right" onClick={() => scrollThumbnails("right")}>&gt;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default ProofOfPlay;
