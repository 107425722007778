import React, { Component } from "react";
import AuthLayout from "../../layouts/auth";
import './index.css';

import PropertyList_navbar from "../../components/property-list/property-nav/index";
import BillBoardListing from "../../components/property-list/property-search/index";

class PropertyDetails extends Component {
    state = {
        filterChildData: {}
    }
    constructor(props: {}) {
        super(props);
        // this.state = {
        //     redirect: null,
        // }
    }
    handleCallback = (childData: any) => {
        this.setState({ filterChildData: childData });
    }

    render() {
        return (
            <div >
                <AuthLayout>
                    <>
                        <div className="container p-0">
                            <PropertyList_navbar parentCallback={this.handleCallback} />
                            <BillBoardListing filterData={this.state.filterChildData} />
                        </div>
                    </>
                </AuthLayout>
            </div>
        );
    }
}
export default PropertyDetails;
