import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import './index.css';
import { useMutation } from "@apollo/client";
import { FORGET_PASSWORD } from "../../../Graphql/Queries";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const ForgotPasswordForm: React.FC = () => {
  const [forgot, { data, loading, error }] = useMutation(FORGET_PASSWORD);
  const [email, setEmail] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [submit, setSubmit] = useState(false);
  const token = localStorage.getItem('authToken');




  const handleBackToLogin = () => {
    setRedirect(true);
  };

  const isFormValid = () => {
    return email;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/i)) {
      return;
    }
    setSubmit(true)
    try {
      const { data } = await forgot({
        variables: {
          email,
          platformSource: "ECOMMERCE",
          platformBaseUrl: "https://test-bigoutdoor.lmx.ai",
          publisherId: "642685a0421c2678de8421ac"
        }
      });

      if (data && data.forgetPassword) {
        const message = data.forgetPassword.message;
        setSubmit(false)
        if (message === "Provided email address is not registered") {
          toast.error("The email address is not registered. Please check and try again.");
        } else if (message === "error.validation") {
          toast.error("An error occurred. Please try again.");
        } else {
          toast.success(message);
        }
      }



    } catch (error) {
      console.error("Error sending password reset link:", error);
      toast.error("Failed to send password reset link. Please try again.");
    }
  };

  if (redirect) {
    return <Navigate to="/sign-in" />;
  }

  return (
    <>
      <form >
        <div className="loginfilled-login-form-parent">
          <div className="image-bg-style">
            <div className='card cart-bg-style'>
              <div className="right-side-alignment">
                <h4 className="loginfilled-please-enter-your">Forgot Password?</h4>
                {error && <div className="error-message">{error.message}</div>}
                <div className="loginfilled-hatimbig-parent username-input-top">
                  <input
                    className="loginfilled-hatimbig"
                    placeholder="Enter your email"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div
                    className={`forgot-underline-border ${submit && !email || (email.length > 0 && !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/i)) ? 'error' : ''}`}
                  ></div>

                  {submit && email.length === 0 ? (
                    <div className="text-danger error-message-required">Email is required</div>
                  ) : (
                    <>
                      {email.length > 0 && !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/i) && (
                        <div className="text-danger">Invalid email format</div>
                      )}
                    </>
                  )}
                </div>
                <div className="loginfilled-login-btn forgot">
                  <button
                    onClick={handleSubmit}

                    type="submit"
                    disabled={!isFormValid()}

                    className={`${!isFormValid() ? 'disabled' : ''
                      }`}  >
                    {loading ? (
                      <div className="spinner-loading">
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </div>
                    ) : (
                      "Send Link"
                    )}
                  </button>
                </div>

                <div className="forgot-login-btn">
                  <button type="button" onClick={handleBackToLogin}>Back to login</button>
                </div>

                <div className="register-link">
                  <span className="forgot-not-a-member">Not a member yet? </span>
                  <Link to="/sign-up" className="loginfilled-register-now" id="signup-link">Register now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
