import React, { Component, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Image1 from "../../../assets/images/DSC_7293-17169.jpg";
import Image from "../../../assets/images/132566552488339083.jpg";
import Img from "../../../assets/images/login-right-bg.png";
import { EXPLORE_PROPERTY_DETAILS, PROPERTY_DETAILS } from "../../../Graphql/Queries";
import client from "../../../Graphql/apolloClient";
import { GET_LANDING_EXPLORATION } from "../../../Graphql/PropertyListQueries";
import { format } from 'date-fns';

interface PropertyDetails {
  mainImage: string;
  redirect: string | null;
  thumbnailPath: string;
}

interface PropertyDetails_props {
  assest_img: Boolean
}

const ExplorePropertyDetailsGallery: React.FC<PropertyDetails_props> = ({ assest_img }) => {
  const [redirect, setRedirect] = useState<string | null>(null);
  const [propertyDetailsStoreValue, setPropertyDetailsStoreValue] = useState<PropertyDetails[]>([]);
  const [mainImage, setMainImage] = useState<string>(Img); // Default main image
  const location = useLocation();
  const propertyDetailsValue = location.state?.propertyDetailsData || {};
  const [loading, setLoading] = useState(false);
  const companyId = localStorage.getItem("companyId");
  const countryId = localStorage.getItem("countryId");
  const userId = localStorage.getItem("userId");
  const currentDate = new Date();

  const [startDate, setStartDate] = useState<Date>(propertyDetailsValue?.startDate || currentDate);
  const [endDate, setEndDate] = useState<Date>(propertyDetailsValue?.endDate || currentDate);


  const fetchPropertyDetailsGallery = async () => {
    setLoading(true);
    try {
      const { data } = await client.query({
        query: EXPLORE_PROPERTY_DETAILS,
        variables: {
          id: propertyDetailsValue?.id,
          companyId: companyId || '642685a0421c2678de8421ac',
          countryId: countryId || '573aa8c388041e1667e335d1',
          billboardType: propertyDetailsValue?.type.toLowerCase(),

        },
      });

      const propertyDetails = data?.propertyDetailsWithoutLogin;
      console.log("Fetched ExploreProperty Details details----->:", propertyDetails);
      const billboardObjectId = propertyDetails?.billboardObjectId;
      if (billboardObjectId) {
        await fetchBillBoardList(billboardObjectId);
      }
    } catch (error) {
      console.error("Error fetching property details:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchBillBoardList = async (billboardObjectId: string, cartItems: any = "") => {
    setLoading(true);
    try {
      // console.log("Fetching Billboards for ID: ", billboardObjectId);
      setBillBoardData([]);

      const filterData = {
        type: propertyDetailsValue?.type,
        availableBooking: true,
        categories: [],
        format: [],
        venueType: [],
      };
      const lowercaseType = propertyDetailsValue?.type?.toLowerCase();

      const { data } = await client.query({
        query: GET_LANDING_EXPLORATION,
        variables: {
          sort: "last_modified_date,desc",
          billboard: lowercaseType,
          userId: userId,
          companyId: companyId || '642685a0421c2678de8421ac',
          page: 0,
          size: 12,
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          dspName: "LMX-ECOMMERCE",
          countryId: countryId || '573aa8c388041e1667e335d1',
          searchTerm: "",
          filterData: filterData,
        },
      });

      if (data) {
        // console.log("Billboard data testing: ", data);
        let resultObj = data.landingpageExplorePropertiesNotoken;
        if (resultObj.length > 0 && cartItems.length > 0) {
          resultObj = resultObj.map((obj: any) => {
            const isInCart = cartItems.some((cartItem: any) => cartItem.cartItemId === obj.id);
            return { ...obj, isInCart };
          });
        }
        setBillBoardData(resultObj);
        const matchedBillboard = resultObj.find((billboard: any) => billboard.id === billboardObjectId);
        if (matchedBillboard) {
          setPropertyDetailsStoreValue([matchedBillboard]);
          setMainImage(matchedBillboard?.thumbnailPath);
        }
      }
    } catch (error) {
      console.error("Error fetching billboard list:", error);
    } finally {
      setLoading(false);
    }
  };

  const [billBoardData, setBillBoardData] = useState<any>([]);
  useEffect(() => {
    fetchPropertyDetailsGallery();
  }, [])

  if (redirect) {
    return <Navigate to={redirect} />;
  }


  const handleImageClick = (newImage: string) => {
    setMainImage(newImage); // Update the main image on click
  };


  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6">
          <img
            id="mainImage"
            src={mainImage} // Dynamic image
            className="main-image property-main-image"
            alt="Main Image"
          />
        </div>

        <div className="col-md-6 thumb-container property-thumb-container">
          {assest_img && (
            <div className="row">
              {propertyDetailsStoreValue.map((val, index) => (
                <div className="col-4" key={index}>
                  <img
                    src={val.thumbnailPath || Image1}
                    className="thumb-image property-thumb-img"
                    alt={`Thumbnail ${index + 1}`}
                    onClick={() => handleImageClick(val.thumbnailPath)} // Update main image on click
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );


}


export default ExplorePropertyDetailsGallery;
