import React, { useEffect, useState } from "react";
import LandingLayout from "../../layouts/landing";
import { Link } from "react-router-dom";
import LogoImage from "../../assets/brand/home-logo.svg";
import LandingImage from '../../assets/images/mask-group@2x.png';
import LandingContent from "../../components/home/landing-image-content";
import ExploreProperties from "../../components/home/explore-properties";
import PropertiesCarousel from "../../components/home/properties-carousel";
import ClientServed from "../../components/home/client-served";
import ClientSays from "../../components/home/client-says";
import QuestionForm from "../../components/home/question-form";
import './index.css';

const LandingPage = () => {
  const [config, setConfig] = useState<any[]>([]);
  const [content, setContent] = useState<any[]>([]);
  const [contactUsConfig, setContactUsConfig] = useState<any[]>([]);
  const [showFooter, setShowFooter] = useState<boolean>(false);
  const [headline, setHeadline] = useState<string>("");
  const [subHeadline, setSubHeadline] = useState<string>("");
  const [headerLogo, setHeaderLogo] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [facebook, setFacebook] = useState<string>("");
  const [twitter, setTwitter] = useState<string>("");
  const [insta, setInsta] = useState<string>("");
  const [backgroundImages, setBackgroundImage] = useState('');
  const [contactImage, setContactImage] = useState('');
  const [authToken, setAuthToken] = useState<string>("");
  // Fetch content data
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/json/contentJson.json`);
        const data = await response.json();
        const value = data.find((page: any) => page.name === "Landing Page");
        setContent(value.fields);
        console.log("Content Data:", value.fields);
        const headlineField = value.fields.find((field: any) => field.key === "headline");
        const subHeadlineField = value.fields.find((field: any) => field.key === "sub headline");
        const headerLogoField = value.fields.find((field: any) => field.key === "panel");
        const clientLogogField = value.fields.find((field: any) => field.title === "Clients we have served");
        const clientImgField = value.fields.find((field: any) => field.title === "Testimonials");
        const addressField = value.fields.find((field: any) => field.key === "address");
        const emailField = value.fields.find((field: any) => field.key === "email");
        const socialMediaLinkField = value.fields.find((field: any) => field.key === "SocialmediaLinks");
        const [facebook, twitter, instagram] = socialMediaLinkField.components[0].components.map((component: { defaultValue: any; }) => component.defaultValue);
        const bgImageField = value.fields.find((field: any) => field.key === "Backgroundimage");
        if (bgImageField && bgImageField.defaultValue.length > 0) {
          const imageUrl = bgImageField.defaultValue[0].data.url;
          setBackgroundImage(bgImageField.url + imageUrl);
        }
        const contactImageField = value.fields.find((field: any) => field.key === "Contactusform");
        setContactImage(contactImageField.components[0].components[3].url + contactImageField.components[0].components[3].defaultValue[0].url);


        console.log(clientLogogField.components[1].components, 'client');
        console.log(clientImgField.components[2].defaultValue.employeeInformation, 'employeInfo');
        console.log(clientImgField.components[2].defaultValue.testimonialEntries, 'testentries');
        const token: any = localStorage.getItem('authToken');
        setAuthToken(token);
        setFacebook(facebook);
        setTwitter(twitter);
        setInsta(instagram);
        setAddress(addressField.defaultValue);
        setEmail(emailField.defaultValue);
        setHeadline(headlineField.defaultValue);
        setSubHeadline(subHeadlineField.defaultValue);
        setHeaderLogo(headerLogoField);
      } catch (error) {
      }
    };

    fetchContent();
  }, []);

  // Fetch configuration data
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/json/configJson.json`);
        const data = await response.json();
        const landingPageConfig = data.find((page: { name: string; }) => page.name === "Landing Page");
        const contactusConfig = data.find((page: { name: string; }) => page.name === "Contact Us");
        setConfig(landingPageConfig.configurations || []);
        setContactUsConfig(contactusConfig.configurations || []);

        const footerConfig = landingPageConfig.configurations.find((item: { key: string; }) => item.key === "footer");
        console.log("Config Data:", landingPageConfig.configurations);
        setShowFooter(footerConfig ? footerConfig.default : true);

        console.log("Contact Us Config:", contactusConfig.configurations);
      } catch (error) {
        console.error("Error fetching config data:", error);
      }
    };

    fetchConfig();
  }, []);

  const isFieldEnabled = (key: string, config: any[]) => {
    const fieldConfig = config.find((field) => field.key === key);
    return fieldConfig ? fieldConfig.default : true;
  };
  const isFieldEnabledGeneric = (key: string) => isFieldEnabled(key, config);
  const isContactUsFieldEnabledGeneric = (key: string) => isFieldEnabled(key, contactUsConfig);
  const isLandingImageEnabled = isFieldEnabledGeneric("do you need an image in your landing page");
  return (
    <LandingLayout>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <nav className="navbar navbar-expand-lg">
              <Link to={`${process.env.REACT_APP_BASE_PATH}/landing-page`}>
                {isFieldEnabledGeneric("logo") && (
                  <img className="logo-img" src={headerLogo || LogoImage} alt="Your Logo" />
                )}
              </Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <form className=" nav-btn landing-nav">
                  {isFieldEnabledGeneric("Explore") && (
                    <Link to={`${process.env.REACT_APP_BASE_PATH}/explore-property`}><button className="landing-btn btn-outline-primary" type="button">Explore</button></Link>)}
                  <Link to={`${process.env.REACT_APP_BASE_PATH}/sign-in`}>
                    {isFieldEnabledGeneric("login") && (
                      <button className="landing-btn login-btn" type="button">Login</button>
                    )}
                  </Link>
                </form>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="landing-bg">
        {/* {isFieldEnabledGeneric("do you need an image in your landing page") && (
          <> */}
        <div
          className={`landing-img ${isLandingImageEnabled ? 'show-image' : ''}`}
          style={{
            backgroundImage: isLandingImageEnabled && backgroundImages ? `url(https://oss-2o-builder-public.s3.ap-southeast-2.amazonaws.com/production/blob.jpg)` : '',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat', margin: 'auto', width: '100%', height: '100%'
          }}
        > <div className="container">
            <div className="row">
              <div className="col-md-12">
                <LandingContent
                  showHeadline={isFieldEnabledGeneric("headline")}
                  showSubHeadline={isFieldEnabledGeneric("sub headline")}
                  headline={headline}
                  subHeadline={subHeadline}
                />
              </div>
            </div>
          </div>
        </div>
        {/* </>
        )} */}
        <div className="container">
          {isFieldEnabledGeneric("featured properties") && (
            <div className="explore-pro">
              <div className="row">
                <div className="explore-cnt1 col-md-6">
                  <h2>Explore properties</h2>
                </div>
                <div className="explore-cnt2 col-md-6">
                  {true ? (
                    <p><Link to={`${process.env.REACT_APP_BASE_PATH}/explore-property`}>View all</Link></p>) : (
                    <p data-bs-toggle="modal" data-bs-target="#redirectToLoginModal" >View all</p>
                  )}
                </div>
              </div>
              <ExploreProperties />
            </div>
          )}
          {isFieldEnabledGeneric("popular properties") && (
            <div className="properties-carousel row">
              <div className="col-md-12">
                <h2>Properties with 1 million views</h2>
              </div>
              <PropertiesCarousel />
            </div>
          )}
          {isFieldEnabledGeneric("clients we have served") && (
            <div className="client-served-cnt row">
              <div className=" col-md-12">
                <h2>Clients we’ve served</h2>
              </div>
              <ClientServed />
            </div>
          )}
          {isFieldEnabledGeneric("clients testimonials") && (
            <div className="client-says-cnt row">
              <div className="col-md-12">
                <h2>What our clients say</h2>
              </div>
              <div className="client-says-cnt-content col-md-12">
                <ClientSays />
              </div>
            </div>
          )}
          <div className="row">
            {isFieldEnabledGeneric("start a campaign") && (
              <div className="client-start col-md-12 text-center">
                {true ? (<Link to={`${process.env.REACT_APP_BASE_PATH}/sign-in`}><button className="btn btn-primary">Start Campaign</button></Link>) : (
                  <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#redirectToLoginModal">Start Campaign</button>
                )}
              </div>
            )}
          </div>


          <div className="question-cnt row">
            {isFieldEnabledGeneric("contact form") && (<>
              <div className="col-md-6">
                <div className="question-cnt-left">
                  <h1>Any<br /> questions?</h1>
                  <p>Write to us. We will get <br />back to you within 24 hrs.</p>
                </div>
              </div>
              <QuestionForm
                showEmailField={isFieldEnabledGeneric("contact email")}
                showContactImg={isFieldEnabledGeneric("add image for contact us")}
                showNamelField={isContactUsFieldEnabledGeneric("fullName")}
                showMessageField={isContactUsFieldEnabledGeneric("message")}
                contactImageField={contactImage}
              />
            </>)}
          </div>


        </div>

        {showFooter && (
          <div className="footer-cnt">
            <div className="container">
              <div className="footer-container">
                <div className="row">
                  <div className="footer-left-cnt col-md-6">
                    <p>Contact</p>
                    {isContactUsFieldEnabledGeneric("e-mail") && (
                      <h5>{email}</h5>
                    )}
                  </div>

                  <div className="footer-right-cnt col-md-6">
                    {isFieldEnabledGeneric("social media links") && (
                      <>
                        <a href={facebook}><i className="fa-brands fa-facebook"></i></a>
                        <a href={twitter}><i className="fa-brands fa-twitter"></i></a>
                        <a href={insta}><i className="fa-brands fa-instagram"></i></a>
                      </>
                    )}

                  </div>
                  <div className="footer-left-cnt col-md-6">
                    {isFieldEnabledGeneric("address") && (
                      <>
                        <p>Address</p>
                        <h5>{address}</h5>
                      </>
                    )}
                  </div>
                  <div style={{ cursor: 'pointer' }} className="footer-right-cnt col-md-6" >
                    <p>
                      {isFieldEnabledGeneric("terms&conditions") && (
                        <span data-bs-toggle="modal" data-bs-target="#terms&condition">Terms & conditions</span>)}
                      {isFieldEnabledGeneric("privacy policy") && (
                        <span data-bs-toggle="modal" data-bs-target="#privacypolicy">| Privacy policy</span>)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}


      </div>



      <div className="modal fade" id="terms&condition" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header term-header">
              <h5 className="modal-title terms-title" id="exampleModalLabel">Terms & conditions</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body term-body">
              <p>Terms and Conditions for Company Name</p>
              <h5>Introduction</h5>
              <p>These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Webiste Name accessible at Website.com.</p>
              <p>These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.</p>
              <p>Minors or people below 18 years old are not allowed to use this Website.Intellectual Property Rights</p>
              <p>Other than the content you own, under these Terms, Company Name and/or its licensors own all the intellectual property rights and materials contained in this Website.You are granted limited license only for purposes of viewing the material contained on this Website.</p>
              <h5>Restrictions</h5>
              <p>You are specifically restricted from all of the following:</p>
              <ul>
                <li>publishing any Website material in any other media;</li>
                <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
                <li>publicly performing and/or showing any Website material;</li>
                <li>using this Website in any way that is or may be damaging to this Website;</li>
                <li>using this Website in any way that impacts user access to this Website;</li>
                <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
                <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</li>
                <li>using this Website to engage in any advertising or marketing.</li>
              </ul>
              <p>Certain areas of this Website are restricted from being access by you and Company Name may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.</p>
              <h5>Your Content</h5>
              <p>In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Contenroduce, adapt, publish, translate and distribute it in any and all media.</p>
            </div>
            <div className="modal-footer term-footer">
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="privacypolicy" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header term-header">
              <h5 className="modal-title terms-title" id="exampleModalLabel">Privacy Policy</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body term-body">
              <p>Terms and Conditions for Company Name</p>
              <h5>Introduction</h5>
              <p>These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Webiste Name accessible at Website.com.</p>
              <p>These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.</p>
              <p>Minors or people below 18 years old are not allowed to use this Website.Intellectual Property Rights</p>
              <p>Other than the content you own, under these Terms, Company Name and/or its licensors own all the intellectual property rights and materials contained in this Website.You are granted limited license only for purposes of viewing the material contained on this Website.</p>
              <h5>Restrictions</h5>
              <p>You are specifically restricted from all of the following:</p>
              <ul>
                <li>publishing any Website material in any other media;</li>
                <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
                <li>publicly performing and/or showing any Website material;</li>
                <li>using this Website in any way that is or may be damaging to this Website;</li>
                <li>using this Website in any way that impacts user access to this Website;</li>
                <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
                <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</li>
                <li>using this Website to engage in any advertising or marketing.</li>
              </ul>
              <p>Certain areas of this Website are restricted from being access by you and Company Name may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.</p>
              <h5>Your Content</h5>
              <p>In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Contenroduce, adapt, publish, translate and distribute it in any and all media.</p>
            </div>
            <div className="modal-footer term-footer">
            </div>
          </div>
        </div>
      </div>
      {/* <div className="modal fade" id="redirectToLoginModal" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header explore_property_filter_popup_header border-0" >
              <h5 className="modal-title">Warning !</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              You need to be login to view
              <div className="text-center">
                <Link to={`${process.env.REACT_APP_BASE_PATH}/sign-in`}><button className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Login</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}


    </LandingLayout>
  );
};

export default LandingPage;
