import React, { Component, useEffect, useRef, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import Image1 from "../../../assets/images/search.svg";
import Image2 from "../../../assets/images/group-212841306.svg";
import Image3 from "../../../assets/images/mappin.svg";
import Image6 from "../../../assets/images/cart-icon.svg";
import Image5 from "../../../assets/images/sliders@2x.png";
import Image7 from "../../../assets/images/calendar.jpg";
import Image8 from "../../../assets/images/vector-41.svg";
import Image9 from "../../../assets/images/group-21.svg";
import NoImage from "../../../assets/images/no-image.jpg";
import DatePicker from "react-datepicker";
import LogoImage from "../../../assets/brand/your-logo.svg";

import './index.css';
import { gql, useMutation, useQuery } from "@apollo/client";
import { GET_LANDING_EXPLORATION, GETPROPERTY_LIST } from "../../../Graphql/PropertyListQueries";
import client from "../../../Graphql/apolloClient";
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import { ADD_TO_CART_SUBMIT, DELETE_CART_SUBMIT, GET_MY_CART_ITEMS } from "../../../Graphql/cartQueries";
import { toast } from "react-toastify";
import { generateMockData } from "../../../Graphql/MockData";
import { AUTHENTICATE_MUTATION, CART_INITIAL_QUERY, GET_COUNTRY_LIST, GET_STATE_LIST, INVOICE, PROPERTY_LIST_VENUE_TYPE_LIST, SUBMIT_ENQUIRY } from "../../../Graphql/Queries";
import { Spinner } from "react-bootstrap";
type SubCategory = {
    id: string;
    name: string;
    value: string;
    child?: GrandSubCategory[];
};
type GrandSubCategory = {
    id: string;
    name: string;
    value: string;
};
type Category = {
    id: string;
    name: string;
    value: string;
    child: SubCategory[];
};
interface Inventory {
    dealId: string;
    inventoryVenueType: string;
    description: string;
    name: string;
    inventoryType: string;
    inventoryResolution: string;
    inventoryId: string;
    inventoryName: string;
    inventoryThumbnailUrl: string;
    inventoryAddress: string;
    inventoryReferenceId: string;
    inventoryReports: {
        totalPotentialViews: number;
    };
    inventoryLatitude?: number;
    inventoryLongitude?: number;
    nowPayPrice: any;
    reasonForRejection: string;

}
interface SssItem {
    id: string;
    cartItemId: string;
    [key: string]: any;
}
const BillBoardListingExploreProperty = (props: any) => {
    console.log("Parent to Child Data: ", props);
    const navigate = useNavigate();
    const [showMap, setShowMap] = useState(false);
    const [id, setId] = useState(null);
    const [billBoardType, setBillBoardType] = useState(null);
    const [showLocationFilters, setShowLocationFilters] = useState(false);
    const [showLocationVenue, setShowLocationVenue] = useState(false);
    const [showLocationSite, setShowLocationSite] = useState(false);
    const [showLocationBill, setShowLocationBill] = useState(false);
    const [showLocationPrice, setShowLocationPrice] = useState(false);
    const [showLocationFinancial, setShowLocationFinancial] = useState(false);
    const [showLocationAirport, setShowLocationAirport] = useState(false);
    const [activeCategory, setActiveCategory] = useState<Category | null>(null);
    const [activeSubCategory, setActiveSubCategory] = useState<SubCategory | null>(null);
    const [activeGradSubCategory, setActiveGradSubCategory] = useState<GrandSubCategory | null>(null);
    const [activeCategoryLocation, setActiveCategoryLocation] = useState<string | null>();
    const [activeCategorySite, setActiveCategorySite] = useState<string | null>();
    const [activeCategoryBill, setActiveCategoryBill] = useState<string | null>();
    const [activeCategoryPrice, setActiveCategoryPrice] = useState<string | null>();
    const [activeCategoryAirports, setActiveCategoryAirports] = useState<string | null>();
    const [activeCategoryTransit, setActiveCategoryTransit] = useState<string | null>();
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
    const [badges, setBadges] = useState<string[]>([]);
    const location = useLocation();
    const cardData = location.state || {};
    const formData = cardData?.formData || {};
    const cartformData = cardData?.cartformData || {};
    const currentDate = new Date();
    const monthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const monthendDate = new Date(currentDate?.getFullYear(), currentDate?.getMonth() + 1, 0);
    const [startDate, setStartDate] = useState<Date>(monthStartDate);
    const [endDate, setEndDate] = useState<Date>(monthendDate);
    const [showStartDatePicker, setShowStartDatePicker] = useState<boolean>(false);
    const [showEndDatePicker, setShowEndDatePicker] = useState<boolean>(false);
    const [tempStartDate, setTempStartDate] = useState<Date>(startDate);
    const [tempEndDate, setTempEndDate] = useState<Date>(endDate);
    const [showMonthPicker, setShowMonthPicker] = useState<boolean>(false);
    const [showYearPicker, setShowYearPicker] = useState<boolean>(false);
    const [selectedMonth, setSelectedMonth] = useState<number>(currentDate.getMonth());
    const [selectedYear, setSelectedYear] = useState<number>(currentDate.getFullYear());
    //selectDatepopup
    const [startDates, setStartDates] = useState<Date>(monthStartDate);
    const [endDates, setEndDates] = useState<Date>(monthendDate);
    const [showStartDatePickers, setShowStartDatePickers] = useState<boolean>(false);
    const [showEndDatePickers, setShowEndDatePickers] = useState<boolean>(false);
    const [tempStartDates, setTempStartDates] = useState<Date>(startDate);
    const [tempEndDates, setTempEndDates] = useState<Date>(endDate);
    const [showMonthPickers, setShowMonthPickers] = useState<boolean>(false);
    const [showYearPickers, setShowYearPickers] = useState<boolean>(false);
    const [selectedMonths, setSelectedMonths] = useState<number>(currentDate.getMonth());
    const [selectedYears, setSelectedYears] = useState<number>(currentDate.getFullYear());
    const [campaignName, setCampaignName] = useState<string>(
        formData?.campaignName || cartformData?.campaignName || 'Default Campaign'
    );
    const [billBoardDataLoading, setBillBoardDataLoading] = useState(false);
    const [billBoardData, setBillBoardData] = useState<any>([]);
    const [soldCampaignViewDetails, setSoldCampaignViewDetails] = useState<any>({});
    const [cartData, setCartData] = useState<any>([{}]);
    const [cartCount, setCartCount] = useState(0);
    const [addtoCartQuery] = useMutation(ADD_TO_CART_SUBMIT);
    const [deleteCartQuery] = useMutation(DELETE_CART_SUBMIT);
    const [billboardType, setBillboardType] = useState("digital");
    const dataListLength = 12;
    const [totalDataLength, setTotalDataLength] = useState(0);
    const [pageStartData, setPageStartData] = useState(0);
    const [pageEndData, setPageEndData] = useState(0);
    const [locationData, setLocationData] = useState<string[]>([]);
    const [venueTypeData, setVenueTypeData] = useState<string[]>([]);
    const [siteTypeData, setSitetypeData] = useState<string[]>([]);
    const [typeData, setTypeData] = useState<string[]>([]);
    const mapContainer = useRef<HTMLDivElement>(null);
    const [mapLat, setMapLat] = useState<number>(40.7127281);
    const [mapLng, setMapLng] = useState<number>(-74.0060152);
    const [map, setMap] = useState<mapboxgl.Map | null>(null);
    const [marker, setMarker] = useState<mapboxgl.Marker | null>(null);
    const [selectedInventory, setSelectedInventory] = useState<Inventory | null>(null);
    const [currentPopup, setCurrentPopup] = useState<mapboxgl.Popup | null>(null);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [submit, setSubmit] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [authenticate] = useMutation(AUTHENTICATE_MUTATION);
    const [submitEnquiry] = useMutation(SUBMIT_ENQUIRY);
    const [submitName, setSubmitName] = useState('');
    const [submitCompanyName, setSubmitCompanyName] = useState('');
    const [submitEmail, setSubmitEmail] = useState('');
    const [submitPhone, setSubmitPhone] = useState('');
    const [submitAddress, setSubmitAddress] = useState('');
    const [submitStartDate, setSubmitStartDate] = useState('');
    const [submitEndDate, setSubmitEndDate] = useState('');
    const [addedBillboardIds, setaddBillboardIds] = useState<string[]>([]);
    const successModalRef = useRef<any>(null);
    const [redirect, setRedirect] = useState<string | null>(null);
    const [loadings, setLoading] = useState(false);
    const modalRef = useRef<HTMLButtonElement | null>(null);
    const [errors, setError] = useState<string | null>(null);
    const USER_ACCOUNT_QUERY = gql`
    query userAccount($accessToken: String!) {
    userAccount(accessToken: $accessToken) {
      id
      login
      password
      firstName
      lastName
      phone
      email
      activated
      langKey
      authorities
      accountType
      companyName
      companyId
      companyType
      country {
        id
        countryId
        name
        nameJa
        latitude
        longitude
        tax {
          label
          percent
        }
      }
      lastModifiedBy
      lastModifiedDate
      ecommerceDomainName
      userCompanyName
      userCompanyId
      userCompanyAddress
      userCompanyPhoneNumber
      platform
      userCountry
      
    }
  }
`;
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();
        setError(null);
        setSubmit(true);
        setLoading(true);

        if (!username || !password) {
            return;
        }

        const mediaOwnerCompanyId = '642685a0421c2678de8421ac';
        const concatenatedUsername = `${username}:${mediaOwnerCompanyId}`;

        try {
            const { data } = await authenticate({
                variables: {
                    username: concatenatedUsername,
                    password: password,
                }
            });

            const result: any = { loading, error, data };
            console.log("GraphQL Login Response: ", result);

            if (result.error) {
                // alert("Invalid username or password");
                setSubmit(false);
                setLoading(false);
                return;
            }

            if (result.data && result.data.authenticate) {
                const token = result.data.authenticate.authData.accessToken;
                if (token) {
                    document.getElementById("explore-login-close-btn")?.click();
                    setSubmit(false);
                    setLoading(false);
                    console.log("Token:", token);
                    toast.success("Login successful");
                    if (modalRef.current) {
                        modalRef.current.click();
                    }
                    localStorage.setItem("authToken", token);


                    const { data: userData } = await client.query({
                        query: USER_ACCOUNT_QUERY,
                        variables: {
                            accessToken: token,
                        },
                    });

                    if (userData && userData.userAccount) {
                        console.log("User Details:", userData.userAccount);
                        const countryId = userData.userAccount[0].country.id;
                        const userId = userData.userAccount[0].id;
                        const userNameLogin = userData.userAccount[0].login;
                        const userEmail = userData.userAccount[0].email
                        const companyId = userData.userAccount[0].companyId;
                        const { firstName, lastName } = userData.userAccount[0];
                        localStorage.setItem("userAccountData", JSON.stringify(userData.userAccount))
                        localStorage.setItem("countryId", countryId);
                        localStorage.setItem("userId", userId);
                        localStorage.setItem('userNameLogin', userNameLogin);
                        localStorage.setItem("userEmail", userEmail);
                        localStorage.setItem("companyId", companyId);
                        localStorage.setItem("userName", `${firstName} ${lastName}`);
                        const exploreData = {
                            exploreStartDate: format(startDates, 'yyyy-MM-dd'),
                            exploreEndDate: format(endDates, 'yyyy-MM-dd'),
                            exploreCondtion: true,
                            addedBillboardIds
                        };

                        navigate(`${process.env.REACT_APP_BASE_PATH}/explorenew/create-campaign`, { state: { exploreData } })
                    } else {
                        toast.error("Failed to retrieve user details");
                    }
                } else {

                    toast.error("Invalid username or password");
                    setSubmit(false);
                    setLoading(false);
                }
            } else {

                toast.error("An unexpected error occurred");
                setSubmit(false);
                setLoading(false);
            }
        } catch (error) {
            console.error("Login error: ", error);
            toast.error("An unexpected error occurred");
            setSubmit(false);
            setLoading(false);
        }

    };

    //Submit Enquiry 
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^[0-9]{10,13}$/;
    const handleSubmitEnquiry = async (event: any) => {
        event.preventDefault();
        setError(null);
        setSubmit(true);
        setLoading(true);
        if (!emailRegex.test(submitEmail)) {
            return;
        }
        if (submitPhone.length === 0 || !phoneRegex.test(submitPhone)) {
            return;
        }

        try {
            const response = await submitEnquiry({
                variables: {
                    name: submitName,
                    companyName: submitCompanyName,
                    email: submitEmail,
                    phone: submitPhone,
                    address: submitAddress,
                    startDate: submitStartDate,
                    endDate: submitEndDate,
                    addedBillboardIds,
                    companyId,
                    countryId
                }
            });

            if (response.data.submitEnquiry.message) {
                document.getElementById("explore-login-submit-btn")?.click();
                setSubmit(false);
                setLoading(false);
                console.log("Enquiry submitted successfully:", response.data.submitEnquiry.message);
                if (successModalRef.current) {

                    const modal = new window.bootstrap.Modal(successModalRef.current);
                    modal.show();
                }
            } else if (response.data.errors && response.data.errors.length > 0) {
                const errorMessage = response.data.errors[0].message;
                toast.error(`Error: ${errorMessage}`);
                setSubmit(false);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error submitting enquiry:", error);
            setSubmit(false);
            setLoading(false);
            toast.error("An unexpected error occurred. Please try again later.");
        }
    };

    const handleSubmitSuccess = () => {
        navigate(`${process.env.REACT_APP_BASE_PATH}/landing-page`)
        document.getElementById("explore-login-submit-btn")?.click();

    }

    useEffect(() => {
        mapboxgl.accessToken =
            "pk.eyJ1IjoidGVqYS1zaHJlZSIsImEiOiJjbHo4OWF6ZHgwMGwxMmxzYWxzaTk1YnNiIn0.hpbbzYRq7WOaOPwInekf9w";
        if (mapContainer.current && showMap && billBoardData.length > 0) {
            console.log("Initializing map at New York coordinates:", mapLat, mapLng);
            const initialMap = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/streets-v11",
                center: [mapLng, mapLat],
                zoom: 13.5,
            });

            initialMap.addControl(new mapboxgl.NavigationControl(), "top-right");
            setMap(initialMap);

            if (billBoardData.length > 0) {
                const firstBillboard = billBoardData[0];
                const firstLat = firstBillboard.latitude;
                const firstLng = firstBillboard.longitude;
                const newMarker = new mapboxgl.Marker({ color: '#014DC0' })
                    .setLngLat([firstLng, firstLat])
                    .addTo(initialMap);

                setMarker(newMarker);
                setSelectedInventory(firstBillboard);
                showPopup(firstBillboard);
            }

            return () => {
                initialMap.remove();
            };
        }
    }, [mapContainer, showMap, billBoardData]);


    useEffect(() => {
        if (map && billBoardData.length > 0) {
            map.flyTo({
                center: [mapLng, mapLat],
                essential: true,
                speed: 2.5,
                curve: 1,
                zoom: 13.5,
            });
            if (marker) {
                marker.setLngLat([mapLng, mapLat]);
            } else {
                const newMarker = new mapboxgl.Marker({ color: '#014DC0' })
                    .setLngLat([mapLng, mapLat])
                    .addTo(map);
                setMarker(newMarker);
            }
            if (selectedInventory) {
                showPopup(selectedInventory);
            }
        }
    }, [mapLat, mapLng, map, marker, selectedInventory]);

    const handleSelectBillboard = (inventory: any) => {
        setSelectedInventory(inventory);
        const latitude = inventory.latitude || 21.7679;
        const longitude = inventory.longitude || 78.8718;
        handleBillboardClick(latitude, longitude);
        showPopup(inventory);
    };

    const handleBillboardClick = (latitude: string | number, longitude: string | number) => {
        const newLat = parseFloat(latitude.toString());
        const newLng = parseFloat(longitude.toString());
        if (isNaN(newLat) || isNaN(newLng)) {
            console.error("Invalid latitude or longitude", latitude, longitude);
            return;
        }
        setMapLat(newLat);
        setMapLng(newLng);
    };
    const showPopup = (inventory: any) => {
        if (marker && map && billBoardData.length > 0) {
            if (currentPopup) {
                currentPopup.remove();
            }
            if (!marker.getLngLat() || !map) {
                console.error("Marker or map is not properly initialized.");
                return;
            }
            const popupContent = createPopupContent(inventory);
            try {
                const popup = new mapboxgl.Popup({ offset: 25 })
                    .setHTML(popupContent)
                    .setLngLat(marker.getLngLat());
                marker.setPopup(popup);
                popup.addTo(map);

                setCurrentPopup(popup);
            } catch (error) {
                console.error("Error showing popup:", error);
            }
        } else {
            console.error("Marker or map instance is not available.");
        }
    };

    const createPopupContent = (inventory: any) => {
        const address = inventory?.stateName || "N/A";
        const impressions = inventory?.monthlySummary?.totalVisitors || "N/A";
        const price = inventory?.monthlySummary?.uniqueVisitors || "N/A";
        const prices = inventory?.sellingRate?.monthly?.month1 || "N/A";
        return `
          <div class="map-popup-container">
            <div class="map-popup-image-container">
              <img src="${inventory?.thumbnailPath}" alt="Billboard Image" class="map-popup-image">
            </div>
            <div class="map-popup-body">
              <div class="row">
                <div class="col-md-6">
                  <h3 class="map-popup-title">${inventory?.displayName || "N/A"}</h3>
                  <p class="map-popup-stat-label">Impressions: ${impressions}</p>
                  <p class="map-popup-stat-label">AUD ${prices}</p>
                </div>
                <div class="col-md-6">
                  <p class="map-popup-location">${address}</p>
                  <p class="map-popup-price">Unique Reach : ${price}</p>
                </div>
              </div>
            </div>
          </div>
        `;
    };

    useEffect(() => {
        if (map && selectedInventory) {
            showPopup(selectedInventory);
        }
    }, [mapLat, mapLng, selectedInventory]);


    const handleShowMap = () => {
        setShowMap(!showMap);
    };


    const formatDate = (date: Date): string => {
        return date.toISOString().split('T')[0];
    };
    const handleSubmit = () => {
        setStartDates(startDates);
        setEndDates(endDates);
        setIsSubmitted(true);
        fetchBillBoardList();
    };
    const closeFilter = () => {
        setActiveCategoryLocation(null);
        setShowLocationFilters(!showLocationFilters);
    }
    const handleLocationFilterClick = () => {
        setShowLocationFilters(!showLocationFilters);
        setActiveCategoryLocation(activeCategoryLocation === 'location' ? null : 'location');
        setActiveCategory(null);
        setVenueTypeData([]);
        setActiveCategorySite(null);
        setActiveCategoryBill(null);
        setActiveCategoryPrice(null);
        setShowLocationVenue(false);
        setShowLocationFinancial(false);
        setShowLocationAirport(false);
    };

    const handleLocationVenueClick = () => {
        setShowLocationVenue(!showLocationVenue);
        setActiveCategory(activeCategory?.id === 'venue' ? null : { id: 'venue', name: 'Venue', value: 'Venue type', child: [] });
        setActiveCategoryLocation(null);
        setActiveCategorySite(null);
        setActiveCategoryBill(null);
        setActiveCategoryPrice(null);
        setActiveSubCategory(null);
        setActiveGradSubCategory(null);
        setShowLocationFilters(false);
        setShowLocationSite(false);
        setShowLocationBill(false);
        setShowLocationPrice(false);
    };

    const handleLocationSiteClick = () => {
        setShowLocationSite(!showLocationSite);
        setActiveCategorySite(activeCategorySite === 'site' ? null : 'site');
        setActiveCategoryLocation(null);
        setVenueTypeData(['']);
        setActiveCategory(null);
        setActiveSubCategory(null);
        setActiveGradSubCategory(null);
        setActiveCategoryBill(null);
        setActiveCategoryPrice(null);
        setShowLocationFilters(false);
        setShowLocationVenue(false);
        setShowLocationBill(false);
        setShowLocationPrice(false);
    };

    const handleLocationBillClick = () => {
        setShowLocationBill(!showLocationBill);
        setActiveCategoryBill(activeCategoryBill === 'bill' ? null : 'bill');
        setActiveCategoryLocation(null);
        setVenueTypeData(['']);
        setActiveCategory(null);
        setActiveSubCategory(null);
        setActiveGradSubCategory(null);
        setActiveCategorySite(null);
        setActiveCategoryPrice(null);
        setShowLocationFilters(false);
        setShowLocationVenue(false);
        setShowLocationSite(false);
        setShowLocationPrice(false);
    };

    const handleLocationPriceClick = () => {
        setShowLocationPrice(!showLocationPrice);
        setActiveCategoryPrice(activeCategoryPrice === 'price' ? null : 'price');
        setActiveCategoryLocation(null);
        setVenueTypeData(['']);
        setActiveCategory(null);
        setActiveSubCategory(null);
        setActiveGradSubCategory(null);
        setActiveCategorySite(null);
        setActiveCategoryBill(null);
        setShowLocationFilters(false);
        setShowLocationVenue(false);
        setShowLocationSite(false);
        setShowLocationBill(false);
    };
    const [minPrice, setMinPrice] = useState(1);
    const [maxPrice, setMaxPrice] = useState(3500);
    const handleMinPriceChange = (e: any) => {
        const value = Math.min(e.target.value, maxPrice);
        setMinPrice(Number(value));
    };
    const handleMaxPriceChange = (e: any) => {
        const value = Math.max(e.target.value, minPrice);
        setMaxPrice(Math.min(Number(value), 3500));
    };
    const handlePriceRangeChange = () => {
        const badgeValue = `Price: ${minPrice} - ${maxPrice}`;
        if (!badges.includes(badgeValue)) {
            setBadges([...badges, badgeValue]);
        }
    };
    const handleLocationFinancialClick = (category: Category) => {
        if (category !== activeCategory) {
            setShowLocationFinancial(true);
            setActiveCategory(category);
            setActiveSubCategory(null);
            setActiveGradSubCategory(null);
            setVenueTypeData([]);
        }
    };

    const handleSubCategoryClick = (subCategory: SubCategory) => {
        if (subCategory !== activeSubCategory) {
            setActiveSubCategory(subCategory);
            setActiveGradSubCategory(null);
            setVenueTypeData((prevVenueTypeData) => {
                if (!prevVenueTypeData.includes(subCategory.value)) {
                    return [...prevVenueTypeData, subCategory.value];
                }
                return prevVenueTypeData;
            });
            handleAddBadge(subCategory.value);
        }
    };

    const handleGradSubCategoryClick = (gradSubCategory: GrandSubCategory) => {
        if (gradSubCategory !== activeGradSubCategory) {
            setActiveGradSubCategory(gradSubCategory);
            setVenueTypeData((prevVenueTypeData) => {
                if (!prevVenueTypeData.includes(gradSubCategory.value)) {
                    return [...prevVenueTypeData, gradSubCategory.value];
                }
                return prevVenueTypeData;
            });
            handleAddBadge(gradSubCategory.value);
        }
    };
    const handleAddBadge = (badgeValue: string) => {
        if (!badges.includes(badgeValue)) {
            setBadges([...badges, badgeValue]);

        }
    };
    const [indoorChecked, setIndoorChecked] = useState(false);
    const [outdoorChecked, setOutdoorChecked] = useState(false);
    const handleCheckboxLocation = (badgeValue: string, checked: boolean) => {
        if (checked) {
            if (!badges.includes(badgeValue)) {
                setBadges((prevBadges) => [...prevBadges, badgeValue]);
                setLocationData((prevLocationData) => [...prevLocationData, badgeValue]);
            }
            if (badgeValue === 'Indoor') {
                setIndoorChecked(true);
            } else if (badgeValue === 'Outdoor') {
                setOutdoorChecked(true);
            }
        } else {
            setBadges((prevBadges) => prevBadges.filter((badge) => badge !== badgeValue));
            setLocationData((prevLocationData) =>
                prevLocationData.filter((badge) => badge !== badgeValue)
            );
            if (badgeValue === 'Indoor') {
                setIndoorChecked(false);
            } else if (badgeValue === 'Outdoor') {
                setOutdoorChecked(false);
            }
        }
    };

    const handleCheckboxChange = (gradSubCategory: GrandSubCategory) => {
        if (badges.includes(gradSubCategory.value)) {
            setBadges(badges.filter(badge => badge !== gradSubCategory.value));
        } else {
            setBadges([...badges, gradSubCategory.value]);
        }
    };

    const handleSiteTypeChange = (e: any) => {
        const siteType = e.target.value;

        setBadges((prevBadges) => {
            const newBadges = prevBadges.filter(badge => !badge.includes(siteType));
            setSitetypeData([...newBadges, `${siteType}`]);
            return [...newBadges, `${siteType}`];
        });
    };

    const handleScreenSizeChange = (e: any) => {
        const screenSize = e.target.value;

        setBadges((prevBadges) => {
            const newBadges = prevBadges.filter(badge => !badge.includes(screenSize));
            setSitetypeData([...newBadges, `${screenSize}`]);
            return [...newBadges, `${screenSize}`];
        });
    };

    const [sheetsChecked, setSheetsChecked] = useState(false);
    const [bulletin, setBulletin] = useState(false);
    const [cityLights, setcityLights] = useState(false);
    const [busShelter, setbusShelter] = useState(false);
    const [gallery, setGallery] = useState(false);
    const handleCheckboxLocationBill = (badgeValue: string, checked: boolean) => {
        if (checked) {
            if (!badges.includes(badgeValue)) {
                setBadges((prevBadges) => [...prevBadges, badgeValue]);
            }
            if (!selectedCheckboxes.includes(badgeValue)) {
                setSitetypeData((prevSelected) => [...prevSelected, badgeValue]);
            }
            if (badgeValue === '96 sheets') {
                setSheetsChecked(true);
            } else if (badgeValue === 'Bulletin') {
                setBulletin(true);
            } else if (badgeValue === 'City Lights') {
                setcityLights(true);
            } else if (badgeValue === 'Bus Shelter') {
                setbusShelter(true);
            } else if (badgeValue === 'Gallery') {
                setGallery(true);
            }
        } else {
            setBadges((prevBadges) => prevBadges.filter((badge) => badge !== badgeValue));
            setSitetypeData((prevSelected) =>
                prevSelected.filter((value) => value !== badgeValue)
            );
            if (badgeValue === '96 sheets') {
                setSheetsChecked(false);
            } else if (badgeValue === 'Bulletin') {
                setBulletin(false);
            } else if (badgeValue === 'City Lights') {
                setcityLights(false);
            } else if (badgeValue === 'Bus Shelter') {
                setbusShelter(false);
            } else if (badgeValue === 'Gallery') {
                setGallery(false);
            }
        }
    };

    const handleRemoveBadge = (badgeToRemove: string) => {
        setBadges(badges.filter(badge => badge !== badgeToRemove));
    };
    // Country List Dropdwon
    const [selectedCountryId, setSelectedCountryId] = useState<string | undefined>();
    const [countryOptions, setCountryOptions] = useState<{ id: string; name: string; dialingCode: string }[]>([]);
    const [stateOptions, setStateOptions] = useState<{
        stateId: string | null;
        id: string;
        name: string;
    }[]>([]);
    const [country, setCountry] = useState<string>("");
    const [state, setState] = useState<string>("");
    const { data: countryData } = useQuery(GET_COUNTRY_LIST);

    useEffect(() => {
        if (countryData && countryData.countryList) {
            setCountryOptions(countryData.countryList);
        }
    }, [countryData]);

    // State List Dropdwon

    const { data: stateData } = useQuery(GET_STATE_LIST, {
        variables: { countryId: selectedCountryId },
        skip: !selectedCountryId,
    });

    useEffect(() => {
        if (stateData && stateData.stateList) {
            setStateOptions(stateData.stateList);
        }
    }, [stateData]);

    const handleCountryChange = (e: { target: { value: string } }) => {
        const selectedId = e.target.value;
        const selectedCountry = countryOptions.find(country => country.id === selectedId);

        if (selectedCountry) {
            setSelectedCountryId(selectedCountry.id);
            setCountry(selectedId);
        } else {
            setCountry("");
        }
    };
    const resetAllStates = () => {
        setBadges([]);
        setLocationData([]);
        setVenueTypeData([]);
        setSitetypeData([]);
        setMinPrice(1);
        setMaxPrice(3500);
        setIndoorChecked(false);
        setOutdoorChecked(false);
        setSheetsChecked(false);
        setBulletin(false);
        setcityLights(false);
        setbusShelter(false);
        setGallery(false);
        fetchBillBoardList();
        setActiveCategoryLocation(null);
        setActiveCategory(null);
        setVenueTypeData([]);
        setActiveCategorySite(null);
        setActiveCategoryBill(null);
        setActiveCategoryPrice(null);
        setShowLocationVenue(false);
        setShowLocationFinancial(false);
        setShowLocationAirport(false);

    };


    const token = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyId');
    const countryId = localStorage.getItem('countryId');
    const [currentPage, setCurrentPage] = useState(0);
    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        console.log("Billboard Filter Data: ", props)
        fetchBillBoardList();
    }, [props.filterData])

    useEffect(() => {
        if (process.env.REACT_APP_MOCK_DATA === 'true') {
            generateMockData(GET_LANDING_EXPLORATION).then((mockResponse: any) => {
                console.log("mockData", mockResponse);
                const data = mockResponse.data
                setBillBoardDataLoading(false);
                setBillBoardData(data.landingpageExplorePropertiesNotoken)

            }).catch((err: any) => {
                console.error("Error generating mock data:", err);
            });
        } else {
            fetchBillBoardList();
            // fetchCartItems();
        }

    }, [search])

    const [cartItemCount, setCartItemCount] = useState(0);
    const { data } = useQuery(PROPERTY_LIST_VENUE_TYPE_LIST);
    const venueTypes = data?.venueTypes || [];
    const { data: myCart, loading, error } = useQuery(CART_INITIAL_QUERY, {
        variables: {
            accessToken: token,
            userId: userId,
        },
    });

    useEffect(() => {
        fetchBillBoardList();
    }, [currentPage, search]);
    const fetchBillBoardList = async (searchValue: any = "", cartItems: any = "") => {
        try {
            console.log("cur Bill Board Data: ", billBoardData);
            setBillBoardDataLoading(true);
            setBillBoardData([]);
            let categories = locationData.map(category => category.toUpperCase());
            const filterData = {
                type: billboardType.toUpperCase(),
                availableBooking: true,
                categories: categories,
                format: siteTypeData,
                venueType: venueTypeData,
            };
            const { data, loading, error } = await client.query({
                query: GET_LANDING_EXPLORATION,
                variables: {
                    sort: "last_modified_date,desc",
                    billboard: billboardType,
                    userId: userId,
                    companyId: companyId || '642685a0421c2678de8421ac',
                    page: currentPage,
                    size: dataListLength,
                    startDate: format(startDates, 'yyyy-MM-dd'),
                    endDate: format(endDates, 'yyyy-MM-dd'),
                    dspName: "LMX-ECOMMERCE",
                    countryId: countryId || '573aa8c388041e1667e335d1',
                    searchTerm: search,
                    filterData: filterData
                }
            })
            if (data) {
                console.log("Billboard data testing: ", data);
                setBillBoardDataLoading(false);
                let resultObj = data.landingpageExplorePropertiesNotoken;
                let cartObj = cartItems;
                if (resultObj.length > 0 && cartObj.length > 0) {
                    cartObj.map((cartItem: any) => {
                        const newBillBoardData = resultObj.map((obj: { id: any; }) => {
                            if (obj.id === cartItem.cartItemId) {
                                return { ...obj, isInCart: true };
                            } else {
                                return { ...obj, isInCart: false };
                            }
                            // return obj;
                        });
                        resultObj = newBillBoardData;
                        setBillBoardData(newBillBoardData);
                    });

                } else {
                    setBillBoardData(data.landingpageExplorePropertiesNotoken);
                }
                setTotalDataLength(data.landingpageExplorePropertiesNotoken[0].totalSize);
                if (data.landingpageExplorePropertiesNotoken[0].totalSize > 0) {
                    if (currentPage === 0) {
                        setPageStartData(1);
                        if (data.landingpageExplorePropertiesNotoken[0].totalSize > dataListLength) {
                            setPageEndData(dataListLength);
                        } else {
                            setPageEndData(data.landingpageExplorePropertiesNotoken[0].totalSize);
                        }
                    } else {
                        const curStartData = dataListLength * currentPage;
                        setPageStartData(curStartData + 1);
                        const curEndData = curStartData + dataListLength;
                        if (data.landingpageExplorePropertiesNotoken[0].totalSize > curEndData) {
                            setPageEndData(curEndData);
                        } else {
                            setPageEndData(data.landingpageExplorePropertiesNotoken[0].totalSize);
                        }
                    }
                }
            }
        } catch (error) {
            setBillBoardDataLoading(false);
            console.log("Error fetching property details:", error);
        }
    };

    const addToCart = (itemData: any) => {
        try {

            const isItemInCart = addedBillboardIds.includes(itemData.id);
            if (!isItemInCart) {

                console.log("Current billboardIds before adding pra:", addedBillboardIds);
                const newBillBoardData = billBoardData.map((obj: { id: any; }) => {
                    if (obj.id === itemData.id) {
                        return { ...obj, isInCart: true };
                    }
                    return obj;
                });

                setBillBoardData(newBillBoardData);
                console.log("Updated billboardData after adding:", newBillBoardData);
                setaddBillboardIds((prevIds: any[]) => {
                    const updatedIds = [...prevIds, itemData.id];

                    console.log("Updated addBillboardIds after addingssssssssss:", updatedIds);
                    return updatedIds;
                });


                setCartCount(cartCount + 1);


                toast.success(`${itemData.name} has been added to your cart!`);
            } else {

                toast.info(`${itemData.name} is already in your cart.`);
            }

        } catch (error) {
            console.log("Error adding to cart:", error);
            toast.error("Something went wrong while adding to the cart.");
        }
    }
    const [cartId, setcartId] = useState<SssItem[]>([]);

    const deleteCartItem = (itemData: any) => {
        try {
            const isItemInCart = addedBillboardIds.includes(itemData.id);

            if (isItemInCart) {
                console.log("Current billboardIds before deleting:", addedBillboardIds);
                const newBillBoardData = billBoardData.map((obj: { id: any; }) => {
                    if (obj.id === itemData.id) {
                        return { ...obj, isInCart: false };
                    }
                    return obj;
                });

                setBillBoardData(newBillBoardData);
                setaddBillboardIds((prevIds: any[]) => {
                    const updatedIds = prevIds.filter((id: any) => id !== itemData.id); // Remove the item ID
                    console.log("Updated addBillboardIds after deletion:", updatedIds);
                    return updatedIds;
                });
                setCartCount(cartCount - 1);
                toast.success(`${itemData.name} has been removed from your cart!`);
                console.log("Updated billBoardData after deletion:", newBillBoardData);
                console.log("Updated cart count:", cartCount - 1);

            } else {
                toast.info(`${itemData.name} is not in your cart.`);
            }

        } catch (error) {
            console.log("Error deleting item from cart:", error);
            toast.error("Something went wrong while deleting the item from the cart.");
        }
    };
    const soldCampaignDetails = (details: any) => {
        setSoldCampaignViewDetails(details);
    }
    const billboardTypeChange = (eve: any) => {
        console.log(eve);
        setBillboardType(eve);
        setCurrentPage(0)
    }
    const paginationPlus = () => {
        const newPage = currentPage + 1
        setCurrentPage(newPage)
    }
    const paginationMinus = () => {
        const newPage = currentPage - 1
        setCurrentPage(newPage)
    }
    useEffect(() => {
        fetchBillBoardList();
    }, [billboardType, currentPage, search])
    const { data: datas } = useQuery(INVOICE, {
        variables: {
            accessToken: token,
            campId: cartformData.selectedCampaignId,
        },
    });


    useEffect(() => {
        console.log(datas);

    }, [datas])
    const handleSearchChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setSearch(event.currentTarget.value);
            fetchBillBoardList(event.currentTarget.value);
        }
    };

    const ShowAddDeleteCartBtn = (curObj: any) => {
        let status = false;
        cartData.map((cartItem: any) => {
            if (curObj.id === cartItem.cartItemId) {
                status = true;
            }
        });
        return status;
    }
    const navigateToProDetails = (id: any, type: any) => {
        const propertyDetailsData = {
            id,
            type,
            campaignName,
            startDates,
            endDates
        }
        navigate(`${process.env.REACT_APP_BASE_PATH}/exploreproperty-details`, { state: { propertyDetailsData } });

    };
    const handleStartDateChange = (date: Date | null) => {
        if (date) {
            setTempStartDate(date);
            setShowMonthPicker(false);
            setShowYearPicker(false);
        }
    };

    const handleEndDateChange = (date: Date | null) => {
        if (date) {
            setTempEndDate(date);
            setShowMonthPicker(false);
            setShowYearPicker(false);
        }
    };

    const toggleStartDatePicker = () => {
        setShowStartDatePicker(!showStartDatePicker);
        setTempStartDate(startDates);
        setShowMonthPicker(false);
        setShowYearPicker(false);
    };

    const toggleEndDatePicker = () => {
        setShowEndDatePicker(!showEndDatePicker);
        setTempEndDate(endDates);
        setShowMonthPicker(false);
        setShowYearPicker(false);
    };

    const toggleMonthPicker = () => {
        setShowMonthPicker(!showMonthPicker);
        setShowYearPicker(false);
    };

    const toggleYearPicker = () => {
        setShowYearPicker(!showYearPicker);
        setShowMonthPicker(false);
    };

    const handleMonthSelection = (month: number) => {
        const newStartDate = new Date(tempStartDate);
        const newEndDate = new Date(tempEndDate);
        newStartDate.setMonth(month);
        newEndDate.setMonth(month);

        setTempStartDate(newStartDate);
        setTempEndDate(newEndDate);
        setSelectedMonth(month);
        setShowMonthPicker(false);
    };

    const handleYearSelection = (year: number) => {
        const newStartDate = new Date(tempStartDate);
        const newEndDate = new Date(tempEndDate);
        newStartDate.setFullYear(year);
        newEndDate.setFullYear(year);

        setTempStartDate(newStartDate);
        setTempEndDate(newEndDate);
        setSelectedYear(year);
        setShowYearPicker(false);
    };

    const handleUpdateStartDate = () => {
        setStartDates(tempStartDates);
        setShowStartDatePicker(false);
    };

    const handleUpdateEndDate = () => {
        setEndDates(tempEndDates);
        setShowEndDatePicker(false);
    };
    const handleMonthButtonClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    //Select date popoup


    const handleStartDateChanges = (date: Date | null) => {
        if (date) {
            setTempStartDates(date);
            setShowMonthPickers(false);
            setShowYearPickers(false);
        }
    };

    const handleEndDateChanges = (date: Date | null) => {
        if (date) {
            setTempEndDates(date);
            setShowMonthPickers(false);
            setShowYearPickers(false);
        }
    };

    const toggleStartDatePickers = () => {
        setShowStartDatePickers(!showStartDatePickers);
        setTempStartDates(startDates); // Reset to initial start date
        setShowMonthPickers(false);
        setShowYearPickers(false);
    };

    const toggleEndDatePickers = () => {
        setShowEndDatePickers(!showEndDatePickers);
        setTempEndDates(endDates); // Reset to initial end date
        setShowMonthPickers(false);
        setShowYearPickers(false);
    };

    const toggleMonthPickers = () => {
        setShowMonthPickers(!showMonthPickers);
        setShowYearPickers(false);
    };

    const toggleYearPickers = () => {
        setShowYearPickers(!showYearPickers);
        setShowMonthPickers(false);
    };

    const handleMonthSelections = (month: number) => {
        const newStartDate = new Date(tempStartDates);
        const newEndDate = new Date(tempEndDates);
        newStartDate.setMonth(month);
        newEndDate.setMonth(month);

        setTempStartDates(newStartDate);
        setTempEndDates(newEndDate);
        setSelectedMonths(month);
        setShowMonthPickers(false);
    };

    const handleYearSelections = (year: number) => {
        const newStartDate = new Date(tempStartDates);
        const newEndDate = new Date(tempEndDates);
        newStartDate.setFullYear(year);
        newEndDate.setFullYear(year);

        setTempStartDates(newStartDate);
        setTempEndDates(newEndDate);
        setSelectedYears(year);
        setShowYearPickers(false);
    };

    const handleUpdateStartDates = () => {
        setStartDates(tempStartDates);
        setShowStartDatePickers(false);

    };

    const handleUpdateEndDates = () => {
        setEndDates(tempEndDates);
        setShowEndDatePickers(false);

    };

    const handleMonthButtonClicks = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };



    const handleRotate = () => {
        setSearch('');
        fetchBillBoardList();
    }
    if (redirect) {
        return <Navigate to="/sign-in" />;
    }
    return (
        <>
            <div className="row property-list-search-container">
                <div className={`col-sm-12 ${showMap ? 'col-md-4' : 'col-md-5'}`}>
                    <div className="input-group assign_creative_input_group g-4">
                        <span className="input-group-text search-icon assign_creative_search_icon">
                            <img src={Image1} alt="" />
                        </span>
                        <input type="text" className="form-control border-bottom-only assign_creative_border_bottom_only " placeholder="Search for billboards" aria-label="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                        <span>
                            <i onClick={handleRotate} className="fa-solid fa-rotate"></i>
                        </span>                        <div>
                            <img src={Image2} alt="" className="property_list_search_location_logo" onClick={handleShowMap} style={{ cursor: 'pointer' }} />
                        </div>
                        <div>
                            <img src={Image3} alt="Show Map" className="property_list_search_location_logo" onClick={handleShowMap} style={{ cursor: 'pointer' }} />
                        </div>
                    </div>
                </div>

                <div className={`col-sm-12 ${showMap ? 'col-md-8' : 'col-md-7'} property_list_search_con_2`}>
                    <div className="gx-2 gy-2 d-flex justify-content-between align-items-center property_list_search_con_3">

                        <div className="d-flex flex-column">
                            <label htmlFor="duration" className="form-label exploreproperty_list_navbar_header_1 mt-2">Duration</label>
                            <div className="d-flex align-items-center">
                                {/* Start Date Picker */}
                                <div className="property_list_date_start d-flex align-items-center position-relative">
                                    <div onClick={toggleStartDatePicker} className="calendar-icon-wrapper">
                                        <img alt="Start Date" src={Image7} className="date-icon" />
                                    </div>
                                    <div className="date-text-1 explore-date">
                                        <p style={{ "borderBottom": "1.5px solid black" }}>
                                            {startDates ? format(startDates, "dd MMM yyyy") : "Select Start Date"}
                                        </p>
                                    </div>
                                    {showStartDatePicker && (
                                        <div className="datepicker-wrapper">
                                            <div className="close-icon" onClick={toggleStartDatePicker}>
                                                ×
                                            </div>
                                            <p className="card_date_header">Select Campaign start date</p>
                                            {showYearPicker ? (
                                                <div className="year-picker">
                                                    <div className="years-grid">
                                                        {Array.from({ length: 10 }, (_, i) => (
                                                            <button
                                                                key={i}
                                                                onClick={() => handleYearSelection(selectedYear + i)}
                                                                className="year-button"
                                                            >
                                                                {selectedYear + i}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : showMonthPicker ? (
                                                <div className="month-picker">
                                                    <div className="months-grid">
                                                        {Array.from({ length: 12 }, (_, i) => (
                                                            <button
                                                                key={i}
                                                                onClick={() => handleMonthSelection(i)}
                                                                className="month-button"
                                                            >
                                                                {format(new Date(2021, i), "MMM")}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (
                                                <DatePicker
                                                    selected={tempStartDate}
                                                    onChange={handleStartDateChange}
                                                    inline
                                                    renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                        <div className="custom-header">
                                                            <button onClick={(e) => { handleMonthButtonClick(e); decreaseMonth(); }}>{"«"}</button>
                                                            <span onClick={toggleMonthPicker}>
                                                                {format(date, "MMMM")}
                                                            </span>
                                                            <span onClick={toggleYearPicker}>
                                                                {format(date, "yyyy")}
                                                            </span>
                                                            <button onClick={(e) => { handleMonthButtonClick(e); increaseMonth(); }}>{"»"}</button>
                                                        </div>
                                                    )}
                                                />
                                            )}
                                            <button className="update-button" onClick={handleUpdateStartDate}>
                                                Update
                                            </button>
                                        </div>
                                    )}
                                </div>

                                <span className="mt-1 mx-2">→</span>

                                <div className="property_list_date_end d-flex align-items-center position-relative">
                                    <div onClick={toggleEndDatePicker} className="calendar-icon-wrapper">
                                        <img alt="End Date" src={Image7} className="date-icon" />
                                    </div>
                                    <div className="date-text-1 explore-date explore-enddate">
                                        <p style={{ "borderBottom": "1.5px solid black" }}>
                                            {endDates ? format(endDates, "dd MMM yyyy") : "Select End Date"}
                                        </p>
                                    </div>
                                    {showEndDatePicker && (
                                        <div className="datepicker-wrapper">
                                            <div className="close-icon" onClick={toggleEndDatePicker}>
                                                ×
                                            </div>
                                            <p className="card_date_header">Select Campaign end date</p>
                                            {showYearPicker ? (
                                                <div className="year-picker">
                                                    <div className="years-grid">
                                                        {Array.from({ length: 10 }, (_, i) => (
                                                            <button
                                                                key={i}
                                                                onClick={() => handleYearSelection(selectedYear + i)}
                                                                className="year-button"
                                                            >
                                                                {selectedYear + i}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : showMonthPicker ? (
                                                <div className="month-picker">
                                                    <div className="months-grid">
                                                        {Array.from({ length: 12 }, (_, i) => (
                                                            <button
                                                                key={i}
                                                                onClick={() => handleMonthSelection(i)}
                                                                className="month-button"
                                                            >
                                                                {format(new Date(2021, i), "MMM")}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (
                                                <DatePicker
                                                    selected={tempEndDate}
                                                    onChange={handleEndDateChange}
                                                    inline
                                                    renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                        <div className="custom-header">
                                                            <button onClick={(e) => { handleMonthButtonClick(e); decreaseMonth(); }}>{"«"}</button>
                                                            <span onClick={toggleMonthPicker}>
                                                                {format(date, "MMMM")}
                                                            </span>
                                                            <span onClick={toggleYearPicker}>
                                                                {format(date, "yyyy")}
                                                            </span>
                                                            <button onClick={(e) => { handleMonthButtonClick(e); increaseMonth(); }}>{"»"}</button>
                                                        </div>
                                                    )}
                                                />
                                            )}
                                            <button className="update-button" onClick={handleUpdateEndDate}>
                                                Update
                                            </button>
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>

                        {/* "Show only" Dropdown */}
                        <div className="d-flex align-items-center">
                            <div>
                                <span className="assign_filter_by">Show only:</span>
                            </div>
                            <div>
                                <div className="dropdown">
                                    <button
                                        className="btn btn-sm w-100 border-0 assign_drowdown_head"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {billboardType} <i className="fa-solid fa-angle-down"></i>
                                    </button>
                                    <ul className="dropdown-menu assign_dropdown_menu">
                                        <li>
                                            <a onClick={() => billboardTypeChange('classic')} className="dropdown-item assign_dropdown_item">
                                                Classic<i className="fa-solid fa-check assign_fa_check"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a onClick={() => billboardTypeChange('digital')} className="dropdown-item assign_dropdown_item">
                                                Digital<i className="fa-solid fa-check assign_fa_check"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Filter and Cart */}
                            <div className="d-flex">
                                <img className="property_list_filter_icon" src={Image5} alt="" />
                                <button className="assign_creative_popup_filter" onClick={handleLocationFilterClick} data-bs-toggle="modal" data-bs-target="#filterModal">Filter</button>
                            </div>
                            <div>
                                <div className="property_list_search_card_con">
                                    {/* <button className="property_list_search_card" onClick={navigateToCart}> */}
                                    <button className="property_list_search_card" data-bs-toggle="modal" data-bs-target="#exploreLogin">
                                        <img className="detaillocation-frame-inner property-logo-img" alt="" height="15px" width="17px" src={Image6} />
                                        Cart ({cartCount})
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row property-list-main-container">
                <div className={`col-md-${showMap ? '4' : '12'}`}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="property_list_avil_content">Available locations</p>
                                </div>
                                <div className="d-flex">
                                    <p className="property_list_pagination_content">Showing {pageStartData} to {pageEndData} of {totalDataLength}</p>
                                    {currentPage > 0 ? (
                                        <button className="property_list_pagination_left active" onClick={paginationMinus}> {"<"} </button>
                                    ) : (
                                        <button className="property_list_pagination_left">{"<"}</button>
                                    )}
                                    {totalDataLength > pageEndData ? (
                                        <button className="property_list_pagination_right" onClick={paginationPlus}>{" > "}</button>
                                    ) : (
                                        <button className="property_list_pagination_left">{">"}</button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">

                            {billBoardDataLoading ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: '40vh' }}>
                                    <span className="loading-circle sp1">
                                        <span className="loading-circle sp2">
                                            <span className="loading-circle sp3"></span>
                                        </span>
                                    </span>
                                </div>) : (
                                <>
                                    {billBoardData.length > 0 ? (
                                        <div className={`${showMap ? ' show-map' : ' hide-map'}` + " row"}>
                                            {billBoardData.map((listData: any, i: any) => (
                                                <div className={`col-md-${showMap ? '12' : '4'}`}>
                                                    <div className="property_list_scroll" onClick={() => handleSelectBillboard(listData)}>
                                                        <div className="card props-hover2 mb-3 border-0">
                                                            {listData.thumbnailPath ? (
                                                                <img className="card-img-top" alt={listData.displayName} src={listData.thumbnailPath} />
                                                            ) : (
                                                                <img className="detaillocation-frame-inner property-logo-img" alt="no image" src={NoImage} />
                                                            )}
                                                            {listData.active ? (
                                                                <span className="badge badge-danger status-label props-status-label bg-light text-success"><i
                                                                    className="fa-solid fa-circle"></i> Available</span>
                                                            ) : (
                                                                <span className="badge badge-danger status-label props-status-label bg-light text-danger"><i
                                                                    className="fa-solid fa-circle"></i> Sold Out</span>
                                                            )}
                                                            <div className="card-img-overlay card-img-overlay-custom">
                                                                {!isSubmitted ? (

                                                                    <button
                                                                        className="btn btn-primary propety-btn-primary w-100"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#exploreDate"
                                                                    >
                                                                        <img
                                                                            className="detaillocation-frame-inner property-logo-img"
                                                                            alt=""
                                                                            src={Image9}
                                                                        />
                                                                        Add to Cart
                                                                    </button>
                                                                ) : (

                                                                    !listData.isInCart && listData.active ? (
                                                                        <button
                                                                            className="btn btn-primary propety-btn-primary w-100"
                                                                            onClick={() => addToCart(listData)}
                                                                        >
                                                                            <img
                                                                                className="detaillocation-frame-inner property-logo-img"
                                                                                alt=""
                                                                                src={Image9}
                                                                            />
                                                                            Add to Cart
                                                                        </button>
                                                                    ) : listData.isInCart ? (
                                                                        <button
                                                                            className="btn btn-danger propety-btn-danger"
                                                                            onClick={() => deleteCartItem(listData)}
                                                                        >
                                                                            <img
                                                                                className="detaillocation-frame-inner property-logo-img"
                                                                                alt=""
                                                                                src={Image9}
                                                                            />
                                                                            Delete from Cart
                                                                        </button>
                                                                    ) : null
                                                                )}


                                                                {isSubmitted ? (
                                                                    listData.active ? (
                                                                        <button
                                                                            className="btn text-light mt-2 border-0 property-logo-view-details"
                                                                            onClick={() => navigateToProDetails(listData.id, listData.type)}
                                                                        >
                                                                            View details
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            className="btn text-light mt-2 border-0 property-logo-view-details"
                                                                            onClick={() => navigateToProDetails(listData.id, listData.type)}
                                                                        >
                                                                            View details
                                                                        </button>
                                                                    )
                                                                ) : (
                                                                    <button
                                                                        className="btn text-light mt-2 border-0 property-logo-view-details"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#exploreDate"
                                                                    >
                                                                        View details
                                                                    </button>
                                                                )}

                                                            </div>
                                                            <div className="property-card-body property-card-3">
                                                                <div className="d-flex m-0">
                                                                    <h6 className="property-card-title3">{listData.displayName}</h6>
                                                                    <p className="property-card-text-3">{listData.stateName}</p>
                                                                </div>
                                                                <p className="property-card-text-4">AUD {listData.sellingRate ? listData.sellingRate.monthly.month1 : ''}</p>
                                                                <div className="list-unstyled">
                                                                    <div className="property-row-3">
                                                                        <div className="column  property-column-3">
                                                                            <p className="title-msg property-title-msg">Site resolution</p>
                                                                            <p className="value  property-title-msg">
                                                                                {listData.type === "DIGITAL" ? (
                                                                                    <p className="value property-title-msg">
                                                                                        {listData.resolutionHeight} X {listData.resolutionWidth}
                                                                                    </p>
                                                                                ) : (
                                                                                    <p className="value property-title-msg">
                                                                                        {listData.panelHeight} X {listData.panelWidth}
                                                                                    </p>
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                        <div className="column  property-column-3">
                                                                            <p className="title-msg property-title-msg">Number of screens</p>
                                                                            <p className="value  property-title-msg">{listData.specification?.screens}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="property-row-3">
                                                                        <div className="column  property-column-3">
                                                                            <p className="title-msg property-title-msg">Impressions</p>
                                                                            <p className="value  property-title-msg">{listData.monthlySummary?.totalVisitors}</p>
                                                                        </div>
                                                                        <div className="column  property-column-3">
                                                                            <p className="title-msg property-title-msg">Next available slot</p>
                                                                            <p className="value  property-title-msg">{listData.nextAvailableDate}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    ) : (<div className="text-center">No Data found...</div>)}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {showMap && (
                    <div className="col-md-8">
                        <div className="map-section">
                            <div
                                ref={mapContainer}
                                style={{ position: "absolute", top: '20rem', bottom: 0, width: "64%", height: '80%' }}
                            />
                        </div>
                    </div>
                )}
            </div>

            <div className="modal fade property-unavailable" id="assignCreativeModal" aria-labelledby="assignCreativeLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-header property_list_popup_header ">
                            <h5 className="modal-title property_list_popup_header_content " id="assignCreativeLabel">Property unavailable</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {soldCampaignViewDetails.thumbnailPath ? (
                                <img className="card-img-top" alt={soldCampaignViewDetails.displayName} src={soldCampaignViewDetails.thumbnailPath} />
                            ) : (
                                <img className="detaillocation-frame-inner property-logo-img" alt="no image" src={NoImage} />
                            )}
                            {soldCampaignViewDetails.availableBooking ? (
                                <span className="badge badge-danger status-label props-status-label bg-light text-success"><i
                                    className="fa-solid fa-circle"></i> Available</span>
                            ) : (
                                <span className="badge badge-danger status-label props-status-label bg-light text-danger"><i
                                    className="fa-solid fa-circle"></i> Sold Out</span>
                            )}
                            <div className="d-flex m-0">
                                <h6 className="property-card-title3">{soldCampaignViewDetails.displayName}</h6>
                                <p className="property-card-text-3">{soldCampaignViewDetails.stateName}</p>
                            </div>
                            <p className="property-card-text-4">AUD {soldCampaignViewDetails.sellingRate ? soldCampaignViewDetails.sellingRate.monthly.month1 : ''}</p>

                            <p className="property_list_popup_notify_content">This property seems to be unavailable at this moment, try changing your campaign duration as per next available slot</p>

                            <div className="column  property-column-3">
                                <p className="title-msg property-title-msg">Next available slot</p>
                                <p className="value  property-title-msg">{soldCampaignViewDetails.nextAvailableDate}</p>
                            </div>

                            <button className="property_list_popup_gotit_btn" data-bs-dismiss="modal" aria-label="Close">Got it</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="filterModal" aria-labelledby="filterModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header property_list_filter_popup_header border-0" >
                            <h5 className="modal-title property_list_filter_popup_header_content" id="filterModalLabel">Filters</h5>
                            <button type="button" onClick={closeFilter} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body ">
                            <div className="modal-header selected-filters d-flex">
                                <p className="property_list_filter_popup_selected_input_header">Selected inputs</p>
                                <div className="property_list_scrollbar" id="style">
                                    <div>
                                        {badges.map((badge, index) => (
                                            <span className="badge property_list_filter_popup_selected_input" key={index}>
                                                {badge}
                                                <button
                                                    type="button"
                                                    className="btn-close btn text-primary property_list_filter_popup_selected_input_close"
                                                    aria-label="Close"
                                                    onClick={() => handleRemoveBadge(badge)}
                                                ></button>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex row">
                                {/* Filters Column */}
                                <div className="filter-column col-md-3" style={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', paddingLeft: '0px', paddingRight: '0px' }}>
                                    <h6 className="property_list_popup_list_header">Filters</h6>
                                    <div className="category-list">
                                        <p className={`property_list_popup_list_header_1 ${activeCategoryLocation === 'location' ? 'active' : ''}`}
                                            id="locationFilterLink"
                                            onClick={handleLocationFilterClick}>
                                            Location based filters
                                        </p>
                                        <p
                                            className={`property_list_popup_list_header_2 ${activeCategory?.id === 'venue' ? 'active' : ''}`}
                                            id="locationFilterLink_1"
                                            onClick={handleLocationVenueClick}
                                        >
                                            Venue type
                                        </p>
                                        <p className={`property_list_popup_list_header_2 ${activeCategorySite === 'site' ? 'active' : ''}`}
                                            id="locationFilterLink_3"
                                            onClick={handleLocationSiteClick}>
                                            Site based filters
                                        </p>
                                        <p className={`property_list_popup_list_header_2 ${activeCategoryBill === 'bill' ? 'active' : ''}`}
                                            id="locationFilterLink_4"
                                            onClick={handleLocationBillClick}>
                                            Billboard based filters</p>
                                        <p className={`property_list_popup_list_header_2 ${activeCategoryPrice === 'price' ? 'active' : ''}`}
                                            id="locationFilterLink_5"
                                            onClick={handleLocationPriceClick}>
                                            Price based filters</p>
                                    </div>
                                    <p className="property_list_popup_list_header_formcheck"><input type="checkbox" checked /> Show unavailable location</p>
                                    <p className="property_list_popup_list_header_formcheck_1"><input type="checkbox" /> Show location without picture</p>
                                </div>

                                <div className="content-column col-md-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    {showLocationFilters && (
                                        <div id="locationFilters" style={{ borderRight: '1px solid #ddd' }}>
                                            <h6 className="property_list_popup_list_header">Environment</h6>
                                            <div className="property_list_popup_list_cm">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="indoorCheck">
                                                        <input
                                                            type="checkbox"
                                                            id="indoorCheck"
                                                            onChange={(e) => handleCheckboxLocation('Indoor', e.target.checked)}
                                                            checked={indoorChecked}
                                                        /> Indoor
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="outdoorCheck">
                                                        <input
                                                            type="checkbox"
                                                            id="outdoorCheck"
                                                            onChange={(e) => handleCheckboxLocation('Outdoor', e.target.checked)}
                                                            checked={outdoorChecked}
                                                        /> Outdoor
                                                    </label>
                                                </div>

                                                <div className="mt-3">
                                                    <label htmlFor="selectState" className="form-label">Select State</label>
                                                    <select className="form-select property_list_select_state country-select" onChange={handleCountryChange}
                                                        id="selectState"
                                                        value={country}>
                                                        <option disabled value="">Country</option>
                                                        {countryOptions.length > 0 ? (
                                                            countryOptions.map((country) => (
                                                                <option key={country.id} value={country.id}>
                                                                    {country.name}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <option value="">No countries available</option>
                                                        )}</select>
                                                </div>

                                                <div className="mt-3">
                                                    <label htmlFor="selectState" className="form-label">Select District</label>
                                                    <select className="form-select property_list_select_state country-select" onChange={(e) => setState(e.target.value)}
                                                        value={state}
                                                        id="state"
                                                        disabled={!country}  >
                                                        <option disabled value="">State</option>
                                                        {stateOptions.length > 0 ? (
                                                            stateOptions.map((list) => (
                                                                list.stateId ? (
                                                                    <option key={list.stateId} value={list.stateId}>
                                                                        {list.name}
                                                                    </option>
                                                                ) : null
                                                            ))
                                                        ) : (
                                                            <option value="">No states available</option>
                                                        )}</select>
                                                </div>

                                                <p className="property_list_popup_list_header_4"></p>
                                                <p className="property_list_popup_list_header_4"></p>
                                                <p className="property_list_popup_list_header_4"></p>
                                                <p className="property_list_popup_list_header_4"> </p>
                                                <p className="property_list_popup_list_header_4"> </p>
                                                <p className="property_list_popup_list_header_4"></p>

                                            </div>
                                        </div>
                                    )}

                                    {showLocationVenue && (
                                        <div className="filter-column" style={{ borderRight: '1px solid #ddd', paddingRight: '0px' }}>
                                            <div className="row assign_creative_card_scroll">
                                                <h6 className="property_list_popup_list_header">Categories</h6>
                                                <div className="category-list">
                                                    {venueTypes?.map((category: Category, index: any) => (
                                                        <p
                                                            key={category.id || index}
                                                            className={`property_list_popup_list_header_1 ${activeCategory?.id === category.id ? 'active' : ''}`}  // Add active class here
                                                            onClick={() => handleLocationFinancialClick(category)}
                                                        >
                                                            {category.value}
                                                            <i className="fa-solid fa-chevron-right property_list_popup_rightarrow"></i>
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {showLocationSite && (
                                        <div className="filter-column" style={{ borderRight: '1px solid #ddd', paddingRight: '0px', paddingLeft: '12px' }}>
                                            <div className="filter-column" style={{ borderRight: '1px solid #ddd', paddingRight: '0px', paddingLeft: '12px' }}>
                                                <div className="row assign_creative_card_scroll">
                                                    <h6 className="property_list_popup_list_header">Site types</h6>
                                                    <div className="category-list">
                                                        <p className="property_list_popup_list_header_3">
                                                            <input type="radio" name="site-type" value="Classic" onChange={handleSiteTypeChange} /> Classic (55)
                                                        </p>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-type" value="Digital" onChange={handleSiteTypeChange} /> Digital (234)
                                                        </p>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-type" value="Network" onChange={handleSiteTypeChange} /> Network (125)
                                                        </p>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-type" value="Scrollable" onChange={handleSiteTypeChange} /> Scrollable (45)
                                                        </p>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-type" value="Package" onChange={handleSiteTypeChange} /> Package (67)
                                                        </p>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-type" value="Transit" onChange={handleSiteTypeChange} /> Transit (87)
                                                        </p>
                                                        <h6 className="property_list_popup_list_header">Screen Size (Digital)</h6>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-types" value="Small (15 sqft)" onChange={handleScreenSizeChange} /> Small (15 sqft)
                                                        </p>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-types" value="Medium (15-99 sqft)" onChange={handleScreenSizeChange} /> Medium (15-99 sqft)
                                                        </p>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-types" value="Large (100-249 Sqft)" onChange={handleScreenSizeChange} /> Large (100-249 sqft)
                                                        </p>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-types" value="Extra Large (249 Sqft)" onChange={handleScreenSizeChange} /> Extra Large (249 sqft)
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                    {showLocationBill && (
                                        <div className="filter-column" style={{ borderRight: '1px solid #ddd', paddingRight: '0px', paddingLeft: '12px' }}>
                                            <div className="row assign_creative_card_scroll">
                                                <h6 className="property_list_popup_list_header">Bill format based</h6>
                                                <div className="category-list">
                                                    <p className="property_list_popup_list_header_3" ><input type="checkbox" onChange={(e) => handleCheckboxLocationBill('96 sheets', e.target.checked)} checked={sheetsChecked} /> 96 sheets </p>
                                                    <p className="property_list_popup_list_header_4"><input type="checkbox" onChange={(e) => handleCheckboxLocationBill('Bulletin', e.target.checked)} checked={bulletin} /> Bulletin</p>
                                                    <p className="property_list_popup_list_header_4"><input type="checkbox" onChange={(e) => handleCheckboxLocationBill('City Lights', e.target.checked)} checked={cityLights} /> City Lights</p>
                                                    <p className="property_list_popup_list_header_4"><input type="checkbox" onChange={(e) => handleCheckboxLocationBill('Bus Shelter', e.target.checked)} checked={busShelter} /> Bus Shelter </p>
                                                    <p className="property_list_popup_list_header_4"><input type="checkbox" onChange={(e) => handleCheckboxLocationBill('Gallery', e.target.checked)} checked={gallery} /> Gallery </p>
                                                    <p className="property_list_popup_list_header_4"></p>
                                                    <p className="property_list_popup_list_header_4"></p>
                                                    <p className="property_list_popup_list_header_4"></p>
                                                    <p className="property_list_popup_list_header_4"> </p>
                                                    <p className="property_list_popup_list_header_4"> </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                    {showLocationPrice && (
                                        <div className="filter-column" style={{ borderRight: '1px solid #ddd', paddingRight: '0px', paddingLeft: '12px' }}>
                                            <div className="row assign_creative_card_scroll">
                                                <h6 className="property_list_popup_list_header">Average price</h6>
                                                <div className="category-list">
                                                    <div className="form-group">
                                                        <input
                                                            type="range"
                                                            className="form-range"
                                                            min="1"
                                                            max="3500"
                                                            step="10"
                                                            value={minPrice}
                                                            onChange={handleMinPriceChange}
                                                        />
                                                    </div>

                                                    <div className="input-group assign_creative_input_group mt-4">
                                                        <p className="property_list_popup_list_header_5">Min price</p>
                                                        <input
                                                            type="number"
                                                            className="form-control border-bottom-only assign_creative_border_bottom_only"
                                                            aria-label="Search"
                                                            value={minPrice}
                                                            onChange={handleMinPriceChange}
                                                        />
                                                    </div>

                                                    <div className="input-group assign_creative_input_group mt-4">
                                                        <p className="property_list_popup_list_header_5">Max price</p>
                                                        <input
                                                            type="number"
                                                            className="form-control border-bottom-only assign_creative_border_bottom_only"
                                                            aria-label="Search"
                                                            value={maxPrice}
                                                            onChange={handleMaxPriceChange}
                                                        />
                                                    </div>
                                                    <button onClick={handlePriceRangeChange} className="btn btn-primary mt-4 assign_creative_footer_btn_2">
                                                        Apply Price Range
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="content-column col-md-3" style={{ paddingLeft: '0px', paddingRight: '0px' }} >
                                    {showLocationFinancial && activeCategory && (
                                        <div className="filter-column" style={{ borderRight: '1px solid #ddd', paddingLeft: '0px' }}>
                                            <h6 className="property_list_popup_list_header">Sub-categories</h6>
                                            <div className="category-list">
                                                {activeCategory.child?.map((subCategory: SubCategory, index: any) => (
                                                    <p
                                                        key={subCategory.id || index}
                                                        className={`property_list_popup_list_header_2 ${activeSubCategory?.id === subCategory.id ? 'active' : ''}`}
                                                        onClick={() => handleSubCategoryClick(subCategory)}
                                                    >
                                                        {subCategory.value}
                                                        <i className="fa-solid fa-chevron-right property_list_popup_rightarrow"></i>
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="content-column col-md-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    {showLocationFinancial && activeSubCategory && (
                                        <div className="filter-column" style={{ borderLeft: '1px solid #ddd' }}>
                                            <h6 className="property_list_popup_list_header">Products</h6>
                                            <div className="category-list">
                                                {activeSubCategory.child?.map((gradSubCategory: GrandSubCategory, index: number) => (
                                                    <div key={gradSubCategory.id || index} className="d-flex align-items-center">
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox-${gradSubCategory.id}`}
                                                            checked={badges.includes(gradSubCategory.value)}
                                                            onChange={() => handleCheckboxChange(gradSubCategory)}
                                                            className="grdcategory-checkbox"
                                                        />
                                                        <p
                                                            className={`property_list_popup_list_header_3 ${activeGradSubCategory?.id === gradSubCategory.id ? 'active' : ''}`}
                                                            onClick={() => handleGradSubCategoryClick(gradSubCategory)}
                                                        >
                                                            {gradSubCategory.value}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn assign_creative_footer_btn_1" onClick={() => resetAllStates()}>
                                    Reset
                                </button>
                                <button type="button" className="btn btn-primary assign_creative_footer_btn_2" data-bs-dismiss="modal" onClick={() => fetchBillBoardList()}>
                                    Apply filters
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="exploreDate" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content explore-content">
                        <div className="modal-header explore_property_filter_popup_header border-0" >
                            <h5 className="modal-title exploredate_title">Select Dates</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body explore_datebody">
                            <div className="explore_startDate">
                                <label htmlFor="explore_startDate">Start Date:</label>
                                <div className="property_list_date_start d-flex align-items-center position-relative">
                                    <div onClick={toggleStartDatePickers} className="calendar-icon-wrapper">
                                        <img alt="Start Date" src={Image7} className="date-icon" />
                                    </div>
                                    <div className="date-text-1 explore-date">
                                        <p style={{ "borderBottom": "1.5px solid black" }}>
                                            {startDates ? format(startDates, "dd MMM yyyy") : "Select Start Date"}
                                        </p>
                                    </div>
                                    {showStartDatePickers && (
                                        <div className="datepicker-wrapper">
                                            <div className="close-icon" onClick={toggleStartDatePickers}>×</div>
                                            <p className="card_date_header">Select Campaign start date</p>
                                            {showYearPickers ? (
                                                <div className="year-picker">
                                                    <div className="years-grid">
                                                        {Array.from({ length: 10 }, (_, i) => (
                                                            <button key={i} onClick={() => handleYearSelections(selectedYears + i)} className="year-button">
                                                                {selectedYears + i}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : showMonthPickers ? (
                                                <div className="month-picker">
                                                    <div className="months-grid">
                                                        {Array.from({ length: 12 }, (_, i) => (
                                                            <button key={i} onClick={() => handleMonthSelections(i)} className="month-button">
                                                                {format(new Date(2021, i), "MMM")}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (
                                                <DatePicker
                                                    selected={tempStartDates}
                                                    onChange={handleStartDateChanges}
                                                    inline
                                                    renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                        <div className="custom-header">
                                                            <button onClick={(e) => { handleMonthButtonClicks(e); decreaseMonth(); }}>{"«"}</button>
                                                            <span onClick={toggleMonthPickers}>{format(date, "MMMM")}</span>
                                                            <span onClick={toggleYearPickers}>{format(date, "yyyy")}</span>
                                                            <button onClick={(e) => { handleMonthButtonClicks(e); increaseMonth(); }}>{"»"}</button>
                                                        </div>
                                                    )}
                                                />
                                            )}
                                            <button className="update-button" onClick={handleUpdateStartDates}>Update</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="explore_endDate">
                                <label htmlFor="explore_endDate">End Date:</label>
                                <div className="property_list_date_end d-flex align-items-center position-relative">
                                    <div onClick={toggleEndDatePickers} className="calendar-icon-wrapper">
                                        <img alt="End Date" src={Image7} className="date-icon" />
                                    </div>
                                    <div className="date-text-1 explore-date explore-enddate">
                                        <p style={{ "borderBottom": "1.5px solid black" }}>
                                            {endDates ? format(endDates, "dd MMM yyyy") : "Select End Date"}
                                        </p>
                                    </div>
                                    {showEndDatePickers && (
                                        <div className="datepicker-wrapper">
                                            <div className="close-icon" onClick={toggleEndDatePickers}>×</div>
                                            <p className="card_date_header">Select Campaign end date</p>
                                            {showYearPickers ? (
                                                <div className="year-picker">
                                                    <div className="years-grid">
                                                        {Array.from({ length: 10 }, (_, i) => (
                                                            <button key={i} onClick={() => handleYearSelections(selectedYears + i)} className="year-button">
                                                                {selectedYears + i}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : showMonthPickers ? (
                                                <div className="month-picker">
                                                    <div className="months-grid">
                                                        {Array.from({ length: 12 }, (_, i) => (
                                                            <button key={i} onClick={() => handleMonthSelections(i)} className="month-button">
                                                                {format(new Date(2021, i), "MMM")}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (
                                                <DatePicker
                                                    selected={tempEndDates}
                                                    onChange={handleEndDateChanges}
                                                    inline
                                                    renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                        <div className="custom-header">
                                                            <button onClick={(e) => { handleMonthButtonClicks(e); decreaseMonth(); }}>{"«"}</button>
                                                            <span onClick={toggleMonthPickers}>{format(date, "MMMM")}</span>
                                                            <span onClick={toggleYearPickers}>{format(date, "yyyy")}</span>
                                                            <button onClick={(e) => { handleMonthButtonClicks(e); increaseMonth(); }}>{"»"}</button>
                                                        </div>
                                                    )}
                                                />
                                            )}
                                            <button className="update-button" onClick={handleUpdateEndDates}>Update</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary explore_dateSubmit" data-bs-dismiss="modal" aria-label="Close" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* cartloginPopup */}
            <div className="modal fade" id="exploreLogin" aria-labelledby="exploreLoginLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="exploremodal-content">
                        <div className="modal-header explorelogin_header">
                            <h1 className="modal-title fs-5" id="exploreLoginLabel"></h1>
                            <button id="explore-login-close-btn" type="button" ref={modalRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body row explorelogin-body">
                            <div className="exploreLogin_left col-md-6">
                                <div className="explorelogin-leftside">

                                    <img className='explorelogo-style exploreloginfilled-your-logo-icon' src={LogoImage} alt="edde" />

                                    <h1 className="exploreloginfilled-welcome-to-outfront-container font">
                                        <p className="loginfilled-welcome-to">Welcome to</p>
                                        <p className="loginfilled-outfront-media">OutFront Media</p>
                                    </h1>

                                    <h1 className="exploreloginfilled-run-your-next">
                                        Run your next outdoor Advertising campaign with just a few clicks
                                    </h1>
                                </div>
                            </div>
                            <div className="exploreLogin_right col-md-6">
                                <form>
                                    <div className="loginfilled-login-form-parents exploreloginfilled-login-form-parent">
                                        <div className="image-bg-style">
                                            <div className="card cart-bg-style explore-rightside">
                                                <div className="right-side-alignment exploreright-side-alignment">
                                                    <h4 className="exploreloginfilled-please-enter-your">
                                                        Please enter your login and password
                                                    </h4>

                                                    {error && <div className="error-message">{errors}</div>}

                                                    <div className="loginfilled-hatimbig-parent username-input-top">
                                                        <input
                                                            className="loginfilled-hatimbig"
                                                            placeholder="User Name"
                                                            type="text"
                                                            name="username"
                                                            value={username}
                                                            onChange={(e) => setUsername(e.target.value)}
                                                        />
                                                        <div
                                                            className={`loginfilled-frame-username ${submit && !username ? 'error' : ''}`}
                                                        ></div>
                                                        {submit && username.length === 0 ? (
                                                            <div className="text-danger error-message-required">Username is required</div>
                                                        ) : ""
                                                        }
                                                    </div>

                                                    <div className="loginfilled-hatimbig-parent">
                                                        <input
                                                            className="loginfilled-hatimbig"
                                                            placeholder="Password"
                                                            type={showPassword ? 'text' : 'password'}
                                                            id="passwordInput"
                                                            name="password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}

                                                        />
                                                        <div
                                                            className={`loginfilled-frame-password ${submit && !password ? 'error' : ''}`}
                                                        ></div>

                                                        {submit && password.length === 0 && (
                                                            <div className="text-danger error-message-required">Password is required</div>
                                                        )}
                                                        <i
                                                            className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} password-eye`}
                                                            id="togglePassword"
                                                            onClick={togglePasswordVisibility}
                                                            style={{ cursor: 'pointer' }}
                                                        ></i>

                                                        <div className="forgot-link">
                                                            <div className="row">
                                                                <div className="col-md-12 loginfilled-forget-password explore-forgetpassword">
                                                                    <a href="/forgot-password">Forgot password</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="exploreloginfilled-login-btn">

                                                        {loading ? <button type="submit" onClick={handleLogin}>
                                                            <div className="spinner-loading">
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                Loading...
                                                            </div>
                                                        </button> : <button type="submit" onClick={handleLogin}>
                                                            Login
                                                        </button>}


                                                    </div>

                                                    <div className="register-link">
                                                        <span className="loginfilled-not-a-member">
                                                            <a href="/sign-up" className="exploreloginfilled-register-now" id="signup-link">
                                                                Sign Up
                                                            </a>
                                                        </span>
                                                        <span className="signup-line"> | </span>
                                                        <Link to="" data-bs-toggle="modal" data-bs-target="#exploreSubmit" className="exploreloginfilled-register-now" id="signup-link">
                                                            Submit Enquiry
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* submitEnquiryPopup */}

            <div className="modal fade" id="exploreSubmit" aria-labelledby="exploreSubmitLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header exploreSubmit-header">
                            <h1 className="modal-title fs-5" id="exploreSubmitLabel">Submit Enquiry</h1>
                            <button id="explore-login-submit-btn" type="button" ref={modalRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body exploreSubmit-body">
                            <h6>General details</h6>
                            <div className="row">
                                {error && <div className="error-message">{errors}</div>}
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="submitName" className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className={`form-control exploreSubmitInput ${submit && submitName.length === 0 ? 'error-border' : ''}`}
                                        id="submitName"
                                        value={submitName}
                                        onChange={(e) => setSubmitName(e.target.value)}
                                    />
                                    <div
                                        className={`loginfilled-frame-username ${submit && !submitName ? 'error' : ''}`}
                                    ></div>
                                    {submit && submitName.length === 0 ? (
                                        <div className="text-danger error-message-required">Username is required</div>
                                    ) : ""
                                    }
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="submitCompanyName" className="form-label">Company Name</label>
                                    <input
                                        type="text"
                                        className={`form-control exploreSubmitInput ${submit && submitCompanyName.length === 0 ? 'error-border' : ''}`}
                                        id="submitCompanyName"
                                        value={submitCompanyName}
                                        onChange={(e) => setSubmitCompanyName(e.target.value)}
                                    />
                                    <div
                                        className={`loginfilled-frame-username ${submit && !submitCompanyName ? 'error' : ''}`}
                                    ></div>
                                    {submit && submitCompanyName.length === 0 ? (
                                        <div className="text-danger error-message-required">Company Name is required</div>
                                    ) : ""
                                    }
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="submitEmail" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className={`form-control exploreSubmitInput ${submit && submitEmail.length === 0 ? 'error-border' : ''}`}
                                        id="submitEmail"
                                        value={submitEmail}
                                        onChange={(e) => setSubmitEmail(e.target.value)}
                                    />
                                    <div
                                        className={`loginfilled-frame-username ${submit && !emailRegex.test(submitEmail) ? 'error' : ''}`}
                                    ></div>
                                    {submit && !emailRegex.test(submitEmail) ? (
                                        <div className="text-danger error-message-required">Please enter a valid email address</div>
                                    ) : ""
                                    }
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="submitPhone" className="form-label">Phone</label>
                                    <input
                                        type="text"
                                        className={`form-control exploreSubmitInput ${submit && submitPhone.length === 0 ? 'error-border' : ''}`}
                                        id="submitPhone"
                                        value={submitPhone}
                                        onChange={(e) => setSubmitPhone(e.target.value)}
                                    />
                                    <div
                                        className={`loginfilled-frame-username ${submit && !phoneRegex.test(submitPhone) ? 'error' : ''}`}
                                    ></div>
                                    {submit && submitPhone.length === 0 ? (
                                        <div className="text-danger error-message-required">Phone number is required</div>
                                    ) : ""}
                                    {submit && !phoneRegex.test(submitPhone) && submitPhone.length > 0 ? (
                                        <div className="text-danger error-message-required">Please enter a valid phone number.</div>
                                    ) : ""}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="submitAddress" className="form-label">Address</label>
                                    <input
                                        type="text"
                                        className="form-control exploreSubmitInput border-bottom-only no-focus-border"
                                        id="submitAddress"
                                        value={submitAddress}
                                        onChange={(e) => setSubmitAddress(e.target.value)}
                                    />
                                </div>

                                <h6>Schedule details</h6>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="submitStartDate" className="form-label">Start Date</label>
                                    <input
                                        type="date"
                                        className={`form-control exploreSubmitInput ${submit && submitStartDate.length === 0 ? 'error-border' : ''}`}
                                        id="submitStartDate"
                                        value={submitStartDate}
                                        onChange={(e) => setSubmitStartDate(e.target.value)}
                                    />
                                    <div
                                        className={`loginfilled-frame-username ${submit && !submitStartDate ? 'error' : ''}`}
                                    ></div>
                                    {submit && submitStartDate.length === 0 ? (
                                        <div className="text-danger error-message-required">Start date is required</div>
                                    ) : ""
                                    }
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="submitEndDate" className="form-label">End Date</label>
                                    <input
                                        type="date"
                                        className={`form-control exploreSubmitInput ${submit && submitEndDate.length === 0 ? 'error-border' : ''}`}
                                        id="submitEndDate"
                                        value={submitEndDate}
                                        onChange={(e) => setSubmitEndDate(e.target.value)}
                                    />
                                    <div
                                        className={`loginfilled-frame-username ${submit && !submitEndDate ? 'error' : ''}`}
                                    ></div>
                                    {submit && submitEndDate.length === 0 ? (
                                        <div className="text-danger error-message-required">End date is required</div>
                                    ) : ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="exploreSubmitClose" data-bs-dismiss="modal">Close</button>
                            <button
                                type="button"
                                className="exploreSubmitRequest"
                                onClick={handleSubmitEnquiry}

                            >
                                Request for approval
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Submit Success */}
            <div className="modal fade" id="successModal" tabIndex={-1} aria-labelledby="successModalLabel" aria-hidden="true" ref={successModalRef}>
                <div className="modal-dialog modal-dialog-centered model-xl">
                    <div className="modal-content">
                        <div className="modal-header assign_creative_apply_success_header">
                            <h5 className="modal-title assign_creative_apply_success_header_content" id="successModalLabel">Success message</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <i className="fa-regular fa-thumbs-up assign_creative_popup_success_icon"></i>
                            <p className="assign_creative_popup_success_con_2">Successfully submitted enquiry
                                Our representative will reach out to you shortly</p>
                        </div>
                        <div className="modal-footer justify-content-center border-0">
                            <button type="button" className="assign_creative_footer_btn_1" data-bs-dismiss="modal" ref={modalRef} onClick={handleSubmitSuccess}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default BillBoardListingExploreProperty;