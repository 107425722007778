import React, { Component, useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import client from "../../../Graphql/apolloClient";
import { EXPLORE_PROPERTY_DETAILS, PROPERTY_DETAILS } from "../../../Graphql/Queries";
import Image2 from "../../../assets/images/group-212840670.svg";
import Image3 from "../../../assets/images/photo-1639054515827-41fb52f3058d.avif";
import Image9 from "../../../assets/images/group-21.svg";
import { useMutation } from "@apollo/client";
import { ADD_TO_CART_SUBMIT, DELETE_CART_SUBMIT } from "../../../Graphql/cartQueries";
import { format } from 'date-fns';
import { toast } from "react-toastify";
import { GET_LANDING_EXPLORATION } from "../../../Graphql/PropertyListQueries";

interface PropertyDetails {
    displayName: string;
    id: string;
    sellingRate: {
        daily: number;
    }
    monthlySummary: {
        totalVisitors: number;
    }
    stateName: string;
    price: {
        openAuctionCurrency: string;
    }
    specification: {
        screens: number;
        resolution1Height: string;
        resolution1Width: string;
        playerSoftwareModule: {
            active: boolean
        }
    }
    nextAvailableDate: string | number | null;
    thumbnailPath: string;

}

interface PropertyDetailsYouMayLike {
    dataCartList: any;
    refetchCartList: () => void;
}
const ExplorePropertyDetailsYouMayLike: React.FC<PropertyDetailsYouMayLike> = ({
    dataCartList,
    refetchCartList,
}) => {
    const [redirect, setRedirect] = useState<string | null>(null);
    const [propertyDetailsStoreValue, setPropertyDetailsStoreValue] = useState<PropertyDetails[]>([]);
    const location = useLocation();
    const propertyDetailsValue = location.state?.propertyDetailsData || {};
    const [addtoCartQuery] = useMutation(ADD_TO_CART_SUBMIT);
    const [deleteCartQuery] = useMutation(DELETE_CART_SUBMIT);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem("companyId");
    const countryId = localStorage.getItem("countryId");
    const currentDate = new Date();
    const [campaignName, setCampaignName] = useState<string | null>(propertyDetailsValue?.campaignName);
    const [startDate, setStartDate] = useState<Date>(propertyDetailsValue?.startDate || currentDate);
    const [endDate, setEndDate] = useState<Date>(propertyDetailsValue?.endDate || currentDate);
    const [isInCart, setIsInCart] = useState(false);
    const [billBoardData, setBillBoardData] = useState<any>([]);
    const [cartData, setCartData] = useState<any>([{}]);
    useEffect(() => {
        if (dataCartList && propertyDetailsStoreValue) {
            console.log("Refetch cart list dataCartList: ", dataCartList);
            const cartDataList = dataCartList.cartpageItem;
            setCartData(cartDataList);

            const resultObj = propertyDetailsStoreValue[0]; // Assuming you want to access the first object in the array
            const cartObj = cartDataList;

            if (resultObj && cartObj.length > 0) {
                cartObj.forEach((cartItem: any) => {
                    // Match cart item name with display name
                    if (cartItem.cartItemName === resultObj.displayName) {
                        console.log('====================================');
                        console.log(cartItem.cartItemName, "/", resultObj.displayName);
                        console.log('====================================');

                        const updatedBillBoardData = [resultObj].map((obj: { id: any }) => {
                            if (obj.id === cartItem.cartItemId) {
                                return { ...obj, isInCart: true };
                            } else {
                                return { ...obj, isInCart: false };
                            }
                        });

                        setBillBoardData(updatedBillBoardData);
                    }
                });
            }
        }
    }, [dataCartList, propertyDetailsStoreValue]);


    // const fetchPropertyDetailsYouMayLike = async () => {
    //     const token = localStorage.getItem("authToken");
    //     const companyId = localStorage.getItem("companyId");
    //     const countryId = localStorage.getItem("countryId");
    //     try {
    //         const { data } = await client.query({
    //             query: PROPERTY_DETAILS,
    //             variables: {
    //                 accessToken: token,
    //                 id: propertyDetailsValue?.id,
    //                 companyId: companyId,
    //                 countryId: countryId,
    //                 billboardType: propertyDetailsValue?.type

    //             }
    //         })
    //         setPropertyDetailsStoreValue([data.propertyDetails]);
    //         console.log("Fetched Property Details YouMayLike----->:", data.propertyDetails);

    //     } catch (error) {
    //         console.error("Error fetching property details:", error);
    //     }
    // }

    const fetchPropertyDetailsYouMayLike = async () => {
        setLoading(true);
        try {
            const { data } = await client.query({
                query: EXPLORE_PROPERTY_DETAILS,
                variables: {
                    id: propertyDetailsValue?.id,
                    companyId: companyId || '642685a0421c2678de8421ac',
                    countryId: countryId || '573aa8c388041e1667e335d1',
                    billboardType: propertyDetailsValue?.type.toLowerCase(),

                },
            });

            const propertyDetails = data?.propertyDetailsWithoutLogin;
            // console.log("Fetched Property Details summary----->:", propertyDetails);
            const billboardObjectId = propertyDetails?.billboardObjectId;
            if (billboardObjectId) {
                await fetchBillBoardList(billboardObjectId);
            }
        } catch (error) {
            console.error("Error fetching property details:", error);
        } finally {
            setLoading(false);
        }
    };


    const fetchBillBoardList = async (billboardObjectId: string, cartItems: any = "") => {
        setLoading(true);
        try {
            // console.log("Fetching Billboards for ID: ", billboardObjectId);
            setBillBoardData([]);

            const filterData = {
                type: propertyDetailsValue?.type,
                availableBooking: true,
                categories: [],
                format: [],
                venueType: [],
            };
            const lowercaseType = propertyDetailsValue?.type?.toLowerCase();

            const { data } = await client.query({
                query: GET_LANDING_EXPLORATION,
                variables: {
                    sort: "last_modified_date,desc",
                    billboard: lowercaseType,
                    userId: userId,
                    companyId: companyId || '642685a0421c2678de8421ac',
                    page: 0,
                    size: 12,
                    startDate: format(startDate, 'yyyy-MM-dd'),
                    endDate: format(endDate, 'yyyy-MM-dd'),
                    dspName: "LMX-ECOMMERCE",
                    countryId: countryId || '573aa8c388041e1667e335d1',
                    searchTerm: "",
                    filterData: filterData,
                },
            });

            if (data) {
                // console.log("Billboard data testing: ", data);
                let resultObj = data.landingpageExplorePropertiesNotoken;
                if (resultObj.length > 0 && cartItems.length > 0) {
                    resultObj = resultObj.map((obj: any) => {
                        const isInCart = cartItems.some((cartItem: any) => cartItem.cartItemId === obj.id);
                        return { ...obj, isInCart };
                    });
                }
                setBillBoardData(resultObj);
                const matchedBillboard = resultObj.find((billboard: any) => billboard.id === billboardObjectId);
                if (matchedBillboard) {
                    console.log('====================================');
                    console.log(matchedBillboard, 'specification explore');
                    console.log('====================================');
                    setPropertyDetailsStoreValue([matchedBillboard]);

                }
            }
        } catch (error) {
            console.error("Error fetching billboard list:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPropertyDetailsYouMayLike();
    }, [])

    if (redirect) {
        return <Navigate to={redirect} />;
    }
    const addToCart = async (itemData: any) => {
        try {
            const { data, errors } = await addtoCartQuery({
                variables: {
                    accessToken: token,
                    userId: userId,
                    campaignName: campaignName,
                    startDate: format(startDate, 'dd MMMM yyyy'),
                    endDate: format(endDate, 'dd MMMM yyyy'),
                    cartItemName: itemData.displayName,
                    cartItemId: propertyDetailsValue?.id,
                    cartItemReferenceId: itemData.referenceId,
                    cartItemType: itemData.type,
                    cartItemCountry: itemData.stateName,
                    cartItemVenueType: itemData.venueType,
                    cartItemResolution: `${itemData.specification.resolution1Height}x${itemData.specification.resolution1Width}`,
                    cartItemThumbnailUrl: itemData.thumbnailPath,
                    cartItemLatitude: itemData.latitude,
                    cartItemLongitude: itemData.longitude,
                    packageDetails: ""
                }
            });

            if (data) {
                const { success, message } = data.addtocart.itemData;

                if (success) {
                    console.log("Add to Cart: ", data);
                    refetchCartList();
                    toast.success(message);
                    setIsInCart(true);
                } else {
                    toast.error(message || "Failed to add item to cart.");
                }
            } else {
                toast.error("No data returned from the server.");
            }

            if (errors) {
                toast.error(errors[0]?.message || "An error occurred while adding to cart.");
            }
        } catch (error) {
            console.error("Error adding to cart:", error);
            toast.error("An unexpected error occurred.");
        }
    };
    const deleteCartItem = async (itemData: any) => {
        console.log(itemData, "itemDataitemDataitemDataitemData");

        const matchingCartItem = cartData.find((cartItem: any) => cartItem.cartItemName === itemData);

        console.log(matchingCartItem, "matchingCartItemmatchingCartItem");

        if (!matchingCartItem) {
            console.log("No matching item found in cart.");
            toast.error("Item not found in cart.");
            return;
        }

        // Extract cartItemId from the matching cart item
        const cartItemId = matchingCartItem.id;
        console.log("Matching cartItemId: ", cartItemId);
        if (propertyDetailsStoreValue.length > 0 && propertyDetailsStoreValue[0].displayName === itemData) {
            try {
                const { data, errors } = await deleteCartQuery({
                    variables: {
                        accessToken: token,
                        deleteItemId: cartItemId,
                        itemType: "cart-data"
                    }
                });

                if (data) {
                    console.log("Delete cart item response: ", data);
                    if (data.deleteCartItem.success) {

                        const updatedBillBoardData = billBoardData.map((obj: { id: any; }) => {
                            if (obj.id === matchingCartItem.cartItemId) {
                                return { ...obj, isInCart: false };
                            }
                            return obj;
                        });
                        setBillBoardData(updatedBillBoardData);

                        refetchCartList();
                        setIsInCart(false);
                        toast.success(data.deleteCartItem.message);
                    } else {
                        toast.error(data.deleteCartItem.message);
                    }
                }

                if (errors) {
                    toast.error(errors[0].message);
                }
            } catch (error) {
                console.log("Error deleting cart item:", error);
            }
        } else {
            console.log("The cart item name does not match the property name.");
            toast.error("The cart item does not match the selected property.");
        }
    };

    const ShowAddDeleteCartBtn = (curObj: string) => {
        let status = false;
        cartData.forEach((cartItem: any) => {
            if (curObj === cartItem.cartItemName) {
                status = true;
            }
        });
        return status;
    };


    return (
        <>
            <div className="col-md-12 cart-you-may-like">
                <div className="card-head-line">
                    <h5>You may also like these properties</h5>
                </div>
                {propertyDetailsStoreValue.map((val, ind) => (
                    <div className="row" key={ind}>
                        <div className="col-md-4">
                            <div className="card props-hover mb-3 border-0">
                                <img src={val.thumbnailPath || Image3} className="card-img-top"
                                    alt={val.displayName || "Property Image"} />

                                {val?.specification?.playerSoftwareModule?.active ? (
                                    <span className="badge badge-success status-label props-status-label bg-light text-success">
                                        <i className="fa-solid fa-circle"></i> Available
                                    </span>
                                ) : (
                                    <span className="badge badge-danger status-label props-status-label bg-light text-danger">
                                        <i className="fa-solid fa-circle"></i> Sold Out
                                    </span>
                                )}

                                <div className="card-img-overlay card-img-overlay-custom">
                                    {
                                        val?.specification?.playerSoftwareModule?.active && (
                                            ShowAddDeleteCartBtn(val.displayName) ? (

                                                <button
                                                    className="btn btn-danger propety-btn-danger"
                                                    onClick={() => deleteCartItem(val.displayName)}
                                                >
                                                    <img className="detaillocation-frame-inner property-logo-img" alt="" src={Image9} />
                                                    Delete
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-primary propety-btn-primary w-100"
                                                    onClick={() => addToCart(val)}
                                                >
                                                    <img className="detaillocation-frame-inner property-logo-img" alt="" src={Image9} />
                                                    Add to Cart
                                                </button>
                                            )
                                        )
                                    }
                                    <a href="#" className="btn text-light mt-2 border-0">View details</a>
                                </div>
                                <div className="property-card-body property-card-3">
                                    <div className="d-flex m-0">
                                        <h6 className="property-card-title3">{val.displayName}</h6>
                                        <p className="property-card-text-3">{val.stateName || "N/A"}</p>
                                    </div>
                                    <p className="property-card-text-4">{val.price?.openAuctionCurrency} {val.sellingRate?.daily || "N/A"}</p>
                                    <div className="list-unstyled">
                                        <div className="row property-row-3">
                                            <div className="column property-column-3">
                                                <p className="title-msg property-title-msg">Site resolution</p>
                                                <p className="value  property-title-value">{val.specification?.resolution1Width || "N/A"} x {val.specification?.resolution1Height || "N/A"} </p>
                                            </div>
                                            <div className="column property-column-3">
                                                <p className="title-msg property-title-msg">Number of screens</p>
                                                <p className="value  property-title-msg">{val.specification?.screens || "N/A"}</p>
                                            </div>
                                        </div>
                                        <div className="row property-row-3">
                                            <div className="column property-column-3">
                                                <p className="title-msg property-title-msg">Impressions</p>
                                                <p className="value  property-title-msg">{val.monthlySummary?.totalVisitors || "N/A"}</p>
                                            </div>
                                            <div className="column  property-column-3">
                                                <p className="title-msg property-title-msg">Next available slot</p>
                                                <p className="value  property-title-msg">{val.nextAvailableDate || "N/A"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </>
    );
}

export default ExplorePropertyDetailsYouMayLike;
