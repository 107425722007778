import React, { Component, useEffect, useState } from "react";
import DefaultLayout from "../../layouts/default";
import { Link, Navigate } from "react-router-dom";
import LogoImage from "../../assets/brand/your-logo.svg";
import './index.css'
import CompanyDetailsForm from "../../components/company-details/form";
interface FormState {
  redirect: string | null,
}
const CompanyDetails =()=> {
  const [config, setConfig] = useState<any[]>([]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/json/configJson.json`);
        const data = await response.json();
        const companyDetailsConfig = data.find((page: any) => page.name === "User Registration")?.configurations.find((c: any) => c.key === "basicInformation");
        console.log(config,'fff');
        if (companyDetailsConfig) {
          setConfig(companyDetailsConfig.fields);
        }
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    fetchConfig();
  }, []);
  const isFieldEnabled = (key: string) => {
    console.log(key,'key');
    console.log(config,'fff');
    const fieldConfig = config.find((fields: any) => fields.key === key);
    console.log(fieldConfig,'config');
    return fieldConfig ? fieldConfig.default : true;
  };
    return (
      <DefaultLayout>
         <section  className="container login-basic-height-details">
          <div  className="row">
              <div className="col-md-6 login-leftside">

                  <img className='logo-style loginfilled-your-logo-icon' src={LogoImage} alt="edde" />

                  <h1 className="loginfilled-welcome-to-outfront-container font">
                      <p className="loginfilled-welcome-to">Welcome to</p>
                      <p className="loginfilled-outfront-media">OutFront Media</p>
                  </h1>

                  <h1 className="company-details-run-your-next">
                      Run your next outdoor Advertising campaign with just a few clicks
                  </h1>
              </div>
              <div className="col-md-6 login-rightside">
              <CompanyDetailsForm 
              companyNameInput={isFieldEnabled("company name")}
              designationInput={isFieldEnabled("designation")}
              phoneCountryCodeInput={isFieldEnabled("phone number with Country Code")}
              />
              </div>

          </div>
      </section>
      </DefaultLayout>
    );
  }
export default CompanyDetails;
