import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthLayout from "../../layouts/auth";
import FilterLogo from "../../assets/images/sliders@2x.png";
import BackIcon from "../../assets/images/back-icon.svg";
import SearchLogo from "../../assets/images/search.svg";
import DoubleChevron from "../../assets/images/doublechevron.svg";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import client from "../../Graphql/apolloClient";
import { GRAPHQL_URI } from '../../Graphql/apolloClient';
import { CONTENT_HUB_QUERY, DELETE_CONTENT_MUTATION } from "../../Graphql/Queries";
import './index.css';
import { generateMockData } from "../../Graphql/MockData";

interface ContentHubList {
  id: string;
  fileName: string;
  mimeType: string;
  thumbnail: string;
  resolution: string;
  duration: string;
  filePath: string;
}

const ContentHub = () => {
  const [contentHubList, setContentHubList] = useState<ContentHubList[]>([]);
  const [filteredResults, setFilteredResults] = useState<ContentHubList[]>([]);
  const [deleteContent] = useMutation(DELETE_CONTENT_MUTATION);
  const [selectedResolutions, setSelectedResolutions] = useState<string[]>([]);
  const [selectedMimeType, setSelectedMimeType] = useState<string | null>("");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState<string | null>(null);

  const fetchContentHubList = async (page: number = currentPage) => {
    const token = localStorage.getItem("authToken");
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");

    if (!token) {
      toast.error("No authentication token found.");
      return;
    }
    setLoading(true);
    const variables = {
      accessToken: token,
      page: page - 1,
      size: 10,
      metadatatype: "LMX_CREATIVE",
      metadatacampaign: false,
      companyId: companyId,
      creatives: true,
      userId: userId,
      sort: "lastModifiedDate,desc",
      fileName: search,
      resolution: selectedResolutions.length > 0 ? selectedResolutions.join(',') : "",
      mimeType: selectedMimeType || "",
    };

    try {
      const { data } = await client.query({
        query: CONTENT_HUB_QUERY,
        variables
      });
      const contentHub = data.contentHub || [];
      setContentHubList(contentHub);
      setFilteredResults(contentHub);
      const totalPages = data.totalPages || Math.ceil(contentHub.length / 5);
      setTotalPages(totalPages);

    } catch (err) {
      console.error("Fetch Content Error:", err);
      toast.error("An unexpected error occurred while fetching content");
    } finally {
      setLoading(false);
    }
  };

  // Handle page change logic
  const handlePageChange = (page: number) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    fetchContentHubList(page);
  };

  const handleCheckboxChange = (id: string) => {
    setSelectedIds(prev =>
      prev.includes(id) ? prev.filter(selectedId => selectedId !== id) : [...prev, id]
    );
  };
  //Select all checkbox
  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const allIds = filteredResults.map(content => content.id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };
  useEffect(() => {
    if (process.env.REACT_APP_MOCK_DATA === 'true') {
      generateMockData(CONTENT_HUB_QUERY).then((mockResponse: any) => {
        console.log("mockData", mockResponse);
        const data = mockResponse.data
        setContentHubList(data.contentHub);
        setFilteredResults(data.contentHub);
        setTotalPages(data.contentHub[0]?.totalPages || 0);

      }).catch((err: any) => {
        console.error("Error generating mock data:", err);
      });
    } else {
      fetchContentHubList();
    }

  }, [currentPage, search]);

  const handleDeleteItem = (id: string) => {
    setDeleteItemId(id);
    const modal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    modal.show();
  };

  const confirmDelete = async () => {
    const token = localStorage.getItem("authToken");
    if (!token || !deleteItemId) return;

    try {
      const response = await deleteContent({ variables: { accessToken: token, contentIds: [deleteItemId] } });
      const message = response.data.deleteContent[0].message;
      if (message.startsWith("error.")) {
        toast.warn("Internal server Error");
      } else {
        toast.success(message);
        window.location.reload();
        fetchContentHubList();
      }
    } catch (error) {
      toast.error("Failed to delete content");
      console.error(error);
    } finally {
      const modal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
      modal.hide();
      setDeleteItemId(null);
    }
  };

  //Delete All
  const handleDeleteAll = async () => {

    if (selectedIds.length === 0) {
      toast.warn("No items selected for deletion");
      return;
    }
    const modal = new window.bootstrap.Modal(document.getElementById('deleteAllModal'));
    modal.show();
  }
  const confirmDeleteAll = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await deleteContent({ variables: { accessToken: token, contentIds: selectedIds } });
      const message = response.data.deleteContent[0].message;
      if (message.startsWith("error.")) {
        toast.warn("Internal server Error");
      } else {
        toast.success(message);
        window.location.reload();
        fetchContentHubList();
        setSelectedIds([]);
      }
    } catch (error) {
      toast.error("Failed to delete selected content");
      console.error(error);
    }
    finally {
      const modal = new window.bootstrap.Modal(document.getElementById('deleteAllModal'));
      modal.hide();
      setDeleteItemId(null);
    }
  };
  //Filter the data
  const handleApplyFilters = () => {
    const filteredResults = contentHubList.filter(content => {
      const matchesResolution = selectedResolutions.length === 0 || selectedResolutions.includes(content.resolution);
      const matchesMimeType = !selectedMimeType || content.mimeType === selectedMimeType;
      fetchContentHubList();

      return matchesResolution && matchesMimeType;

    });

    setFilteredResults(filteredResults);
  };
  //File Upload
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      const newPreviewUrls = newFiles.map(file => URL.createObjectURL(file));
      setPreviewUrls((prevUrls) => [...prevUrls, ...newPreviewUrls]);
    }
  };


  const handleUpload = async () => {
    const token = localStorage.getItem("authToken");
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");

    if (!token || !companyId || !userId) {
      toast.error("Missing authentication data.");
      return;
    }

    if (files.length === 0) {
      toast.warn("No files selected for upload.");
      return;
    }

    setUploadProgress(new Array(files.length).fill(0));

    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("operations", JSON.stringify({
        query: `mutation UploadContent($accessToken: String!, $file: Upload!, $mediaOwnerId: String!, $filetype: String!, $source: String!, $userId: String!) {
          uploadcontent(
            accessToken: $accessToken,
            file: $file,
            mediaOwnerId: $mediaOwnerId,
            filetype: $filetype,
            source: $source,
            userId: $userId
          ) {
            success
            message
          }
        }`,
        variables: {
          accessToken: token,
          file: null,
          mediaOwnerId: companyId,
          filetype: "LMX_CREATIVE",
          source: "LMX_COMMERCE",
          userId: userId,
        },
      }));

      formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
      formData.append("0", files[i]);

      const xhr = new XMLHttpRequest();

      // Track the upload progress
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round((event.loaded / event.total) * 100);
          setUploadProgress((prev) => {
            const updatedProgress = [...prev];
            updatedProgress[i] = percentComplete;
            return updatedProgress;
          });
        }
      });

      xhr.open('POST', GRAPHQL_URI);
      xhr.onload = () => {
        const responseData = JSON.parse(xhr.responseText);

        if (xhr.status !== 200) {
          toast.error(responseData.errors ? responseData.errors[0].message : "File upload failed");
          return;
        }

        if (responseData.data.uploadcontent.success) {
          toast.success(`File uploaded successfully`);
          setUploadProgress([]);
          setPreviewUrls([]);
          setFiles([]);
          fetchContentHubList();
          window.location.reload();
        } else {
          toast.error(responseData.data.uploadcontent.message);
        }
      };

      xhr.onerror = () => {
        toast.error("Failed to upload file.");
      };
      xhr.send(formData);
    }

  };

  const handleDelete = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setPreviewUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
    setUploadProgress((prevProgress) => prevProgress.filter((_, i) => i !== index));
  };



  const handleResolutionReset = () => {
    setSelectedResolutions([]);
    window.location.reload();
    fetchContentHubList();
  };
  const handleMimeReset = () => {
    setSelectedMimeType(null);
    window.location.reload();
    fetchContentHubList();
  };

  //Data Search
  const handleSearchChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSearch(event.currentTarget.value);
      fetchContentHubList();
    }
  };
  const handleRotate = () => {
    setSearch('');
    fetchContentHubList();
  }
  return (
    <AuthLayout>
      <>
        <div className="contenthub-full-content">
          <div className="container mt-2">
            <div className="navbar-back-btn"><img alt="" src={BackIcon} /><Link to={`${process.env.REACT_APP_BASE_PATH}/my-campaigns`} >Back</Link> </div>
          </div>
          <div className="container content-hub-grand-parent">
            <form className="content-hub-form">
              <div className="contenthub-head">
                <h5>Content Hub</h5>
              </div>
              <div className="content-hub-parent row">
                <div className="contenthub-search col-md-6">
                  <img src={SearchLogo} alt="" />
                  <input
                    type="text" className="border-bottom-only no-focus-border"
                    placeholder="Search for your campaigns"
                    value={search}
                    onKeyDown={handleSearchChange}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span>
                    <i onClick={handleRotate} className="fa-solid fa-rotate"></i>
                  </span>
                </div>
                <div className="contenthub-buttons col-md-6">
                  <button type="button" className="content-upload" data-bs-toggle="modal" data-bs-target="#FilterUpload">UPLOAD</button>
                  <button type="button" className="content-filter" data-bs-toggle="modal" data-bs-target="#FilterRes">Filters</button>
                  <img src={FilterLogo} alt="" />
                </div>
                <div className="contenthub-second-head col-md-6">
                  <h5>Creatives</h5>
                </div>
                <div className="contenthub-deleteall col-md-6">
                  <p onClick={handleDeleteAll}><i className="fa-solid fa-trash-can"></i> Delete All</p>
                </div>
              </div>
              <div className="contenthub-scrollbar">
                {loading ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: '40vh' }}>
                    <span className="loading-circle sp1">
                      <span className="loading-circle sp2">
                        <span className="loading-circle sp3"></span>
                      </span>
                    </span>
                  </div>
                ) : (
                  <table className="table table-hover content-hub-table">
                    <thead className="content-hub-thead">
                      <tr>
                        <th><input
                          type="checkbox"
                          checked={selectedIds.length === filteredResults.length && filteredResults.length > 0}
                          onChange={handleSelectAllChange}
                        /></th>
                        <th scope="col">Creative name</th>
                        <th scope="col">Duration <img src={DoubleChevron} alt="" /></th>
                        <th scope="col">Resolution <img src={DoubleChevron} alt="" /></th>
                        <th className="delete-cell"></th>
                      </tr>
                    </thead>
                    <tbody className="content-hub-tbody">
                      {filteredResults.length > 0 ? (
                        filteredResults.map((content) => (
                          <tr key={content.id} className="delete-cell">
                            <th scope="row">
                              <input
                                type="checkbox"
                                checked={selectedIds.includes(content.id)}
                                onChange={() => handleCheckboxChange(content.id)}
                              />
                            </th>
                            <td className="contenthub-img">
                              <img src={content.thumbnail} alt="" className="content-logo" />
                              <span className="contenthub-creatives-name">{content.fileName}</span>
                            </td>
                            <td>{content.duration}</td>
                            <td>{content.resolution}</td>
                            <td className="delete-cell">
                              <span className="delete-text" onClick={() => handleDeleteItem(content.id)}>
                                <i className="fa-solid fa-trash-can"></i> Delete
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7} className="text-center contenthub-creatives-nodata">No data found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
              <div className="col-md-12">
                <nav aria-label="Page navigation example">
                  <ul className="pagination pagenation-align">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <Link
                        className="page-chevron-left page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        to="#"
                      >
                        <i className="fa-solid fa-angle-left"></i>
                      </Link>
                    </li>
                    {totalPages > 0 && Array.from({ length: totalPages }).map((_, index) => {
                      const pageNumber = index + 1;
                      return (
                        <li
                          key={index}
                          className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
                        >
                          <Link
                            className="page-link"
                            onClick={() => handlePageChange(pageNumber)}
                            to="#"
                          >
                            {pageNumber}
                          </Link>
                        </li>
                      );
                    })}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                      <Link
                        className="page-chevron-right page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        to="#"
                      >
                        <i className="fa-solid fa-angle-right"></i>
                      </Link>
                    </li>
                  </ul>


                </nav>

              </div>


            </form>
          </div>

          {/* Filter-Resolutions-Modal */}
          <div className="modal fade" id="FilterRes" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content content-hub-filter-popup">
                <div className="modal-header contenthub-filter-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Filters</h1>
                  <button type="button" className="contenthub-fliter-close" data-bs-dismiss="modal"><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className="modal-body contenthub-body row">
                  <div className="contenthub-filter-left col-md-6">
                    <h5><button data-bs-target="#FilterRes">Resolution</button></h5>
                    <h5><button data-bs-toggle="modal" data-bs-target="#FilterMime">Mime type</button></h5>
                  </div>
                  <div className="contenthub-filter-right col-md-6">
                    {Array.from(new Set(filteredResults.map(content => content.resolution)))
                      .map((resolution, index) => (
                        <div key={index} className="contenthub-filter-option">
                          <div className="col-md-12">
                            <input
                              type="checkbox"
                              id={`resolution-${index}`}
                              checked={selectedResolutions.includes(resolution)}
                              onChange={() => {
                                setSelectedResolutions(prev =>
                                  prev.includes(resolution) ? prev.filter(res => res !== resolution) : [...prev, resolution]
                                );
                              }}
                            />
                            <label className="c-box" htmlFor={`resolution-${index}`}>{resolution}</label>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="modal-footer contenthub-popup-footer">
                  <button type="button" className="btn contenthub-filter-resetbtn" onClick={handleResolutionReset}>Reset</button>
                  <button type="button" className="btn contenthub-filter-applybtn" onClick={handleApplyFilters} data-bs-dismiss="modal">Apply filters</button>
                </div>
              </div>
            </div>
          </div>

          {/* Filter-Mimetype-Modal */}
          <div className="modal fade" id="FilterMime" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content content-hub-filter-popup">
                <div className="modal-header contenthub-filter-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Filters</h1>
                  <button type="button" className="contenthub-fliter-close" data-bs-dismiss="modal"><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className="modal-body contenthub-body row">
                  <div className="contenthub-filter-left col-md-6">
                    <h5><button data-bs-toggle="modal" data-bs-target="#FilterRes">Resolution</button></h5>
                    <h5><button data-bs-target="#FilterMime">Mime type</button></h5>
                  </div>
                  <div className="contenthub-filter-right col-md-6">
                    {Array.from(new Set(filteredResults.map(content => content.mimeType)))
                      .map((mimeType, index) => (
                        <div key={index} className="contenthub-filter-option">
                          <div className="col-md-12">
                            <input
                              type="radio"
                              className="mime-type"
                              id={`mime-type-${index}`}
                              name="imageType"
                              checked={selectedMimeType === mimeType}
                              onChange={() => setSelectedMimeType(mimeType)}
                            />
                            <label className="c-radio" htmlFor={`mime-type-${index}`}>{mimeType}</label>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="modal-footer contenthub-popup-footer">
                  <button type="button" className="btn contenthub-filter-resetbtn" onClick={handleMimeReset}>Reset</button>
                  <button type="button" className="btn contenthub-filter-applybtn" onClick={handleApplyFilters} data-bs-dismiss="modal">Apply filters</button>
                </div>
              </div>
            </div>
          </div>

          {/* Filter-Apply-Upload-Modal */}
          <div className="modal fade" id="FilterUpload" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content contenthub-upload-filter-popup">
                <div className="modal-header contenthub-upload-filter-header">
                  <h1 className="modal-title" id="staticBackdropLabel">Upload File</h1>
                  <button type="button" className="contenthub-fliter-close" data-bs-dismiss="modal">
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </div>
                <div className="modal-body contenthub-upload-body row">
                  <div className="contenthub-upload-file col-md-12">
                    <h1><i className="fa-solid fa-cloud-arrow-up"></i></h1>
                    <h2>Drag & drop to upload</h2>
                    <h3>
                      <input type="file" multiple onChange={handleFileChange} style={{ display: 'none' }} id="file-upload" />
                      <label htmlFor="file-upload" style={{ cursor: 'pointer', color: '#014DC0' }}>or browse</label>
                    </h3>
                  </div>
                </div>
                <div className="container contenthub-image-upload">
                  {previewUrls.map((url, index) => (
                    <div key={index} className="image-preview" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                      <img src={url} alt={`Preview ${index}`} style={{ width: '89px', height: '52px', borderRadius: '5px', marginRight: '10px' }} />
                      <p style={{ width: '40%' }}>{files[index]?.name}</p>
                      {uploadProgress[index] > 0 && (
                        <div className="progress" style={{ height: '5px', marginTop: '10px', width: '292px', borderRadius: '12px', backgroundColor: '#D9D9D9' }}>
                          <div className="progress-bar" role="progressbar" style={{ width: `${uploadProgress[index]}%`, backgroundColor: '#014DC0' }}></div>
                        </div>
                      )}
                      <button onClick={() => handleDelete(index)} style={{ marginLeft: '10px', cursor: 'pointer', backgroundColor: 'transparent', border: 'none', color: '#FF0000' }}>
                        <i className="fa-solid fa-trash-can"></i> Delete
                      </button>
                    </div>
                  ))}
                </div>
                <div className="modal-footer contenthub-upload-popup-footer">
                  <i className="fa-solid fa-exclamation"></i>
                  <span>You may upload multiple files including JPEG, mp3, mp4, or webm</span>
                  <button type="button" className="btn contenthub-filter-uploadbtn" onClick={handleUpload}>Upload</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Delete Modal */}
        <div className="modal fade" id="deleteModal" aria-labelledby="deleteModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header cart-delete-header">
                <h5 className="modal-title cart-delete-title" id="deleteModalLabel">Delete Item ?</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body cart-delete-body">
                <h3 className="delete-bin"><i className="fa-solid fa-trash-arrow-up"></i></h3>
                <h6>Do you want to delete this item ?</h6>
              </div>
              <div className="modal-footer cart-delete-footer">
                <button type="button" className="cart-cancel" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="cart-delete" onClick={confirmDelete} data-bs-dismiss="modal">Delete</button>
              </div>
            </div>
          </div>
        </div>
        {/*DeleteAll Modal */}
        <div className="modal fade" id="deleteAllModal" aria-labelledby="deleteModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header cart-delete-header">
                <h5 className="modal-title cart-delete-title" id="deleteModalLabel">Delete Item ?</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body cart-delete-body">
                <h3 className="delete-bin"><i className="fa-solid fa-trash-arrow-up"></i></h3>
                <h6>Do you want to delete this all item ?</h6>
              </div>
              <div className="modal-footer cart-delete-footer">
                <button type="button" className="cart-cancel" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="cart-delete" onClick={confirmDeleteAll} data-bs-dismiss="modal">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </>
    </AuthLayout>
  );
}

export default ContentHub;
