import React, { Component, useEffect, useRef, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import Image1 from "../../../assets/images/search.svg";
import Image2 from "../../../assets/images/group-212841306.svg";
import Image3 from "../../../assets/images/mappin.svg";
import Image6 from "../../../assets/images/cart-icon.svg";
import Image5 from "../../../assets/images/sliders@2x.png";
import Image9 from "../../../assets/images/group-21.svg";
import NoImage from "../../../assets/images/no-image.jpg";

import './index.css';
import { gql, useMutation, useQuery } from "@apollo/client";
import { GETPROPERTY_LIST } from "../../../Graphql/PropertyListQueries";
import client from "../../../Graphql/apolloClient";

import { ADD_TO_CART_SUBMIT, DELETE_CART_SUBMIT, GET_MY_CART_ITEMS } from "../../../Graphql/cartQueries";
import { toast } from "react-toastify";
import { generateMockData } from "../../../Graphql/MockData";
import { CART_INITIAL_QUERY, GET_COUNTRY_LIST, GET_STATE_LIST, INVOICE, PROPERTY_LIST_VENUE_TYPE_LIST } from "../../../Graphql/Queries";
import { E } from "@faker-js/faker/dist/airline-BLb3y-7w";
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
type SubCategory = {
    id: string;
    name: string;
    value: string;
    child?: GrandSubCategory[];
};
type GrandSubCategory = {
    id: string;
    name: string;
    value: string;
};
type Category = {
    id: string;
    name: string;
    value: string;
    child: SubCategory[];
};

interface SssItem {
    id: string;
    cartItemId: string;
    [key: string]: any;
}
interface Inventory {
    dealId: string;
    inventoryVenueType: string;
    description: string;
    name: string;
    inventoryType: string;
    inventoryResolution: string;
    inventoryId: string;
    inventoryName: string;
    inventoryThumbnailUrl: string;
    inventoryAddress: string;
    inventoryReferenceId: string;
    inventoryReports: {
        totalPotentialViews: number;
    };
    specification: {
        screens: string;
    }
    inventoryLatitude?: number;
    inventoryLongitude?: number;
    nowPayPrice: any;
    reasonForRejection: string;

}interface Inventory {
    dealId: string;
    inventoryVenueType: string;
    description: string;
    name: string;
    inventoryType: string;
    inventoryResolution: string;
    inventoryId: string;
    inventoryName: string;
    inventoryThumbnailUrl: string;
    inventoryAddress: string;
    inventoryReferenceId: string;
    inventoryReports: {
        totalPotentialViews: number;
    };
    inventoryLatitude?: number;
    inventoryLongitude?: number;
    nowPayPrice: any;
    reasonForRejection: string;

}
type Field = {
    key: string;
    type: string;
    label: string;
    default: boolean | string;
    disabled?: boolean;
    options?: { label: string; value: string }[];
};

type Configuration = {
    key: string;
    label: string;
    fields?: Field[];
    options?: { label: string; value: string }[];
    type?: string;
    default?: boolean | string;
    disabled?: boolean;
};

const locations = [
    '96 sheets',
    'Bulletin',
    'City Lights',
    'Bus Shelter',
    'Gallery'
];
const BillBoardListing = (props: any) => {
    console.log("Parent to Child Data: ", props);
    const [showMap, setShowMap] = useState(false);
    const [showLocationFilters, setShowLocationFilters] = useState(false);
    const [showLocationVenue, setShowLocationVenue] = useState(false);
    const [showLocationSite, setShowLocationSite] = useState(false);
    const [showLocationBill, setShowLocationBill] = useState(false);
    const [showLocationPrice, setShowLocationPrice] = useState(false);
    const [showLocationFinancial, setShowLocationFinancial] = useState(false);
    const [showLocationAirport, setShowLocationAirport] = useState(false);
    const [activeCategory, setActiveCategory] = useState<Category | null>(null);
    const [activeSubCategory, setActiveSubCategory] = useState<SubCategory | null>(null);
    const [activeGradSubCategory, setActiveGradSubCategory] = useState<GrandSubCategory | null>(null);
    const [activeCategoryLocation, setActiveCategoryLocation] = useState<string | null>();
    const [activeCategorySite, setActiveCategorySite] = useState<string | null>();
    const [activeCategoryBill, setActiveCategoryBill] = useState<string | null>();
    const [activeCategoryPrice, setActiveCategoryPrice] = useState<string | null>();
    const [badges, setBadges] = useState<string[]>([]);
    const location = useLocation();
    const cardData = location.state || {};
    const formData = cardData?.formData || {};
    const cartformData = cardData?.cartformData || {};
    const currentDate = new Date();
    const [startDate, setStartDate] = useState<Date>(() => {
        return formData?.startDate ? new Date(formData?.startDate) :
            cartformData?.startDate ? new Date(cartformData?.startDate) : new Date();
    });
    const [endDate, setEndDate] = useState<Date>(() => {
        return formData?.endDate ? new Date(formData?.endDate) :
            cartformData?.endDate ? new Date(cartformData?.endDate) : new Date();
    });
    const [campaignName, setCampaignName] = useState<string>(
        formData?.campaignName || cartformData?.campaignName || props.filterData?.campaignName || 'Default Campaign'
    );
    const [state, setState] = useState<any>(formData?.state || '');
    const [district, setDistrict] = useState<any>(formData?.district || '');

    const [billBoardDataLoading, setBillBoardDataLoading] = useState(false);
    const [billBoardData, setBillBoardData] = useState<any>([]);
    const [soldCampaignViewDetails, setSoldCampaignViewDetails] = useState<any>({});
    const [cartData, setCartData] = useState<any>([{}]);
    const [cartCount, setCartCount] = useState(0);
    const [addtoCartQuery] = useMutation(ADD_TO_CART_SUBMIT);
    const [deleteCartQuery] = useMutation(DELETE_CART_SUBMIT);
    const [billboardType, setBillboardType] = useState("digital");
    const dataListLength = 12;
    const [totalDataLength, setTotalDataLength] = useState(0);
    const [pageStartData, setPageStartData] = useState(0);
    const [pageEndData, setPageEndData] = useState(0);
    const [locationData, setLocationData] = useState<string[]>([]);
    const [venueTypeData, setVenueTypeData] = useState<string[]>([]);
    const [siteTypeData, setSitetypeData] = useState<string[]>([]);
    const mapContainer = useRef<HTMLDivElement>(null);
    const [mapLat, setMapLat] = useState<number>(40.7127281);
    const [mapLng, setMapLng] = useState<number>(-74.0060152);
    const [map, setMap] = useState<mapboxgl.Map | null>(null);
    const [marker, setMarker] = useState<mapboxgl.Marker | null>(null);
    const [selectedInventory, setSelectedInventory] = useState<Inventory | null>(null);
    const [currentPopup, setCurrentPopup] = useState<mapboxgl.Popup | null>(null);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
    useEffect(() => {
        mapboxgl.accessToken =
            "pk.eyJ1IjoidGVqYS1zaHJlZSIsImEiOiJjbHo4OWF6ZHgwMGwxMmxzYWxzaTk1YnNiIn0.hpbbzYRq7WOaOPwInekf9w";
        if (mapContainer.current && showMap && billBoardData.length > 0) {
            console.log("Initializing map at New York coordinates:", mapLat, mapLng);
            const initialMap = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/streets-v11",
                center: [mapLng, mapLat],
                zoom: 13.5,
            });

            initialMap.addControl(new mapboxgl.NavigationControl(), "top-right");
            setMap(initialMap);

            if (billBoardData.length > 0) {
                const firstBillboard = billBoardData[0];
                const firstLat = firstBillboard.latitude;
                const firstLng = firstBillboard.longitude;
                const newMarker = new mapboxgl.Marker({ color: '#014DC0' })
                    .setLngLat([firstLng, firstLat])
                    .addTo(initialMap);

                setMarker(newMarker);
                setSelectedInventory(firstBillboard);
                showPopup(firstBillboard);
            }

            return () => {
                initialMap.remove();
            };
        }
    }, [mapContainer, showMap, billBoardData]);


    useEffect(() => {
        if (map && billBoardData.length > 0) {
            map.flyTo({
                center: [mapLng, mapLat],
                essential: true,
                speed: 2.5,
                curve: 1,
                zoom: 13.5,
            });
            if (marker) {
                marker.setLngLat([mapLng, mapLat]);
            } else {
                const newMarker = new mapboxgl.Marker({ color: '#014DC0' })
                    .setLngLat([mapLng, mapLat])
                    .addTo(map);
                setMarker(newMarker);
            }
            if (selectedInventory) {
                showPopup(selectedInventory);
            }
        }
    }, [mapLat, mapLng, map, marker, selectedInventory]);

    const handleSelectBillboard = (inventory: any) => {
        setSelectedInventory(inventory);
        const latitude = inventory.latitude || 21.7679;
        const longitude = inventory.longitude || 78.8718;
        handleBillboardClick(latitude, longitude);
        showPopup(inventory);
    };

    const handleBillboardClick = (latitude: string | number, longitude: string | number) => {
        const newLat = parseFloat(latitude.toString());
        const newLng = parseFloat(longitude.toString());
        if (isNaN(newLat) || isNaN(newLng)) {
            console.error("Invalid latitude or longitude", latitude, longitude);
            return;
        }
        setMapLat(newLat);
        setMapLng(newLng);
    };
    const showPopup = (inventory: any) => {
        if (marker && map && billBoardData.length > 0) {
            if (currentPopup) {
                currentPopup.remove();
            }
            if (!marker.getLngLat() || !map) {
                console.error("Marker or map is not properly initialized.");
                return;
            }
            const popupContent = createPopupContent(inventory);
            try {
                const popup = new mapboxgl.Popup({ offset: 25 })
                    .setHTML(popupContent)
                    .setLngLat(marker.getLngLat());
                marker.setPopup(popup);
                popup.addTo(map);
                setCurrentPopup(popup);
            } catch (error) {
                console.error("Error showing popup:", error);
            }
        } else {
            console.error("Marker or map instance is not available.");
        }
    };

    const createPopupContent = (inventory: any) => {
        const address = inventory?.countryName || "N/A";
        const impressions = inventory?.monthlySummary?.totalVisitors || "N/A";
        const price = inventory?.monthlySummary?.uniqueVisitors || "N/A";
        const prices = inventory?.sellingRate?.monthly?.month1 || "N/A";
        return `
          <div class="map-popup-container">
            <div class="map-popup-image-container">
              <img src="${inventory?.thumbnailPath}" alt="Billboard Image" class="map-popup-image">
            </div>
            <div class="map-popup-body">
              <div class="row">
                <div class="col-md-6">
                  <h3 class="map-popup-title">${inventory?.displayName || "N/A"}</h3>
                  <p class="map-popup-stat-label">Impressions: ${impressions}</p>
                  <p class="map-popup-stat-label">AUD ${prices}</p>
                </div>
                <div class="col-md-6">
                  <p class="map-popup-location">${address}</p>
                  <p class="map-popup-price">Unique Reach : ${price}</p>
                </div>
              </div>
            </div>
          </div>
        `;
    };

    useEffect(() => {
        if (map && selectedInventory) {
            showPopup(selectedInventory);
        }
    }, [mapLat, mapLng, selectedInventory]);



    const handleShowMap = () => {

        setShowMap(!showMap);
    };
    const handleLocationFilterClick = () => {
        setShowLocationFilters(!showLocationFilters);
        setActiveCategoryLocation(activeCategoryLocation === 'location' ? null : 'location');
        setActiveCategory(null);
        setVenueTypeData([]);
        setActiveCategorySite(null);
        setActiveCategoryBill(null);
        setActiveCategoryPrice(null);
        setShowLocationVenue(false);
        setShowLocationFinancial(false);
        setShowLocationAirport(false);
    };

    const handleLocationVenueClick = () => {
        setShowLocationVenue(!showLocationVenue);
        setActiveCategory(activeCategory?.id === 'venue' ? null : { id: 'venue', name: 'Venue', value: 'Venue type', child: [] });
        setActiveCategoryLocation(null);
        setActiveCategorySite(null);
        setActiveCategoryBill(null);
        setActiveCategoryPrice(null);
        setActiveSubCategory(null);
        setActiveGradSubCategory(null);
        setShowLocationFilters(false);
        setShowLocationSite(false);
        setShowLocationBill(false);
        setShowLocationPrice(false);
    };

    const handleLocationSiteClick = () => {
        setShowLocationSite(!showLocationSite);
        setActiveCategorySite(activeCategorySite === 'site' ? null : 'site');
        setActiveCategoryLocation(null);
        setVenueTypeData(['']);
        setActiveCategory(null);
        setActiveSubCategory(null);
        setActiveGradSubCategory(null);
        setActiveCategoryBill(null);
        setActiveCategoryPrice(null);
        setShowLocationFilters(false);
        setShowLocationVenue(false);
        setShowLocationBill(false);
        setShowLocationPrice(false);
    };

    const handleLocationBillClick = () => {
        setShowLocationBill(!showLocationBill);
        setActiveCategoryBill(activeCategoryBill === 'bill' ? null : 'bill');
        setActiveCategoryLocation(null);
        setVenueTypeData(['']);
        setActiveCategory(null);
        setActiveSubCategory(null);
        setActiveGradSubCategory(null);
        setActiveCategorySite(null);
        setActiveCategoryPrice(null);
        setShowLocationFilters(false);
        setShowLocationVenue(false);
        setShowLocationSite(false);
        setShowLocationPrice(false);
    };

    const handleLocationPriceClick = () => {
        setShowLocationPrice(!showLocationPrice);
        setActiveCategoryPrice(activeCategoryPrice === 'price' ? null : 'price');
        setActiveCategoryLocation(null);
        setVenueTypeData(['']);
        setActiveCategory(null);
        setActiveSubCategory(null);
        setActiveGradSubCategory(null);
        setActiveCategorySite(null);
        setActiveCategoryBill(null);
        setShowLocationFilters(false);
        setShowLocationVenue(false);
        setShowLocationSite(false);
        setShowLocationBill(false);
    };

    const [minPrice, setMinPrice] = useState(1);
    const [maxPrice, setMaxPrice] = useState(3500);
    const handleMinPriceChange = (e: any) => {
        const value = Math.min(e.target.value, maxPrice);
        setMinPrice(Number(value));
    };
    const handleMaxPriceChange = (e: any) => {
        const value = Math.max(e.target.value, minPrice);
        setMaxPrice(Math.min(Number(value), 3500));
    };
    const handlePriceRangeChange = () => {
        const badgeValue = `Price: ${minPrice} - ${maxPrice}`;
        if (!badges.includes(badgeValue)) {
            setBadges([...badges, badgeValue]);
        }
    };
    const handleLocationFinancialClick = (category: Category) => {
        if (category !== activeCategory) {
            setShowLocationFinancial(true);
            setActiveCategory(category);
            setActiveSubCategory(null);
            setActiveGradSubCategory(null);
            setVenueTypeData([]);
        }
    };

    const handleSubCategoryClick = (subCategory: SubCategory) => {
        if (subCategory !== activeSubCategory) {
            setActiveSubCategory(subCategory);
            setActiveGradSubCategory(null);
            setVenueTypeData((prevVenueTypeData) => {
                if (!prevVenueTypeData.includes(subCategory.value)) {
                    return [...prevVenueTypeData, subCategory.value];
                }
                return prevVenueTypeData;
            });
            handleAddBadge(subCategory.value);
        }
    };

    const handleGradSubCategoryClick = (gradSubCategory: GrandSubCategory) => {
        if (gradSubCategory !== activeGradSubCategory) {
            setActiveGradSubCategory(gradSubCategory);
            setVenueTypeData((prevVenueTypeData) => {
                if (!prevVenueTypeData.includes(gradSubCategory.value)) {
                    return [...prevVenueTypeData, gradSubCategory.value];
                }
                return prevVenueTypeData;
            });
            handleAddBadge(gradSubCategory.value);
        }
    };

    const handleAddBadge = (badgeValue: string) => {
        if (!badges.includes(badgeValue)) {
            setBadges([...badges, badgeValue]);

        }
    };
    const handleCheckboxLocation = (badgeValue: string, checked: boolean) => {
        if (checked) {
            if (!badges.includes(badgeValue)) {
                setBadges((prevBadges) => [...prevBadges, badgeValue]);
                setLocationData((prevLocationData) => [...prevLocationData, badgeValue]);
            }
            if (badgeValue === 'Indoor') {
                setIndoorChecked(true);
            } else if (badgeValue === 'Outdoor') {
                setOutdoorChecked(true);
            }
        } else {
            setBadges((prevBadges) => prevBadges.filter((badge) => badge !== badgeValue));
            setLocationData((prevLocationData) =>
                prevLocationData.filter((badge) => badge !== badgeValue)
            );
            if (badgeValue === 'Indoor') {
                setIndoorChecked(false);
            } else if (badgeValue === 'Outdoor') {
                setOutdoorChecked(false);
            }
        }
    };


    const handleCheckboxChange = (gradSubCategory: GrandSubCategory) => {
        if (badges.includes(gradSubCategory.value)) {
            setBadges(badges.filter(badge => badge !== gradSubCategory.value));
        } else {
            setBadges([...badges, gradSubCategory.value]);
        }
    };

    const handleSiteTypeChange = (e: any) => {
        const siteType = e.target.value;

        setBadges((prevBadges) => {
            const newBadges = prevBadges.filter(badge => !badge.includes(siteType));
            setSitetypeData([...newBadges, `${siteType}`]);
            return [...newBadges, `${siteType}`];
        });
    };

    const handleScreenSizeChange = (e: any) => {
        const screenSize = e.target.value;

        setBadges((prevBadges) => {
            const newBadges = prevBadges.filter(badge => !badge.includes(screenSize));
            setSitetypeData([...newBadges, `${screenSize}`]);
            return [...newBadges, `${screenSize}`];
        });
    };
    const [sheetsChecked, setSheetsChecked] = useState(false);
    const [bulletin, setBulletin] = useState(false);
    const [cityLights, setcityLights] = useState(false);
    const [busShelter, setbusShelter] = useState(false);
    const [gallery, setGallery] = useState(false);
    const handleCheckboxLocationBill = (badgeValue: string, checked: boolean) => {
        if (checked) {
            if (!badges.includes(badgeValue)) {
                setBadges((prevBadges) => [...prevBadges, badgeValue]);
            }
            if (!selectedCheckboxes.includes(badgeValue)) {
                setSitetypeData((prevSelected) => [...prevSelected, badgeValue]);
            }
            if (badgeValue === '96 sheets') {
                setSheetsChecked(true);
            } else if (badgeValue === 'Bulletin') {
                setBulletin(true);
            } else if (badgeValue === 'City Lights') {
                setcityLights(true);
            } else if (badgeValue === 'Bus Shelter') {
                setbusShelter(true);
            } else if (badgeValue === 'Gallery') {
                setGallery(true);
            }
        } else {
            setBadges((prevBadges) => prevBadges.filter((badge) => badge !== badgeValue));
            setSitetypeData((prevSelected) =>
                prevSelected.filter((value) => value !== badgeValue)
            );
            if (badgeValue === '96 sheets') {
                setSheetsChecked(false);
            } else if (badgeValue === 'Bulletin') {
                setBulletin(false);
            } else if (badgeValue === 'City Lights') {
                setcityLights(false);
            } else if (badgeValue === 'Bus Shelter') {
                setbusShelter(false);
            } else if (badgeValue === 'Gallery') {
                setGallery(false);
            }
        }
    };

    const handleRemoveBadge = (badgeToRemove: string) => {
        setBadges(badges.filter(badge => badge !== badgeToRemove));
    };
    // Country List Dropdwon
    const [selectedCountryId, setSelectedCountryId] = useState<string | undefined>();
    const [countryOptions, setCountryOptions] = useState<{ id: string; name: string; dialingCode: string }[]>([]);
    const [stateOptions, setStateOptions] = useState<{
        stateId: string | null;
        id: string;
        name: string;
    }[]>([]);
    const [country, setCountry] = useState<string>("");
    // const [state, setState] = useState<string>("");
    const { data: countryData } = useQuery(GET_COUNTRY_LIST);

    useEffect(() => {
        if (countryData && countryData.countryList) {
            setCountryOptions(countryData.countryList);
        }
    }, [countryData]);

    // State List Dropdwon

    const { data: stateData } = useQuery(GET_STATE_LIST, {
        variables: { countryId: selectedCountryId },
        skip: !selectedCountryId,
    });

    useEffect(() => {
        if (stateData && stateData.stateList) {
            setStateOptions(stateData.stateList);
        }
    }, [stateData]);

    const handleCountryChange = (e: { target: { value: string } }) => {
        const selectedId = e.target.value;
        const selectedCountry = countryOptions.find(country => country.id === selectedId);

        if (selectedCountry) {
            setSelectedCountryId(selectedCountry.id);
            setCountry(selectedId);
        } else {
            setCountry("");
        }
    };
    const [indoorChecked, setIndoorChecked] = useState(false);
    const [outdoorChecked, setOutdoorChecked] = useState(false);



    const token = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyId');
    const countryId = localStorage.getItem('countryId');
    const [currentPage, setCurrentPage] = useState(0);
    const [search, setSearch] = useState<string>("");

    // useEffect(() => {
    //     console.log("Billboard Filter Data: ", props)
    //     fetchBillBoardList();
    // }, [props.filterData])
    // useEffect(() => {
    //     console.log("Billboard Filter Data: ", props)
    //     fetchBillBoardList();
    // }, [props.filterData?.startDate, props.filterData?.endDate])

    // const { loading: loadingCartList, error: errorCartList, data: dataCartList, refetch: refetchCartList } = useQuery(GET_MY_CART_ITEMS, {
    //     variables: {
    //         accessToken: token,
    //         userId: userId
    //     }
    // })
    // useEffect(() => {
    //     if (dataCartList) {
    //         console.log("Refetch cart list dataCartList: ", dataCartList);
    //         const cartDataList = dataCartList.cartpageItem;
    //         setCartData(cartDataList);
    //         setcartId(cartDataList);
    //         let resultObj = billBoardData;
    //         let cartObj = cartDataList;
    //         if (resultObj.length > 0 && cartObj.length > 0) {
    //             cartObj.map((cartItem: any) => {
    //                 const newBillBoardData = resultObj.map((obj: { id: any; }) => {
    //                     if (obj.id === cartItem.cartItemId) {
    //                         return { ...obj, isInCart: true };
    //                     } else {
    //                         return { ...obj, isInCart: false };
    //                     }
    //                     // return obj;
    //                 });
    //                 resultObj = newBillBoardData;
    //                 setBillBoardData(newBillBoardData);
    //             });

    //         }
    //     }
    // }, [dataCartList])

    useEffect(() => {
        if (process.env.REACT_APP_MOCK_DATA === 'true') {
            generateMockData(GETPROPERTY_LIST).then((mockResponse: any) => {
                console.log("mockData", mockResponse);
                const data = mockResponse.data
                setBillBoardDataLoading(false);
                setBillBoardData(data.landingpageExploreProperties)

            }).catch((err: any) => {
                console.error("Error generating mock data:", err);
            });
        } else {
            fetchBillBoardList();
            fetchCartItems(userId);
        }
    }, [search])

    const [cartItemCount, setCartItemCount] = useState(0);
    const { data } = useQuery(PROPERTY_LIST_VENUE_TYPE_LIST);
    const venueTypes = data?.venueTypes || [];
    const { data: myCart, loading, error } = useQuery(CART_INITIAL_QUERY, {
        variables: {
            accessToken: token,
            userId: userId,
        },
    });

    useEffect(() => {
        if (myCart && myCart.cartpageItem) {
            localStorage.setItem("myCart", JSON.stringify(myCart.cartpageItem));
            const cartItems = myCart.cartpageItem || [];
            setCartItemCount(cartItems.length);
        }
    }, [myCart]);


    const fetchCartItems = async (user: any) => {
        try {
            const { data, loading, error } = await client.query({
                query: GET_MY_CART_ITEMS,
                variables: {
                    accessToken: token,
                    userId: user
                }
            })
            if (data) {
                console.log("cart list data: ", data);
                const cartDataList = data.cartpageItem;
                setCartData(cartDataList);
                let cartItemsCount = cartDataList.length;
                setCartCount(cartItemsCount);
                setcartId(data.cartpageItem)
                fetchBillBoardList("", data.cartpageItem);
            } else {
                fetchBillBoardList();
            }
        } catch (error) {
            console.log("Error fetching District list:", error);
            fetchBillBoardList();
        }
    };
    useEffect(() => {
        fetchCartItems(userId);
    }, []);
    useEffect(() => {
        fetchBillBoardList();
    }, [currentPage]);
    const fetchBillBoardList = async (searchValue: any = "", cartItems: any = "") => {
        try {
            console.log("cur Bill Board Data: ", billBoardData);
            setBillBoardDataLoading(true);
            // setBillBoardData([]);
            let categories = locationData.map(category => category.toUpperCase());
            const filterData = {
                type: billboardType.toUpperCase(),
                availableBooking: true,
                categories: categories,
                format: siteTypeData,
                venueType: venueTypeData,
            };
            const { data, loading, error } = await client.query({
                query: GETPROPERTY_LIST,
                variables: {
                    accessToken: token,
                    sort: "last_modified_date,desc",
                    billboard: billboardType,
                    userId: userId,
                    companyId: companyId,
                    page: currentPage,
                    size: dataListLength,
                    startDate: format(startDate, 'dd MMMM yyyy'),
                    endDate: format(endDate, 'dd MMMM yyyy'),
                    dspName: "LMX-ECOMMERCE",
                    countryId: countryId,
                    searchTerm: search,
                    filterData: filterData
                }
            })
            if (data) {
                console.log("Billboard data testing: ", data);
                setBillBoardDataLoading(false);
                let resultObj = data.landingpageExploreProperties;
                let cartObj = cartItems;
                if (resultObj.length > 0 && cartObj.length > 0) {
                    cartObj.map((cartItem: any) => {
                        const newBillBoardData = resultObj.map((obj: { id: any; }) => {
                            if (obj.id === cartItem.cartItemId) {
                                return { ...obj, isInCart: true };
                            } else {
                                return { ...obj, isInCart: false };
                            }
                            // return obj;
                        });
                        resultObj = newBillBoardData;
                        setBillBoardData(newBillBoardData);
                    });

                } else {
                    setBillBoardData(data.landingpageExploreProperties);
                }
                setTotalDataLength(data.landingpageExploreProperties[0].totalSize);
                if (data.landingpageExploreProperties[0].totalSize > 0) {
                    if (currentPage === 0) {
                        setPageStartData(1);
                        if (data.landingpageExploreProperties[0].totalSize > dataListLength) {
                            setPageEndData(dataListLength);
                        } else {
                            setPageEndData(data.landingpageExploreProperties[0].totalSize);
                        }
                    } else {
                        const curStartData = dataListLength * currentPage;
                        setPageStartData(curStartData + 1);
                        const curEndData = curStartData + dataListLength;
                        if (data.landingpageExploreProperties[0].totalSize > curEndData) {
                            setPageEndData(curEndData);
                        } else {
                            setPageEndData(data.landingpageExploreProperties[0].totalSize);
                        }
                    }
                }
            }
        } catch (error) {
            setBillBoardDataLoading(false);
            console.log("Error fetching property details:", error);
        }
    };
    const resetAllStates = () => {
        setBadges([]);
        setLocationData([]);
        setVenueTypeData([]);
        setSitetypeData([]);
        setMinPrice(1);
        setMaxPrice(3500);
        setIndoorChecked(false);
        setOutdoorChecked(false);
        setSheetsChecked(false);
        setBulletin(false);
        setcityLights(false);
        setbusShelter(false);
        setGallery(false);
        fetchBillBoardList();
        setActiveCategoryLocation(null);
        setActiveCategory(null);
        setVenueTypeData([]);
        setActiveCategorySite(null);
        setActiveCategoryBill(null);
        setActiveCategoryPrice(null);
        setShowLocationVenue(false);
        setShowLocationFinancial(false);
        setShowLocationAirport(false);

    };
    const addToCart = async (itemData: any) => {
        try {
            const { data, errors } = await addtoCartQuery({
                variables: {
                    accessToken: token,
                    userId: userId,
                    campaignName: campaignName,
                    startDate: format(startDate, 'dd MMMM yyyy'),
                    endDate: format(endDate, 'dd MMMM yyyy'),
                    cartItemName: itemData.displayName,
                    cartItemId: itemData.id,
                    cartItemReferenceId: itemData.referenceId,
                    cartItemType: itemData.type,
                    cartItemCountry: itemData.countryName,
                    cartItemVenueType: itemData.type,
                    cartItemResolution: itemData.resolutionHeight + "x" + itemData.resolutionWidth,
                    cartItemThumbnailUrl: itemData.thumbnailPath,
                    cartItemLatitude: itemData.latitude,
                    cartItemLongitude: itemData.longitude,
                    packageDetails: ""
                }
            });
            if (data) {
                console.log("Add to Cart: ", data);
                if (data.addtocart.itemData.success) {
                    const newBillBoardData = billBoardData.map((obj: { id: any; }) => {
                        if (obj.id === itemData.id) {
                            return { ...obj, isInCart: true };
                        }
                        return obj;
                    });
                    setBillBoardData(newBillBoardData);
                    setCartCount(cartCount + 1);
                    // refetchCartList();
                    toast.success(data.addtocart.itemData.message);

                } else {
                    toast.error(data.addtocart.itemData.message);
                }
            }
            if (errors) {
                toast.error(errors[0].message);
            }
        } catch (error) {
            console.log("Error add to cart:", error);
        }
    }
    const [cartId, setcartId] = useState<SssItem[]>([]);
    const deleteCartItem = async (itemData: any) => {
        console.log(itemData.id, "itemDataitemDataitemDataitemData");
        const cartDataId = cartId.map((list: any) => list.id);
        console.log(itemData, "0000000000000000000000000000000000000");
        const matchingItem = cartId.find((list: any) => list.cartItemId === itemData.id);
        console.log(matchingItem, "matchingItemmatchingItem");

        if (!matchingItem) {
            console.log("No matching item found.");
            toast.error("Item not found in cart.");
            return;
        }

        try {
            const { data, errors } = await deleteCartQuery({
                variables: {
                    accessToken: token,
                    deleteItemId: matchingItem.id,
                    itemType: "cart-data"
                }
            });
            if (data) {
                console.log("Add to Cart: ", data);
                if (data.deleteCartItem.success) {
                    const newBillBoardData = billBoardData.map((obj: { id: any; }) => {
                        if (obj.id === itemData.id) {
                            return { ...obj, isInCart: false };
                        }
                        return obj;
                    });
                    setBillBoardData(newBillBoardData);
                    setCartCount(cartCount - 1);
                    // refetchCartList();
                    toast.success(data.deleteCartItem.message);
                } else {
                    toast.error(data.deleteCartItem.message);
                }
            }
            if (errors) {
                toast.error(errors[0].message);
            }
        } catch (error) {
            console.log("Error add to cart:", error);
        }
    }
    const soldCampaignDetails = (details: any) => {
        setSoldCampaignViewDetails(details);
    }
    const billboardTypeChange = (eve: any) => {
        console.log(eve);
        setBillboardType(eve);
        setCurrentPage(0)
    }
    const paginationPlus = () => {
        const newPage = currentPage + 1
        setCurrentPage(newPage)
    }
    const paginationMinus = () => {
        const newPage = currentPage - 1
        setCurrentPage(newPage)
    }
    useEffect(() => {
        fetchBillBoardList();
    }, [billboardType, currentPage])
    const { data: datas } = useQuery(INVOICE, {
        variables: {
            accessToken: token,
            campId: cartformData.selectedCampaignId,
        },
    });


    useEffect(() => {
        console.log(datas);

    }, [datas])
    const handleSearchChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setSearch(event.currentTarget.value);
            fetchBillBoardList(event.currentTarget.value);
        }
    };

    const Navigate = useNavigate();
    const navigateToCart = () => {
        const cartformData = cardData?.cartformData || {};

        console.log(cartformData, "cartformDatacartformData");


        if (cartformData.itsEdits === 'edits') {
            toast.error('Please update the edit campaign');
            const editData = {
                campaignId: cartformData.selectedCampaignId,
                condition: cartformData.condition,
                edit: cartformData.condition === false ? false : true
            };
            Navigate(`${process.env.REACT_APP_BASE_PATH}/editCampaign`, { state: { editData } });
        }
        else {
            const formData = {
                campaignName,
                startDate: format(startDate, 'yyyy-MM-dd'),
                endDate: format(endDate, 'yyyy-MM-dd'),
                campaignId: '',
                edit: false,
                state: state,
                district: district
            };

            console.log('====================================');
            console.log(startDate);
            console.log(endDate);
            console.log('====================================');
            console.log('Navigating to cart with edit: false');
            Navigate(`${process.env.REACT_APP_BASE_PATH}/mycart`, { state: { formData, showSaveAndRequest: true } });
        }
    };

    const navigateToProDetails = (id: any, type: any) => {
        const propertyDetailsData = {
            id,
            type,
            campaignName,
            startDate,
            endDate,
            state,
            district
        }
        Navigate(`${process.env.REACT_APP_BASE_PATH}/property-details`, { state: { propertyDetailsData, showSaveAndRequest: true } });

    };

    const ShowAddDeleteCartBtn = (curObj: any) => {
        let status = false;
        cartData.map((cartItem: any) => {
            if (curObj.id === cartItem.cartItemId) {
                status = true;
            }
        });
        return status;
    }
    const handleRotate = () => {
        setSearch('');
        fetchBillBoardList();
    }
    const closeFilter = () => {
        setActiveCategoryLocation(null);
        setShowLocationFilters(!showLocationFilters);
    }
    const [config, setConfig] = useState<any[]>([]);
    const [propertyList, setPropertyListTerms] = useState<any[]>([]);

    useEffect(() => {
        fetchConfig();
    }, [])


    const fetchConfig = async () => {
        try {
            const response = await fetch(`${process.env.PUBLIC_URL}json/configJson.json`);
            const data = await response.json();
            const value = data.find((page: any) => page.name === "Property List");
            const Termsvalue = data.find((page: any) => page.name === "Property card details");


            setConfig(value?.configurations || []);

            console.log("Config Data property list------>", value.configurations);
        } catch (error) {
            console.error("Error fetching configuration:", error);
        }
    };



    const isFieldEnabled = (key: string, config: Configuration[]) => {
        const fieldConfig = config.flatMap(cfg => cfg.fields || []).find(field => field.key === key);
        return fieldConfig ? fieldConfig.default : true;
    };





    return (
        <>
            <div className="row property-list-search-container">
                <div className={`col-sm-12 ${showMap ? 'col-md-4' : 'col-md-5'}`}>
                    <div className="input-group assign_creative_input_group g-4">
                        <span className="input-group-text search-icon assign_creative_search_icon">
                            <img src={Image1} alt="" />
                        </span>
                        <input type="text" className="form-control border-bottom-only assign_creative_border_bottom_only " placeholder="Search for billboards" aria-label="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                        <span>
                            <i onClick={handleRotate} className="fa-solid fa-rotate"></i>
                        </span>
                        <div>
                            <img src={Image2} alt="" className="property_list_search_location_logo" onClick={handleShowMap} style={{ cursor: 'pointer' }} />
                        </div>
                        <div>
                            <img src={Image3} alt="Show Map" className="property_list_search_location_logo" onClick={handleShowMap} style={{ cursor: 'pointer' }} />
                        </div>
                    </div>
                </div>
                <div className={`col-sm-12 ${showMap ? 'col-md-8' : 'col-md-7'} property_list_search_con_2`}>
                    <div className="gx-2 gy-2 d-flex justify-content-end property_list_search_con_3">
                        <div className="">
                            <span className="assign_filter_by">Show only:</span>
                        </div>
                        <div className="">
                            <div className="dropdown">
                                <button className="btn btn-sm w-100 border-0 assign_drowdown_head" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {billboardType} <i className="fa-solid fa-angle-down"></i>
                                </button>
                                <ul className="dropdown-menu assign_dropdown_menu">
                                    {isFieldEnabled('classic', config) && (
                                        <li><a onClick={() => billboardTypeChange('classic')} className="dropdown-item assign_dropdown_item">Classic<i className="fa-solid fa-check assign_fa_check"></i></a></li>
                                    )}
                                    {isFieldEnabled('digital', config) && (
                                        <li><a onClick={() => billboardTypeChange('digital')} className="dropdown-item assign_dropdown_item">Digital<i className="fa-solid fa-check assign_fa_check"></i></a></li>
                                    )}
                                    {/* <li><a onClick={() =>billboardTypeChange('network')} className="dropdown-item assign_dropdown_item">Network<i className="fa-solid fa-check assign_fa_check"></i></a></li>
                                    <li><a onClick={() =>billboardTypeChange('scrollable')} className="dropdown-item assign_dropdown_item">Scrollable<i className="fa-solid fa-check assign_fa_check"></i></a></li>
                                    <li><a onClick={() =>billboardTypeChange('package')} className="dropdown-item assign_dropdown_item">Package<i className="fa-solid fa-check assign_fa_check"></i></a></li>
                                    <li><a onClick={() =>billboardTypeChange('transit')} className="dropdown-item assign_dropdown_item">Transit<i className="fa-solid fa-check assign_fa_check"></i></a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className=" d-flex">
                            <img className="property_list_filter_icon" src={Image5} alt="" />
                            <button className="assign_creative_popup_filter" data-bs-toggle="modal" data-bs-target="#filterModal" onClick={handleLocationFilterClick}>Filter</button>
                        </div>
                        <div className="">
                            <div className="property_list_search_card_con">
                                <button className="property_list_search_card" onClick={navigateToCart}><img className="detaillocation-frame-inner property-logo-img" alt="" height="15px" width="17px"
                                    src={Image6} /> Cart ({cartCount})</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row property-list-main-container">
                <div className={`col-md-${showMap ? '4' : '12'}`}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="property_list_avil_content">Available locations</p>
                                </div>
                                <div className="d-flex">
                                    <p className="property_list_pagination_content">Showing {pageStartData} to {pageEndData} of {totalDataLength}</p>
                                    {currentPage > 0 ? (
                                        <button className="property_list_pagination_left active" onClick={paginationMinus}> {"<"} </button>
                                    ) : (
                                        <button className="property_list_pagination_left">{"<"}</button>
                                    )}
                                    {totalDataLength > pageEndData ? (
                                        <button className="property_list_pagination_right" onClick={paginationPlus}>{" > "}</button>
                                    ) : (
                                        <button className="property_list_pagination_left">{">"}</button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">

                            {billBoardDataLoading ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: '40vh' }}>
                                    <span className="loading-circle sp1">
                                        <span className="loading-circle sp2">
                                            <span className="loading-circle sp3"></span>
                                        </span>
                                    </span>
                                </div>) : (
                                <>
                                    {billBoardData.length > 0 ? (
                                        <div className={`${showMap ? ' show-map' : ' hide-map'}` + " row"}>
                                            {billBoardData.map((listData: any, i: any) => (
                                                <div className={`col-md-${showMap ? '12' : '4'}`} >
                                                    <div className="property_list_scroll" onClick={() => handleSelectBillboard(listData)}>
                                                        <div className="card props-hover2 mb-3 border-0"  >
                                                            {isFieldEnabled('DisplayImage', config) && (
                                                                listData.thumbnailPath ? (
                                                                    <img className="card-img-top" alt={listData.displayName} src={listData.thumbnailPath} />
                                                                ) : (
                                                                    <img className="detaillocation-frame-inner property-logo-img" alt="no image" src={NoImage} />
                                                                )
                                                            )}

                                                            {isFieldEnabled('Availability', config) && (
                                                                listData.active ? (
                                                                    <span className="badge badge-danger status-label props-status-label bg-light text-success">
                                                                        <i className="fa-solid fa-circle"></i> Available
                                                                    </span>
                                                                ) : (
                                                                    <span className="badge badge-danger status-label props-status-label bg-light text-danger">
                                                                        <i className="fa-solid fa-circle"></i> Sold Out
                                                                    </span>
                                                                )
                                                            )}

                                                            <div className="card-img-overlay card-img-overlay-custom">
                                                                {listData.active && isFieldEnabled('AddToCart', config) && (
                                                                    listData.isInCart || ShowAddDeleteCartBtn(listData) ? (
                                                                        <button
                                                                            className="btn btn-danger propety-btn-danger"
                                                                            onClick={() => deleteCartItem(listData)}
                                                                        >
                                                                            <img
                                                                                className="detaillocation-frame-inner property-logo-img"
                                                                                alt=""
                                                                                src={Image9}
                                                                            />
                                                                            Delete from Cart
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            className="btn btn-primary propety-btn-primary w-100"
                                                                            onClick={() => addToCart(listData)}
                                                                        >
                                                                            <img
                                                                                className="detaillocation-frame-inner property-logo-img"
                                                                                alt=""
                                                                                src={Image9}
                                                                            />
                                                                            Add to Cart
                                                                        </button>
                                                                    )
                                                                )}

                                                                {isFieldEnabled('view more', config) && (
                                                                    listData.active ? (
                                                                        <button
                                                                            className="btn text-light mt-2 border-0 property-logo-view-details"
                                                                            onClick={() => navigateToProDetails(listData.id, listData.type)}
                                                                        >
                                                                            View details
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            className="btn text-light mt-2 border-0 property-logo-view-details"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#assignCreativeModal"
                                                                            onClick={() => soldCampaignDetails(listData)}
                                                                        >
                                                                            View details
                                                                        </button>
                                                                    )
                                                                )}

                                                            </div>
                                                            <div className="property-card-body property-card-3">
                                                                <div className="d-flex m-0">
                                                                    {isFieldEnabled('display name', config) && (
                                                                        <h6 className="property-card-title3">{listData.displayName}</h6>
                                                                    )}

                                                                    {isFieldEnabled('city', config) && (
                                                                        <p className="property-card-text-3">{listData.countryName}</p>
                                                                    )}
                                                                </div>
                                                                <p className="property-card-text-4">AUD {listData.sellingRate ? listData.sellingRate.monthly.month1 : ''}</p>
                                                                <div className="list-unstyled">
                                                                    <div className="property-row-3">
                                                                        {isFieldEnabled('resolution/size', config) && (
                                                                            <div className="column property-column-3">
                                                                                <p className="title-msg property-title-msg">Site resolution</p>
                                                                                {listData.type === "DIGITAL" ? (
                                                                                    <p className="value property-title-msg">
                                                                                        {listData.resolutionHeight} X {listData.resolutionWidth}
                                                                                    </p>
                                                                                ) : (
                                                                                    <p className="value property-title-msg">
                                                                                        {listData.panelHeight} X {listData.panelWidth}
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                        )}

                                                                        {isFieldEnabled('screen type', config) && (
                                                                            <div className="column  property-column-3">
                                                                                <p className="title-msg property-title-msg">Number of screens</p>
                                                                                <p className="value  property-title-msg">{listData.specification?.screens}</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="property-row-3">

                                                                        {isFieldEnabled('show potential views', config) && (
                                                                            <div className="column  property-column-3">
                                                                                <p className="title-msg property-title-msg">Impressions</p>
                                                                                <p className="value  property-title-msg">{listData.monthlySummary?.totalVisitors}</p>
                                                                            </div>
                                                                        )}

                                                                        {isFieldEnabled('Next Availability', config) && (

                                                                            <div className="column  property-column-3">
                                                                                <p className="title-msg property-title-msg">Next available slot</p>
                                                                                <p className="value  property-title-msg">{listData.nextAvailableDate}</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    ) : (<div className="text-center">No Data found...</div>)}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {showMap && (
                    <div className="col-md-8">
                        <div className="map-section">
                            <div
                                ref={mapContainer}
                                style={{ position: "absolute", top: '20rem', bottom: 0, width: "64%", height: '80%' }}
                            />
                        </div>
                    </div>
                )}
            </div>

            <div className="modal fade property-unavailable" id="assignCreativeModal" aria-labelledby="assignCreativeLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-header property_list_popup_header ">
                            <h5 className="modal-title property_list_popup_header_content " id="assignCreativeLabel">Property unavailable</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {soldCampaignViewDetails.thumbnailPath ? (
                                <img className="card-img-top" alt={soldCampaignViewDetails.displayName} src={soldCampaignViewDetails.thumbnailPath} />
                            ) : (
                                <img className="detaillocation-frame-inner property-logo-img" alt="no image" src={NoImage} />
                            )}
                            {soldCampaignViewDetails.availableBooking ? (
                                <span className="badge badge-danger status-label props-status-label bg-light text-success"><i
                                    className="fa-solid fa-circle"></i> Available</span>
                            ) : (
                                <span className="badge badge-danger status-label props-status-label bg-light text-danger"><i
                                    className="fa-solid fa-circle"></i> Sold Out</span>
                            )}
                            <div className="d-flex m-0">
                                <h6 className="property-card-title3">{soldCampaignViewDetails.displayName}</h6>
                                <p className="property-card-text-3">{soldCampaignViewDetails.countryName}</p>
                            </div>
                            <p className="property-card-text-4">AUD {soldCampaignViewDetails.sellingRate ? soldCampaignViewDetails.sellingRate.monthly.month1 : ''}</p>

                            <p className="property_list_popup_notify_content">This property seems to be unavailable at this moment, try changing your campaign duration as per next available slot</p>

                            <div className="column  property-column-3">
                                <p className="title-msg property-title-msg">Next available slot</p>
                                <p className="value  property-title-msg">{soldCampaignViewDetails.nextAvailableDate}</p>
                            </div>

                            <button className="property_list_popup_gotit_btn" data-bs-dismiss="modal" aria-label="Close">Got it</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="filterModal" aria-labelledby="filterModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header property_list_filter_popup_header border-0" >
                            <h5 className="modal-title property_list_filter_popup_header_content" id="filterModalLabel">Filters</h5>
                            <button type="button" onClick={closeFilter} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body ">
                            <div className="modal-header selected-filters d-flex">
                                <p className="property_list_filter_popup_selected_input_header">Selected inputs</p>
                                <div className="property_list_scrollbar" id="style">
                                    <div>
                                        {badges.map((badge, index) => (
                                            <span className="badge property_list_filter_popup_selected_input" key={index}>
                                                {badge}
                                                <button
                                                    type="button"
                                                    className="btn-close btn text-primary property_list_filter_popup_selected_input_close"
                                                    aria-label="Close"
                                                    onClick={() => handleRemoveBadge(badge)}
                                                ></button>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex row">
                                {/* Filters Column */}
                                <div className="filter-column col-md-3" style={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', paddingLeft: '0px', paddingRight: '0px' }}>
                                    <h6 className="property_list_popup_list_header">Filters</h6>
                                    <div className="category-list">
                                        <p className={`property_list_popup_list_header_1 ${activeCategoryLocation === 'location' ? 'active' : ''}`}
                                            id="locationFilterLink"
                                            onClick={handleLocationFilterClick}>
                                            Location based filters
                                        </p>
                                        <p
                                            className={`property_list_popup_list_header_1 ${activeCategory?.id === 'venue' ? 'active' : ''}`}
                                            id="locationFilterLink_1"
                                            onClick={handleLocationVenueClick}
                                        >
                                            Venue type
                                        </p>
                                        <p className={`property_list_popup_list_header_1 ${activeCategorySite === 'site' ? 'active' : ''}`}
                                            id="locationFilterLink_3"
                                            onClick={handleLocationSiteClick}>
                                            Site based filters
                                        </p>
                                        <p className={`property_list_popup_list_header_1 ${activeCategoryBill === 'bill' ? 'active' : ''}`}
                                            id="locationFilterLink_4"
                                            onClick={handleLocationBillClick}>
                                            Billboard based filters</p>
                                        <p className={`property_list_popup_list_header_1 ${activeCategoryPrice === 'price' ? 'active' : ''}`}
                                            id="locationFilterLink_5"
                                            onClick={handleLocationPriceClick}>
                                            Price based filters</p>
                                    </div>
                                    <p className="property_list_popup_list_header_formcheck"><input type="checkbox" checked /> Show unavailable location</p>
                                    <p className="property_list_popup_list_header_formcheck_1"><input type="checkbox" /> Show location without picture</p>
                                </div>

                                <div className="content-column col-md-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    {isFieldEnabled('environment', config) && showLocationFilters && (
                                        <div id="locationFilters" style={{ borderRight: '1px solid #ddd' }}>
                                            <h6 className="property_list_popup_list_header">Environment</h6>
                                            <div className="property_list_popup_list_cm">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="indoorCheck">
                                                        <input
                                                            type="checkbox"
                                                            id="indoorCheck"
                                                            onChange={(e) => handleCheckboxLocation('Indoor', e.target.checked)}
                                                            checked={indoorChecked}
                                                        /> Indoor
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="outdoorCheck">
                                                        <input
                                                            type="checkbox"
                                                            id="outdoorCheck"
                                                            onChange={(e) => handleCheckboxLocation('Outdoor', e.target.checked)}
                                                            checked={outdoorChecked}
                                                        /> Outdoor
                                                    </label>
                                                </div>

                                                {isFieldEnabled('select state', config) && (
                                                    <div className="mt-3">
                                                        <label htmlFor="selectState" className="form-label">Select State</label>
                                                        <select
                                                            className="form-select property_list_select_state country-select"
                                                            onChange={handleCountryChange}
                                                            id="selectState"
                                                            value={country}
                                                            disabled={!isFieldEnabled('select state', config)}
                                                        >
                                                            <option disabled value="">Country</option>
                                                            {countryOptions.length > 0 ? (
                                                                countryOptions.map((country) => (
                                                                    <option key={country.id} value={country.id}>
                                                                        {country.name}
                                                                    </option>
                                                                ))
                                                            ) : (
                                                                <option value="">No countries available</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                )}

                                                {/* {/ District Dropdown /} */}
                                                {!isFieldEnabled('select state', config)} <div className="mt-3">
                                                    <label htmlFor="selectDistrict" className="form-label">Select District</label>
                                                    <select
                                                        className="form-select property_list_select_state country-select"
                                                        onChange={(e) => setState(e.target.value)}
                                                        value={state}
                                                        id="selectDistrict"
                                                        disabled={!country || !isFieldEnabled('select state', config)}
                                                    >
                                                        <option disabled value="">State</option>
                                                        {stateOptions.length > 0 ? (
                                                            stateOptions.map((list) => (
                                                                list.stateId ? (
                                                                    <option key={list.stateId} value={list.stateId}>
                                                                        {list.name}
                                                                    </option>
                                                                ) : null
                                                            ))
                                                        ) : (
                                                            <option value="">No states available</option>
                                                        )}
                                                    </select>
                                                </div>



                                                <p className="property_list_popup_list_header_4"></p>
                                                <p className="property_list_popup_list_header_4"></p>
                                                <p className="property_list_popup_list_header_4"></p>
                                                <p className="property_list_popup_list_header_4"> </p>
                                                <p className="property_list_popup_list_header_4"> </p>
                                                <p className="property_list_popup_list_header_4"></p>

                                            </div>
                                        </div>
                                    )}




                                    {isFieldEnabled('categories', config) && showLocationVenue && (
                                        <div className="filter-column" style={{ borderRight: '1px solid #ddd', paddingRight: '0px' }}>
                                            <div className="row assign_creative_card_scroll">
                                                <h6 className="property_list_popup_list_header">Categories</h6>
                                                <div className="category-list">
                                                    {venueTypes?.map((category: Category, index: any) => (
                                                        <p
                                                            key={category.id || index}
                                                            className={`property_list_popup_list_header_1 ${activeCategory?.id === category.id ? 'active' : ''}`}  // Add active class here
                                                            onClick={() => handleLocationFinancialClick(category)}
                                                        >
                                                            {category.value}
                                                            <i className="fa-solid fa-chevron-right property_list_popup_rightarrow"></i>
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}



                                    {isFieldEnabled('site type', config) && showLocationSite && (
                                        <div className="filter-column" style={{ borderRight: '1px solid #ddd', paddingRight: '0px', paddingLeft: '12px' }}>
                                            <div className="filter-column" style={{ borderRight: '1px solid #ddd', paddingRight: '0px', paddingLeft: '12px' }}>
                                                <div className="row assign_creative_card_scroll">
                                                    <h6 className="property_list_popup_list_header">Site types</h6>
                                                    <div className="category-list">
                                                        <p className="property_list_popup_list_header_3">
                                                            <input type="radio" name="site-type" value="Classic" onChange={handleSiteTypeChange} /> Classic (55)
                                                        </p>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-type" value="Digital" onChange={handleSiteTypeChange} /> Digital (234)
                                                        </p>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-type" value="Network" onChange={handleSiteTypeChange} /> Network (125)
                                                        </p>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-type" value="Scrollable" onChange={handleSiteTypeChange} /> Scrollable (45)
                                                        </p>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-type" value="Package" onChange={handleSiteTypeChange} /> Package (67)
                                                        </p>
                                                        <p className="property_list_popup_list_header_4">
                                                            <input type="radio" name="site-type" value="Transit" onChange={handleSiteTypeChange} /> Transit (87)
                                                        </p>

                                                        {/* {/ Screen Size /} */}

                                                        {isFieldEnabled('screen Size (Digital)', config) && (
                                                            <><h6 className="property_list_popup_list_header">Screen Size (Digital)</h6><p className="property_list_popup_list_header_4">
                                                                <input type="radio" name="site-types" value="Small (15 sqft)" onChange={handleScreenSizeChange} /> Small (15 sqft)
                                                            </p><p className="property_list_popup_list_header_4">
                                                                    <input type="radio" name="site-types" value="Medium (15-99 sqft)" onChange={handleScreenSizeChange} /> Medium (15-99 sqft)
                                                                </p><p className="property_list_popup_list_header_4">
                                                                    <input type="radio" name="site-types" value="Large (100-249 Sqft)" onChange={handleScreenSizeChange} /> Large (100-249 sqft)
                                                                </p><p className="property_list_popup_list_header_4">
                                                                    <input type="radio" name="site-types" value="Extra Large (249 Sqft)" onChange={handleScreenSizeChange} /> Extra Large (249 sqft)
                                                                </p></>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}




                                    {isFieldEnabled('billboard Format Based', config) && showLocationBill && (
                                        <div className="filter-column" style={{ borderRight: '1px solid #ddd', paddingRight: '0px', paddingLeft: '12px' }}>
                                            <div className="row assign_creative_card_scroll">
                                                <h6 className="property_list_popup_list_header">Bill format based</h6>
                                                <div className="category-list">
                                                    <p className="property_list_popup_list_header_3" ><input type="checkbox" onChange={(e) => handleCheckboxLocationBill('96 sheets', e.target.checked)} checked={sheetsChecked} /> 96 sheets </p>
                                                    <p className="property_list_popup_list_header_4"><input type="checkbox" onChange={(e) => handleCheckboxLocationBill('Bulletin', e.target.checked)} checked={bulletin} /> Bulletin</p>
                                                    <p className="property_list_popup_list_header_4"><input type="checkbox" onChange={(e) => handleCheckboxLocationBill('City Lights', e.target.checked)} checked={cityLights} /> City Lights</p>
                                                    <p className="property_list_popup_list_header_4"><input type="checkbox" onChange={(e) => handleCheckboxLocationBill('Bus Shelter', e.target.checked)} checked={busShelter} /> Bus Shelter </p>
                                                    <p className="property_list_popup_list_header_4"><input type="checkbox" onChange={(e) => handleCheckboxLocationBill('Gallery', e.target.checked)} checked={gallery} /> Gallery </p>
                                                    <p className="property_list_popup_list_header_4"></p>
                                                    <p className="property_list_popup_list_header_4"></p>
                                                    <p className="property_list_popup_list_header_4"></p>
                                                    <p className="property_list_popup_list_header_4"> </p>
                                                    <p className="property_list_popup_list_header_4"> </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                    {showLocationPrice && (
                                        <div className="filter-column" style={{ borderRight: '1px solid #ddd', paddingRight: '0px', paddingLeft: '12px' }}>
                                            <div className="row assign_creative_card_scroll">

                                                <div className="category-list">

                                                    {isFieldEnabled('average price', config) && (


                                                        <div className="form-group">
                                                            <h6 className="property_list_popup_list_header">Average price</h6>
                                                            <input
                                                                type="range"
                                                                className="form-range"
                                                                min="1"
                                                                max="3500"
                                                                step="10"
                                                                value={minPrice}
                                                                onChange={handleMinPriceChange}
                                                            />
                                                        </div>
                                                    )}

                                                    {isFieldEnabled('minimum price', config) && (
                                                        <div className="input-group assign_creative_input_group mt-4">
                                                            <p className="property_list_popup_list_header_5">Min price</p>
                                                            <input
                                                                type="number"
                                                                className="form-control border-bottom-only assign_creative_border_bottom_only"
                                                                aria-label="Search"
                                                                value={minPrice}
                                                                onChange={handleMinPriceChange}
                                                            />
                                                        </div>
                                                    )}

                                                    {isFieldEnabled('maximum price', config) && (

                                                        <div className="input-group assign_creative_input_group mt-4">
                                                            <p className="property_list_popup_list_header_5">Max price</p>
                                                            <input
                                                                type="number"
                                                                className="form-control border-bottom-only assign_creative_border_bottom_only"
                                                                aria-label="Search"
                                                                value={maxPrice}
                                                                onChange={handleMaxPriceChange}
                                                            />
                                                        </div>

                                                    )}
                                                    <button onClick={handlePriceRangeChange} className="btn btn-primary mt-4 assign_creative_footer_btn_2">
                                                        Apply Price Range
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="content-column col-md-3" style={{ paddingLeft: '0px', paddingRight: '0px' }} >
                                    {showLocationFinancial && activeCategory && (
                                        <div className="filter-column" style={{ borderRight: '1px solid #ddd', paddingLeft: '0px' }}>
                                            <h6 className="property_list_popup_list_header">Sub-categories</h6>
                                            <div className="category-list">
                                                {activeCategory.child?.map((subCategory: SubCategory, index: any) => (
                                                    <p
                                                        key={subCategory.id || index}
                                                        className={`property_list_popup_list_header_1 ${activeSubCategory?.id === subCategory.id ? 'active' : ''}`}
                                                        onClick={() => handleSubCategoryClick(subCategory)}
                                                    >
                                                        {subCategory.value}
                                                        <i className="fa-solid fa-chevron-right property_list_popup_rightarrow"></i>
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="content-column col-md-3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    {showLocationFinancial && activeSubCategory && (
                                        <div className="filter-column" style={{ borderLeft: '1px solid #ddd' }}>
                                            <h6 className="property_list_popup_list_header">Products</h6>
                                            <div className="category-list">
                                                {activeSubCategory.child?.map((gradSubCategory: GrandSubCategory, index: number) => (
                                                    <div key={gradSubCategory.id || index} className="d-flex align-items-center">
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox-${gradSubCategory.id}`}
                                                            checked={badges.includes(gradSubCategory.value)}
                                                            onChange={() => handleCheckboxChange(gradSubCategory)}
                                                            className="grdcategory-checkbox"
                                                        />
                                                        <p
                                                            className={`property_list_popup_list_header_3 ${activeGradSubCategory?.id === gradSubCategory.id ? 'active' : ''}`}
                                                            onClick={() => handleGradSubCategoryClick(gradSubCategory)}
                                                        >
                                                            {gradSubCategory.value}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="assign_creative_footer_btn_1" onClick={() => resetAllStates()}>
                                    Reset
                                </button>
                                <button type="button" className="btn btn-primary assign_creative_footer_btn_2" data-bs-dismiss="modal" onClick={() => fetchBillBoardList()}>
                                    Apply filters
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default BillBoardListing;

function showPopup(inventory: any) {
    throw new Error("Function not implemented.");
}
