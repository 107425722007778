import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import ClientServedLogo1 from "../../../assets/images/mask-group-1@2x.png";
import ClientServedLogo2 from "../../../assets/images/mask-group-2@2x.png";
import ClientServedLogo3 from "../../../assets/images/mask-group-3@2x.png";
import ClientServedLogo4 from "../../../assets/images/mask-group-4@2x.png";
import ClientServedLogo5 from "../../../assets/images/mask-group-5@2x.png";
import ClientServedLogo6 from "../../../assets/images/mask-group-6@2x.png";

interface FormState {
  redirect: string | null,
}
class ClientServed extends Component<{}, FormState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      redirect: null,
    }
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }

    return (
      <>
        <div className="client-served-images row">
                <div className="client_img client-served-img1 col-md-2">
                    <img src={ClientServedLogo1} alt=""/>
                </div>
                <div className="client_img client-served-img2 col-md-2">
                    <img src={ClientServedLogo2} alt=""/>
                </div>
                <div className="client_img client-served-img1 col-md-2">
                    <img src={ClientServedLogo3} alt=""/>
                </div>
                <div className="client_img client-served-img3 col-md-2">
                    <img src={ClientServedLogo4} alt=""/>
                </div>
                <div className="client_img client-served-img1 col-md-2">
                    <img src={ClientServedLogo5} alt=""/>
                </div>
                <div className="client_img client-served-img2 col-md-2">
                    <img src={ClientServedLogo6} alt=""/>
                </div>
            </div>
      </>
    );
  }
}
export default ClientServed;
