import React, { Component, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Image1 from "../../../assets/images/DSC_7293-17169.jpg";
import Image from "../../../assets/images/132566552488339083.jpg";
import Img from "../../../assets/images/login-right-bg.png";
import { PROPERTY_DETAILS } from "../../../Graphql/Queries";
import client from "../../../Graphql/apolloClient";
import { generateMockData } from "../../../Graphql/MockData";

interface PropertyDetails {
  mainImage: string;
  redirect: string | null;
  thumbnailPath: string;
}

interface PropertyDetails_props {
  assest_img: Boolean
}

const PropertyDetailsGallery: React.FC<PropertyDetails_props> = ({ assest_img }) => {
  const [redirect, setRedirect] = useState<string | null>(null);
  const [propertyDetailsStoreValue, setPropertyDetailsStoreValue] = useState<PropertyDetails[]>([]);
  const [mainImage, setMainImage] = useState<string>(Img); // Default main image
  const location = useLocation();
  const propertyDetailsValue = location.state?.propertyDetailsData || {};


  const fetchPropertyDetailsGallery = async () => {
    const token = localStorage.getItem("authToken");
    const companyId = localStorage.getItem("companyId");
    const countryId = localStorage.getItem("countryId");
    try {
      const { data } = await client.query({
        query: PROPERTY_DETAILS,
        variables: {
          accessToken: token,
          id: propertyDetailsValue?.id,
          companyId: companyId,
          countryId: countryId,
          billboardType: propertyDetailsValue?.type

        }
      })
      setPropertyDetailsStoreValue([data.propertyDetails]);
      setMainImage(data.propertyDetails.thumbnailPath);

      console.log("Fetched Property Details specifications----->:", data.propertyDetails.thumbnailPath);

    } catch (error) {
      console.error("Error fetching property details:", error);
    }
  }
  
  useEffect(() => {
    if (process.env.REACT_APP_MOCK_DATA === 'true') {
      generateMockData(PROPERTY_DETAILS)
        .then((mockResponse: any) => {
          console.log("mockData", mockResponse);
          const data = mockResponse.data;
          setPropertyDetailsStoreValue([data.propertyDetails]);
          setMainImage(data.propertyDetails.thumbnailPath);

        })
        .catch((err: any) => {
          console.error("Error generating mock data:", err);
        });
    }
    else {
      fetchPropertyDetailsGallery();
    }
  }, [])

  if (redirect) {
    return <Navigate to={redirect} />;
  }


  const handleImageClick = (newImage: string) => {
    setMainImage(newImage); // Update the main image on click
  };


  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6">
          <img
            id="mainImage"
            src={mainImage} // Dynamic image
            className="main-image property-main-image"
            alt="Main Image"
          />
        </div>

        <div className="col-md-6 thumb-container property-thumb-container">
          {assest_img && (
            <div className="row">
              {propertyDetailsStoreValue.map((val, index) => (
                <div className="col-4" key={index}>
                  <img
                    src={val.thumbnailPath || Image1}
                    className="thumb-image property-thumb-img"
                    alt={`Thumbnail ${index + 1}`}
                    onClick={() => handleImageClick(val.thumbnailPath)} // Update main image on click
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );


}


export default PropertyDetailsGallery;
