import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/default";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../../components/common/NavBar";
import './index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, addDays } from "date-fns"; // Import addDays from date-fns
import Image1 from "../../assets/images/calendar.jpg";
import { GET_COUNTRY_LIST, GET_DISTRICT_LIST, GET_STATE_LIST } from "../../Graphql/Queries";
import { useQuery } from "@apollo/client";

const CreateCampaign: React.FC = () => {
    const currentDate = new Date();
    const [redirect, setRedirect] = useState<string | null>(null);
    const location = useLocation();
    const { exploreData } = location.state || {};
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    useEffect(() => {
        console.log("Received exploreData:", exploreData);

        if (exploreData && exploreData.exploreCondtion == true) {
            const { exploreStartDate, exploreEndDate } = exploreData;

            const start = new Date(exploreStartDate);
            const end = new Date(exploreEndDate);

            console.log("Start Date String:", exploreStartDate);
            console.log("End Date String:", exploreEndDate);
            if (isNaN(start.getTime()) || isNaN(end.getTime())) {
                console.log("Invalid date format");
            } else {
                console.log("Valid Start Date:", start);
                console.log("Valid End Date:", end);

                setStartDate(start);
                setEndDate(end);
            }
        } else {
            console.log("exploreData or exploreCondition is missing");
        }
    }, [exploreData]);

    console.log("Start Date in State:", startDate);
    const [tempStartDate, setTempStartDate] = useState<Date | null>(currentDate);
    const [tempEndDate, setTempEndDate] = useState<Date | null>(currentDate);
    const [showMonthPicker, setShowMonthPicker] = useState<boolean>(false);
    const [showYearPicker, setShowYearPicker] = useState<boolean>(false);
    const [selectedMonth, setSelectedMonth] = useState<number>(currentDate.getMonth());
    const [selectedYear, setSelectedYear] = useState<number>(currentDate.getFullYear());
    const [showStartDatePicker, setShowStartDatePicker] = useState<boolean>(false);
    const [showEndDatePicker, setShowEndDatePicker] = useState<boolean>(false);
    const [campaignName, setCampaignName] = useState<string>("");
    const [countryOptions, setCountryOptions] = useState<{ id: string; name: string; dialingCode: string }[]>([]);
    const [selectedStateId, setSelectedStateId] = useState<string | undefined>();
    const [country, setCountry] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [stateOptions, setStateOptions] = useState<{
        stateId: string;
        name: string;
    }[]>([]);

    const [district, setDistrict] = useState<string>("");
    const [districtOptions, setDistrictOptions] = useState<{
        districtId: string | null;
        id: string;
        name: string;
    }[]>([]);

    const countryId = localStorage.getItem('countryId');
    const Navigate = useNavigate();

    // Calculate tomorrow's date
    const today = new Date();
    const tomorrow = addDays(today, 0); // Tomorrow's date




    const handleStartDateChange = (date: Date | null) => {
        if (date) {
            setTempStartDate(date);
            setShowMonthPicker(false);
            setShowYearPicker(false);
        }
    };

    const handleEndDateChange = (date: Date | null) => {
        if (date) {
            setTempEndDate(date);
            setShowMonthPicker(false);
            setShowYearPicker(false);
        }
    };

    const toggleStartDatePicker = () => {
        setShowStartDatePicker(!showStartDatePicker);
        setTempStartDate(startDate);
        setShowMonthPicker(false);
        setShowYearPicker(false);
    };

    const toggleEndDatePicker = () => {
        setShowEndDatePicker(!showEndDatePicker); // Fix here: toggle showEndDatePicker instead of showStartDatePicker
        setTempEndDate(endDate);
        setShowMonthPicker(false);
        setShowYearPicker(false);
    };

    const toggleMonthPicker = () => {
        setShowMonthPicker(!showMonthPicker);
        setShowYearPicker(false);
    };

    const toggleYearPicker = () => {
        setShowYearPicker(!showYearPicker);
        setShowMonthPicker(false);
    };

    const handleMonthSelection = (month: number) => {
        const newStartDate = tempStartDate ? new Date(tempStartDate) : new Date();
        const newEndDate = tempEndDate ? new Date(tempEndDate) : new Date();

        newStartDate.setMonth(month);
        newEndDate.setMonth(month);

        setTempStartDate(newStartDate);
        setTempEndDate(newEndDate);
        setSelectedMonth(month);
        setShowMonthPicker(false);
    };

    const handleYearSelection = (year: number) => {
        const newStartDate = tempStartDate ? new Date(tempStartDate) : new Date();
        const newEndDate = tempEndDate ? new Date(tempEndDate) : new Date();

        newStartDate.setFullYear(year);
        newEndDate.setFullYear(year);

        setTempStartDate(newStartDate);
        setTempEndDate(newEndDate);
        setSelectedYear(year);
        setShowYearPicker(false);
    };

    const handleUpdateStartDate = () => {
        setStartDate(tempStartDate);
        setShowStartDatePicker(false);
    };

    const handleUpdateEndDate = () => {
        setEndDate(tempEndDate);
        setShowEndDatePicker(false);
    };

    const handleMonthButtonClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const isFormValid = () => {
        return campaignName && startDate && endDate;
    };

    const handleCampaignSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const selectedState = stateOptions.find(s => s.stateId === state);
            const selectedDistrict = districtOptions.find(c => c.districtId === district);
            const exploreDatas = {
                exploreData
            }
            const formData = {
                campaignName,
                startDate,
                endDate,
                state: selectedState ? selectedState : '',
                district: selectedDistrict ? selectedDistrict : '',
            };

            Navigate(`${process.env.REACT_APP_BASE_PATH}/propertylist`, {
                state: {
                    formData,
                    exploreDatas
                }
            }); console.log(formData);
        } catch (error) {
            console.error(error);
        }
    };

    const { data: countryData } = useQuery(GET_COUNTRY_LIST);

    useEffect(() => {
        if (countryData && countryData.countryList) {
            setCountryOptions(countryData.countryList);
        }
    }, [countryData]);

    const { data: stateData } = useQuery(GET_STATE_LIST, {
        variables: { countryId: countryId }
    });

    useEffect(() => {
        if (stateData && stateData.stateList) {
            setStateOptions(stateData.stateList);
        }
    }, [stateData]);

    const { data: districtData } = useQuery(GET_DISTRICT_LIST, {
        variables: { stateid: selectedStateId },
        skip: !selectedStateId,
    });

    useEffect(() => {
        if (districtData && districtData.districtList) {
            setDistrictOptions(districtData.districtList);
        }
    }, [districtData]);

    const handleStateChange = (e: { target: { value: string } }) => {
        const selectedId = e.target.value;
        const selectedState = stateOptions.find(state => state.stateId === selectedId);

        if (selectedState) {
            setSelectedStateId(selectedState.stateId);
            setState(selectedId);
        } else {
            setCountry("");
        }
    };

    return (
        <DefaultLayout>
            <NavBar />
            <div className="create-campaign-fullform">
                <div className="container create-campaign-container">
                    <div className="card campaign-create-card">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="campaign-create-header-content mb-5">
                                    <h2 className="campaign-create-header-heading">Create campaign</h2>
                                    <p className="campaign-create-header-para">Run your next outdoor advertising campaign with just a few clicks.</p>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleCampaignSubmit}>
                            <div className="campaign-form">
                                <div className="form-row campaign-name">
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <h3 className="campaign-create-label">Create campaign</h3>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <input type="text" className="campaign-input-name border-bottom-only no-focus-border"
                                                onChange={(e) => setCampaignName(e.target.value)}
                                                value={campaignName} placeholder="Enter your campaign here" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row campaign-duration">
                                    <div className="form-group col-md-12">
                                        <h3 className="campaign-create-label">Duration</h3>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card_date_start card_date_starts date-picker">
                                            <div onClick={toggleStartDatePicker} className="calendar-icon-wrapper">
                                                <img alt="Start Date" src={Image1} className="date-icon" />
                                            </div>
                                            <div className="date-text">
                                                <p>{startDate ? format(startDate, 'dd MMM yyyy') : <span className="date-default-text">From</span>}</p>
                                            </div>
                                            {showStartDatePicker && (
                                                <div className="datepicker-wrapper">
                                                    <div className="close-icon" onClick={toggleStartDatePicker}>
                                                        ×
                                                    </div>
                                                    <p className="card_date_header">Select Campaign start date</p>
                                                    {showYearPicker ? (
                                                        <div className="year-picker">
                                                            <div className="years-grid">
                                                                {Array.from({ length: 10 }, (_, i) => (
                                                                    <button
                                                                        key={i}
                                                                        onClick={() => handleYearSelection(selectedYear + i)}
                                                                        className="year-button"
                                                                    >
                                                                        {selectedYear + i}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ) : showMonthPicker ? (
                                                        <div className="month-picker">
                                                            <div className="months-grid">
                                                                {Array.from({ length: 12 }, (_, i) => (
                                                                    <button
                                                                        key={i}
                                                                        onClick={() => handleMonthSelection(i)}
                                                                        className="month-button"
                                                                    >
                                                                        {format(new Date(2021, i), "MMM")}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <DatePicker
                                                            selected={tempStartDate}
                                                            onChange={handleStartDateChange}
                                                            inline
                                                            minDate={new Date()} // Block all dates before the current date
                                                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                                <div className="custom-header">
                                                                    <button onClick={(e) => { handleMonthButtonClick(e); decreaseMonth(); }}>{"«"}</button>
                                                                    <span onClick={toggleMonthPicker}>
                                                                        {format(date, "MMMM")}
                                                                    </span>
                                                                    <span onClick={toggleYearPicker}>
                                                                        {format(date, "yyyy")}
                                                                    </span>
                                                                    <button onClick={(e) => { handleMonthButtonClick(e); increaseMonth(); }}>{"»"}</button>
                                                                </div>
                                                            )}
                                                        />
                                                    )}
                                                    <button className="update-button" onClick={handleUpdateStartDate}>
                                                        Update
                                                    </button>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card_date_end card_date_ends">
                                            <div onClick={toggleEndDatePicker} className="calendar-icon-wrapper">
                                                <img alt="End Date" src={Image1} className="date-icon" />
                                            </div>
                                            <div className="date-text">
                                                <p>{endDate ? format(endDate, 'dd MMM yyyy') : <span className="date-default-text">To</span>}</p>
                                            </div>
                                            {showEndDatePicker && (
                                                <div className="datepicker-wrapper">
                                                    <div className="close-icon" onClick={toggleEndDatePicker}>
                                                        ×
                                                    </div>
                                                    <p className="card_date_header">Select Campaign end date</p>
                                                    {showYearPicker ? (
                                                        <div className="year-picker">
                                                            <div className="years-grid">
                                                                {Array.from({ length: 10 }, (_, i) => (
                                                                    <button
                                                                        key={i}
                                                                        onClick={() => handleYearSelection(selectedYear + i)}
                                                                        className="year-button"
                                                                    >
                                                                        {selectedYear + i}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ) : showMonthPicker ? (
                                                        <div className="month-picker">
                                                            <div className="months-grid">
                                                                {Array.from({ length: 12 }, (_, i) => (
                                                                    <button
                                                                        key={i}
                                                                        onClick={() => handleMonthSelection(i)}
                                                                        className="month-button"
                                                                    >
                                                                        {format(new Date(2021, i), "MMM")}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <DatePicker
                                                            selected={tempEndDate}
                                                            onChange={handleEndDateChange}
                                                            inline
                                                            minDate={new Date()} // Block all dates before the current date
                                                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                                <div className="custom-header">
                                                                    <button onClick={(e) => { handleMonthButtonClick(e); decreaseMonth(); }}>{"«"}</button>
                                                                    <span onClick={toggleMonthPicker}>
                                                                        {format(date, "MMMM")}
                                                                    </span>
                                                                    <span onClick={toggleYearPicker}>
                                                                        {format(date, "yyyy")}
                                                                    </span>
                                                                    <button onClick={(e) => { handleMonthButtonClick(e); increaseMonth(); }}>{"»"}</button>
                                                                </div>
                                                            )}
                                                        />
                                                    )}
                                                    <button className="update-button" onClick={handleUpdateEndDate}>
                                                        Update
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group campaign-state-text">
                                            <h3 className="campaign-create-label">State</h3>
                                            <div className="create-capaign-hatimbig-parent dropdown-method-topside">
                                                <div className="companyform-group">
                                                    <select
                                                        onChange={handleStateChange}
                                                        value={state}
                                                        id="state"
                                                        className="country-select"
                                                    >
                                                        <option disabled value="">Select State</option>
                                                        {stateOptions.length > 0 ? (
                                                            stateOptions.map((list) => (
                                                                list.stateId ? (
                                                                    <option key={list.stateId} value={list.stateId}>
                                                                        {list.name}
                                                                    </option>
                                                                ) : null
                                                            ))
                                                        ) : (
                                                            <option value="">No states available</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="company-details-frame-country"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group campaign-district-text">
                                            <h3 className="campaign-create-label">District</h3>
                                            <div className="create-capaign-hatimbig-parent">
                                                <div className="companyform-group">
                                                    <select
                                                        onChange={(e) => setDistrict(e.target.value)}
                                                        value={district}
                                                        id="district"
                                                        className="country-select"
                                                    >
                                                        <option disabled value="">Select District</option>
                                                        {districtOptions.length > 0 ? (
                                                            districtOptions.map((list) => (
                                                                list.districtId ? (
                                                                    <option key={list.districtId} value={list.districtId}>
                                                                        {list.name}
                                                                    </option>
                                                                ) : null
                                                            ))
                                                        ) : (
                                                            <option value="">No states available</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="company-details-frame-state"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="campagin-submit-btn">
                                    <button type="submit"
                                        disabled={!isFormValid()}
                                        className={`${!isFormValid() ? 'disabled' : ''}`}
                                    >Next</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
};

export default CreateCampaign;
