import React, { Component, useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Card1 from "../../../assets/images/unsplashv3qhk9rhtju@2x.png";
import Card2 from "../../../assets/images/unsplashv3qhk9rhtju-2@2x.png";
import Card3 from "../../../assets/images/unsplashv3qhk9rhtju@2x.png";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { EXPLORE_PROPERTY_BUTTON } from "../../../Graphql/Queries";
import { generateMockData } from "../../../Graphql/MockData";

const ExploreProperties = () => {
  const navigate = useNavigate();
  const isAuthenticated = Boolean(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [properties, setProperties] = useState<any[]>([]);
  let authToken: any = "";
  const { data, loading, error } = useQuery(EXPLORE_PROPERTY_BUTTON);
  useEffect(() => {
    if (process.env.REACT_APP_MOCK_DATA === 'true') {

      console.log("explore property button", EXPLORE_PROPERTY_BUTTON);

      // Generate mock data when mockData is true
      generateMockData(EXPLORE_PROPERTY_BUTTON).then(mockResponse => {
        console.log("mockData", mockResponse);
        setProperties(mockResponse.data.landingpageExploreButton);
      }).catch(err => {
        console.error("Error generating mock data:", err);
      });
    } else {
      // If not using mock data, set properties from useQuery
      if (data) {
        setProperties(data.landingpageExploreButton);
      }
      authToken = localStorage.getItem('authToken')
    }
  }, [data]); // Only run this effect on mount and when mockData changes

  // const properties:any = data.landingpageExploreButton;
  const handlePropertyDetails = (propertyId: any) => {
    console.log("PropertyIDDD", propertyId);

    if (!isAuthenticated) {
      setOpenError(true);
      setErrorMessage("You need to be logged in to view property details.");
    } else {
      navigate(`/property-details/${propertyId}`);
    }
  }
  const Navigate = useNavigate();
  const navigateToProDetails = (id: any, type: any, campaignName: any, startDate: any, endDate: any) => {
    const propertyDetailsData = {
      id,
      type,
      campaignName,
      startDate,
      endDate
    }
    Navigate(`${process.env.REACT_APP_BASE_PATH}/exploreproperty-details`, { state: { propertyDetailsData, toexploreproperty: true } });

  };
  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: '40vh' }}>
          <span className="loading-circle sp1">
            <span className="loading-circle sp2">
              <span className="loading-circle sp3"></span>
            </span>
          </span>
        </div>) : (

        <div className="row">
          {properties.slice(0, 3).map((property: any) => (
            <div className="col-md-4">
              <div key={property.id} className="card explore-card explore-card1">
                <div className="card-frame1">
                  <i className="fa-solid fa-circle"></i> Selling fast
                </div>
                <img src={property.thumbnailPath} className="card-img" alt={property.displayName} />
                <div className="card-body">
                  <span className="card-title">{property.displayName} <span className="card-text">{property.stateName}</span></span>
                  <p className="card-price">AUD {property?.sellingRate?.monthly?.month1}</p>
                  <button className="btn btn-primary card-btn" onClick={() => navigateToProDetails(property.id, property.type, property.campaignName, property.startDate, property.endDate)}>View details</button>
                  {/* {/ <button className="btn btn-primary card-btn" data-bs-toggle="modal" data-bs-target="#redirectToLoginModal">View details</button> /} */}
                </div>
              </div>
            </div>
          ))
          }
        </div>)}
    </>
  );
};
export default ExploreProperties;
