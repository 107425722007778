import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Property1 from "../../../assets/images/explore_property1.jpeg"
import { useQuery } from "@apollo/client";
import { EXPLORE_PROPERTY_BUTTON } from "../../../Graphql/Queries";
import { generateMockData } from "../../../Graphql/MockData";

interface FormState {
  redirect: string | null,
}
const PropertiesCarousel = () => {
  const [sortedProperties, setSortedProperties] = useState<any[]>([]);
  const [centerIndex, setCenterIndex] = useState<number>(0);
  const [authToken, setAuthToken] = useState<string>("");

  const { data, loading, error } = useQuery(EXPLORE_PROPERTY_BUTTON);

  const sortByVisitors = (properties: any[]) => {
    return [...properties].sort((a, b) => {
      const aUnique = a.monthlySummary.uniqueVisitors;
      const bUnique = b.monthlySummary.uniqueVisitors;
      const aTotal = a.monthlySummary.totalVisitors;
      const bTotal = b.monthlySummary.totalVisitors;

      if (aUnique !== bUnique) {
        return bUnique - aUnique;
      } else {
        return bTotal - aTotal;
      }
    });
  };

  useEffect(() => {
    if (process.env.REACT_APP_MOCK_DATA === 'true') {

      console.log("explore property button", EXPLORE_PROPERTY_BUTTON);
      generateMockData(EXPLORE_PROPERTY_BUTTON).then(mockResponse => {
        console.log("mockData", mockResponse);
        const sorted = sortByVisitors(mockResponse.data.landingpageExploreButton);
        setSortedProperties(sorted.slice(0, 3));
      }).catch(err => {
        console.error("Error generating mock data:", err);
      });
    } else {
      const token: any = localStorage.getItem('authToken');
      setAuthToken(token);
      if (!loading && !error && data) {


        const sorted = sortByVisitors(data.landingpageExploreButton);
        setSortedProperties(sorted.slice(0, 3));
      }
    }
  }, [loading, error, data]);

  const Navigate = useNavigate();
  const navigateToProDetails = (id: any, type: any, campaignName: any, startDate: any, endDate: any) => {
    const propertyDetailsData = {
      id,
      type,
      campaignName,
      startDate,
      endDate
    }
    Navigate(`${process.env.REACT_APP_BASE_PATH}/exploreproperty-details`, { state: { propertyDetailsData, toexploreproperty: true } });

  };
  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: '40vh' }}>
          <span className="loading-circle sp1">
            <span className="loading-circle sp2">
              <span className="loading-circle sp3"></span>
            </span>
          </span>
        </div>) : (
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-indicators">
            {sortedProperties.map((property, index) => (
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={index === 0 ? 'active' : ""} aria-current="true" aria-label={"Slide " + index}></button>
            ))}
          </div>
          <div className="carousel-inner">

            {sortedProperties.map((property, index) => (
              <div className={"carousel-item" + (index === 0 ? " active" : "")} key={property.id}>
                <img src={property.thumbnailPath} className="d-block w-90" alt="..." />
                <div className="carousel-caption d-none d-md-block">
                  <div className="curousel-cnt row">
                    <div className="col-md-6">
                      <span className="carousel-caption-title">{property.displayName} <span className="carousel-caption-text">{property.stateName}</span></span>
                      <p className="carousel-price">AUD 5,000/month</p>
                    </div>
                    <div className="carousel-btn  col-md-6">
                      <button className="btn btn-primary carousel-btn-view" onClick={() => navigateToProDetails(property.id, property.type, property.campaignName, property.startDate, property.endDate)}>View details</button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      )}
    </>
  );
};
export default PropertiesCarousel;
