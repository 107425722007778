import React from 'react';
import './index.css';
import { Col, Row } from 'react-bootstrap';

class Footer extends React.Component
{
  render()
  {
    return (
      <footer>
        <div className="jumbotron text-center">
          
        </div>
      </footer>
    )
  }
}
export default Footer;
