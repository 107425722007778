import { ApolloClient, InMemoryCache } from '@apollo/client';

let token = localStorage.getItem('authToken')
export const GRAPHQL_URI = 'https://stg-oss-api-v2.movingwalls.com/graphql/';

const client = new ApolloClient({
    uri: GRAPHQL_URI,
    headers: {
        authorization: `Bearer ${token}`,
    },
    cache: new InMemoryCache()
});

export default client;