import React, { Component } from "react";
import "./index.css";
import NavBar from "../NavBar";
class Header extends Component {

    render() {

        return (
            <>
                <NavBar />
            </>
        );
    }
}

export default Header;
