import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Image1 from "../../../assets/images/calender.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './index.css';
import { format } from "date-fns";
import { useQuery } from '@apollo/client';
import { PROOF_OF_PLAY, PROOF_OF_PLAY_ASSERTS } from "../../../Graphql/Queries";

interface ProofInventory {
    inventoryName: string;
    id: string;
    inventoryId: string;
    inventoryType: string;
}

interface ProofValues {
    campaignInventories: ProofInventory[];
    metadata: any;
    campaignName?: string;
    dealId?: string;
    duration?: string;
}

interface ProofOfPlayDate {
    campaignId: string;
}

const ProofOfPlayDate: React.FC<ProofOfPlayDate> = ({ campaignId }) => {
    const [redirect, setRedirect] = useState<string | null>(null);
    const currentDate = new Date();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showStartDatePicker, setShowStartDatePicker] = useState<boolean>(false);
    const [showEndDatePicker, setShowEndDatePicker] = useState<boolean>(false);
    const [tempStartDate, setTempStartDate] = useState<Date>(currentDate);
    const [tempEndDate, setTempEndDate] = useState<Date>(currentDate);
    const [showMonthPicker, setShowMonthPicker] = useState<boolean>(false);
    const [showYearPicker, setShowYearPicker] = useState<boolean>(false);
    const [selectedMonth, setSelectedMonth] = useState<number>(currentDate.getMonth());
    const [selectedYear, setSelectedYear] = useState<number>(currentDate.getFullYear());
    const [proofValues, setProofValues] = useState<ProofValues | undefined>(undefined);
    const [selectDropdown, setSelectDropdown] = useState<ProofInventory[]>([]);
    const [selectedInventoryId, setSelectedInventoryId] = useState('');
    const [loadingData, setLoadingData] = useState(false);
    const [loadingAssets, setLoadingAssets] = useState(false);
    const [errorData, setErrorData] = useState<string | null>(null);
    const [errorAssets, setErrorAssets] = useState<string | null>(null);

    const token = localStorage.getItem('authToken');
    const selectedCampaignId = localStorage.getItem('selectedCampaignId');
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyId');

    const location = useLocation();
    const userData = location.state;
    const navigate = useNavigate();


    const locations = useLocation();
    const { proofData } = locations?.state || {};

    console.log(proofData, "prave");



    // useEffect(() => {
    //     if (proofData) {
    //         setStartDate(new Date(proofData.startDate));
    //         setEndDate(new Date(proofData.endDate));
    //     }
    // }, [proofData]);

    // Proof Of Play Asserts API

    const { data: assertsData, loading: assertsLoading, error: assertsError } = useQuery(PROOF_OF_PLAY_ASSERTS, {
        variables: {
            accessToken: token,
            metadataCampaignId: selectedCampaignId,
            metadataMediaOwnerId: userId,
            metadataType: "LMX_POC",
            source: "LMX_COMMERCE",
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd"),
            page: 0,
            size: 8,
            sort: "lastModifiedDate,desc",
            billboardId: selectedInventoryId === "all" ? "" : selectedInventoryId,  // Empty string for "all", else send the selected inventoryId
        }
    });


    useEffect(() => {
        if (assertsLoading) {
            setLoadingAssets(true);
            setErrorAssets(null);  // Reset any previous error
        } else if (assertsError) {
            setLoadingAssets(false);
            setErrorAssets("Error fetching Proof of Play asserts.");
        } else {
            setLoadingAssets(false);
            if (assertsData) {
                const proofOfPlayAssets = assertsData?.proofOfPlayAssets;
                if (proofOfPlayAssets && proofOfPlayAssets?.length > 0) {
                    const assetDurations = proofOfPlayAssets?.map((asset: { duration: number; }) =>
                        asset.duration ? `${asset.duration?.toFixed(2)}` : "N/A"
                    );
                }
            }
        }
    }, [assertsLoading, assertsData, assertsError]);





    // Proof Of Play API
    const { data, loading, error } = useQuery(PROOF_OF_PLAY, {
        variables: {
            accessToken: token,
            page: 0,
            campaignId: selectedCampaignId,
            metadataType: "LMX_POCLMX_COMMERCE",
            metadataCampaignId: selectedCampaignId,
            metadataMediaOwnerId: userId,
            source: "LMX_COMMERCE",
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd"),
            size: 8,
            sort: "lastModifiedDate,desc",
        },
        skip: !token || !userId || !companyId || !selectedCampaignId, // Skip query if any required variable is missing
    });

    useEffect(() => {
        console.log("Loading:", loading);
        console.log("Error:", error);
        console.log("Data:", data);

        if (loading) {
            setLoadingData(true);
            setErrorData(null);
        } else if (error) {
            setLoadingData(false);

            if (error.graphQLErrors) {
                console.error("GraphQL Errors:", error.graphQLErrors);
            }
            if (error.networkError) {
                console.error("Network Error:", error.networkError);
            }
            setErrorData("Error fetching Proof of Play data.");
        } else {
            setLoadingData(false);
            if (data && data.proofOfPlay) {
                console.log("API Response:", data.proofOfPlay);
                const { campaignInventories, startDate, endDate } = data.proofOfPlay;

                setStartDate(startDate?.date);
                setEndDate(endDate?.date);

                if (campaignInventories && campaignInventories.length > 0) {
                    setSelectDropdown(campaignInventories);
                    setProofValues(data.proofOfPlay);
                } else {
                    setSelectDropdown([]);
                    setErrorData("No valid campaign inventories found.");
                }
            } else {
                setSelectDropdown([]);
                setErrorData("No valid Proof of Play data received.");
            }
        }
    }, [data, loading, error]);




    // Filter dropdown options based on the matching campaign
    const isMatchingCampaign = data?.proofOfPlay?.id === campaignId;
    const filteredDropdown = isMatchingCampaign ? selectDropdown.filter(inventory => inventory.inventoryType) : [];


    const sss = data?.proofOfPlay?.id

    console.log(sss, "sssssssssssssssssssssssssss");


    const handleStartDateChange = (date: Date | null) => {
        if (date) {
            setTempStartDate(date);
            setShowMonthPicker(false);
            setShowYearPicker(false);
        }
    };

    const handleEndDateChange = (date: Date | null) => {
        if (date) {
            setTempEndDate(date);
            setShowMonthPicker(false);
            setShowYearPicker(false);
        }
    };

    const toggleStartDatePicker = () => {
        setShowStartDatePicker(!showStartDatePicker);
        setTempStartDate(startDate);
        setShowMonthPicker(false);
        setShowYearPicker(false);
    };

    const toggleEndDatePicker = () => {
        setShowEndDatePicker(!showStartDatePicker);
        setTempEndDate(endDate);
        setShowMonthPicker(false);
        setShowYearPicker(false);
    };
    const toggleMonthPicker = () => {
        setShowMonthPicker(!showMonthPicker);
        setShowYearPicker(false);
    };

    const toggleYearPicker = () => {
        setShowYearPicker(!showMonthPicker);
        setShowMonthPicker(false);
    };
    const handleMonthSelection = (month: number) => {
        const newStartDate = new Date(tempStartDate);
        const newEndDate = new Date(tempEndDate);
        newStartDate.setMonth(month);
        newEndDate.setMonth(month);

        setTempStartDate(newStartDate);
        setTempEndDate(newEndDate);
        setSelectedMonth(month);
        setShowMonthPicker(false);
    };

    const handleYearSelection = (year: number) => {
        const newStartDate = new Date(tempStartDate);
        const newEndDate = new Date(tempEndDate);
        newStartDate.setFullYear(year);
        newEndDate.setFullYear(year);

        setTempStartDate(newStartDate);
        setTempEndDate(newEndDate);
        setSelectedYear(year);
        setShowYearPicker(false);
    };
    const handleUpdateStartDate = () => {
        setStartDate(tempStartDate);
        setShowStartDatePicker(false);

        // Navigate and pass start date in the state
        navigate('/proof-of-play', {
            state: { startDate: tempStartDate }
        });
    };

    const handleUpdateEndDate = () => {
        setEndDate(tempEndDate);
        setShowEndDatePicker(false);

        // Navigate and pass both start and end date in the state
        navigate('/proof-of-play', {
            state: {
                startDate: tempStartDate,
                endDate: tempEndDate
            }
        });
    };
    const handleMonthButtonClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    if (redirect) {
        return <Navigate to={redirect} />;
    }

    // ProofOfPlayDate Component

    const handleInventoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newInventoryId = event.target.value;


        let selectedBillboardId = newInventoryId === "all" ? "" : newInventoryId;

        setSelectedInventoryId(newInventoryId);


        localStorage.setItem('selectedInventoryId', newInventoryId);
        localStorage.setItem('billboardId', selectedBillboardId);


        navigate('/proof-of-play', {
            state: {
                ...userData,
                newInventoryId: selectedBillboardId,
            }
        });
    };




    return (
        <>
            <h4 className="proof-play-header">Proof of play</h4>
            <div className="row">
                <div className="col-md-3">
                    <label htmlFor="billboard" className="form-label proof-data-2">Select Billboard</label>
                    {loadingData ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: '40vh' }}>
                            <span className="loading-circle sp1">
                                <span className="loading-circle sp2">
                                    <span className="loading-circle sp3"></span>
                                </span>
                            </span>
                        </div>
                    ) : errorData ? (
                        <div className="error-message">{errorData}</div>
                    ) : (
                        <div className="input-group proof_input_group_1 card_date_start">
                            {proofValues && proofValues.campaignInventories && proofValues.campaignInventories.length > 0 && (
                                <select
                                    className="form-select"
                                    onChange={handleInventoryChange}
                                    value={selectedInventoryId}  // Ensure this is set correctly
                                >
                                    <option value="" disabled>Select an inventory</option>
                                    <option value="all">All Inventories</option>
                                    {proofValues.campaignInventories.map((inventory) => (
                                        <option key={inventory.id} value={inventory.id}>
                                            {inventory.inventoryName}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                    )}

                    <span className="border-bottom"></span>
                </div>
                <div className="col-md-3 card_date_start d-flex align-items-center position-relative">
                    <div onClick={toggleStartDatePicker} className="calendar-icon-wrapper">
                        <img alt="Start Date" src={Image1} className="date-icon" />
                    </div>
                    <div className="date-text">
                        <p>{format(startDate, "dd MMM yyyy")}</p>
                    </div>
                    {showStartDatePicker && (
                        <div className="datepicker-wrapper">
                            <div className="close-icon" onClick={toggleStartDatePicker}>
                                ×
                            </div>
                            <p className="card_date_header">Select Campaign start date</p>
                            {showYearPicker ? (
                                <div className="year-picker">
                                    <div className="years-grid">
                                        {Array.from({ length: 10 }, (_, i) => (
                                            <button
                                                key={i}
                                                onClick={() => handleYearSelection(selectedYear + i)}
                                                className="year-button"
                                            >
                                                {selectedYear + i}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            ) : showMonthPicker ? (
                                <div className="month-picker">
                                    <div className="months-grid">
                                        {Array.from({ length: 12 }, (_, i) => (
                                            <button
                                                key={i}
                                                onClick={() => handleMonthSelection(i)}
                                                className="month-button"
                                            >
                                                {format(new Date(2021, i), "MMM")}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <DatePicker
                                    selected={tempStartDate}
                                    onChange={handleStartDateChange}
                                    inline
                                    renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                        <div className="custom-header">
                                            <button onClick={(e) => { handleMonthButtonClick(e); decreaseMonth(); }}>{"«"}</button>
                                            <span onClick={toggleMonthPicker}>
                                                {format(date, "MMMM")}
                                            </span>
                                            <span onClick={toggleYearPicker}>
                                                {format(date, "yyyy")}
                                            </span>
                                            <button onClick={(e) => { handleMonthButtonClick(e); increaseMonth(); }}>{"»"}</button>
                                        </div>
                                    )}
                                />
                            )}
                            <button className="update-button" onClick={handleUpdateStartDate}>
                                Update
                            </button>
                        </div>
                    )}
                </div>
                <div className="col-md-3 card_date_end d-flex align-items-center position-relative">
                    <div onClick={toggleEndDatePicker} className="calendar-icon-wrapper">
                        <img alt="End Date" src={Image1} className="date-icon" />
                    </div>
                    <div className="date-text">
                        <p>{format(endDate, "dd MMM yyyy")}</p>
                    </div>
                    {showEndDatePicker && (
                        <div className="datepicker-wrapper">
                            <div className="close-icon" onClick={toggleEndDatePicker}>
                                ×
                            </div>
                            <p className="card_date_header">Select Campaign end date</p>
                            {showYearPicker ? (
                                <div className="year-picker">
                                    <div className="years-grid">
                                        {Array.from({ length: 10 }, (_, i) => (
                                            <button
                                                key={i}
                                                onClick={() => handleYearSelection(selectedYear + i)}
                                                className="year-button"
                                            >
                                                {selectedYear + i}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            ) : showMonthPicker ? (
                                <div className="month-picker">
                                    <div className="months-grid">
                                        {Array.from({ length: 12 }, (_, i) => (
                                            <button
                                                key={i}
                                                onClick={() => handleMonthSelection(i)}
                                                className="month-button"
                                            >
                                                {format(new Date(2021, i), "MMM")}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <DatePicker
                                    selected={tempEndDate}
                                    onChange={handleEndDateChange}
                                    inline
                                    renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                        <div className="custom-header">
                                            <button onClick={(e) => { handleMonthButtonClick(e); decreaseMonth(); }}>{"«"}</button>
                                            <span onClick={toggleMonthPicker}>
                                                {format(date, "MMMM")}
                                            </span>
                                            <span onClick={toggleYearPicker}>
                                                {format(date, "yyyy")}
                                            </span>
                                            <button onClick={(e) => { handleMonthButtonClick(e); increaseMonth(); }}>{"»"}</button>
                                        </div>
                                    )}
                                />
                            )}
                            <button className="update-button" onClick={handleUpdateEndDate}>
                                Update
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr className="my-4" />
                </div>
            </div>
        </>
    );
};

export default ProofOfPlayDate;
