import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthLayout from "../../layouts/auth";
import BackIcon from "../../assets/images/back-icon.svg";
import './index.css';
import InvoiceDownload from "../../components/invoice/invoice-download";
import InvoiceTable from "../../components/invoice/invoice-table";
import generatePDF from 'react-to-pdf';


const Invoice = () => {
  const pdfRef = useRef<HTMLDivElement | null>(null);
  const [config, setConfig] = useState<any[]>([]);
  const location = useLocation();
  const campaignId = location.state?.campaignId;

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/json/configJson.json`);
        const data = await response.json();
        const value = data.find((page: any) => page.name === "Terms & conditions");
        setConfig(value.configurations || []);
      } catch (error) {
        console.error("Error fetching config data:", error);
      }
    };

    fetchConfig();
  }, []);

  const isFieldEnabled = (key: string) => {
    const fieldConfig = config.find((field: any) => field.key === key);
    return fieldConfig ? fieldConfig.default : true;
  };

  // Function to download PDF
  const downloadPDF = (filename: string) => {
    if (pdfRef.current) {
      generatePDF(() => pdfRef.current, { filename });
    }
  };

  return (
    <AuthLayout>
      <div className="container">
        <div className="mt-4">
          <div className="navbar-back-btn">
            <img alt="Back" src={BackIcon} />
            <Link to={`${process.env.REACT_APP_BASE_PATH}/my-campaigns`}> Back</Link>
          </div>
        </div>
        <div className="card card-bottom" ref={pdfRef}>
          <h4 className="invoice-header">Invoice</h4>
          {/* <button onClick={() => downloadPDF('invoice.pdf')} aria-label="Download invoice as PDF">
            Download PDF  
          </button> */}
          <InvoiceDownload
            campaignId={campaignId}

            downloadPDF={downloadPDF}
          />
          <InvoiceTable campaignId={campaignId} />
          <div className="row invoice-table-right">
            {isFieldEnabled("download invoice") && (
              <div className="col-md-12 invoice-full-page-right-side terms">
                <h4 className="terms-condtions ">Terms  &  conditions </h4>
                <p className="terms-para">
                  These standard terms & conditions (the “Terms & Conditions”) are entered between Company name  (“Company full name”) & the advertiser referenced the
                  corresponding request for approval order (“Request for Approval Order” or “RFA”).These Terms & Conditions shall be deemed
                  incorporated by reference into any corresponding request for approval submitted by advertiser. The Terms & conditions & request
                  for approval shall be collectively known as the “Agreement”.
                </p>
                <p className="terms-para">
                  By accepting this agreement, the advertiser (the Advertiser”) agrees to be bound by the terms of this agreement.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Invoice;
