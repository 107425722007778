import React, { Component, useEffect, useState } from "react";
import DefaultLayout from "../../layouts/default";
import { Link, Navigate } from "react-router-dom";
import AuthLayout from "../../layouts/auth";
import BackIcon from "../../assets/images/back-icon.svg";
import BillboardLogo from "../../assets/images/DSC_7293-17169.jpg";
import BillboardLogo1 from "../../assets/images/132566552488339083.jpg";
import BillboardLogo2 from "../../assets/images/photo-1639054515827-41fb52f3058d.avif";
import './index.css'
import { GET_PROPERTY_DETAILS, NEGOTIATE_CAMPAIGN_QUERY, NEGOTIATE_CAMPAIGN_SEND_REQUEST, PROPERTY_DETAILS } from "../../Graphql/Queries";
import { useMutation, useQuery } from "@apollo/client";
import noImage from "../../assets/images/no-image.jpg"
import { toast } from "react-toastify";

interface FormState {
    redirect: string | null,
}
interface Currency {
    country: string;
    symbol: string;
    code: string;
}

interface Tax {
    name: string;
    value: number;
    percent: number;
}

interface NegotiationSummaryType {
    status: string;
    summaryReport: number;
}

interface PriceSummary {
    subTotal: number;
    netTotal: number;
    currency?: Currency;  // Make currency optional if it's not guaranteed to be present
    tax: Tax;
    pcrEnable: boolean;
    summaryReport: number;
    status: string;
}


type NegotiationSummary = {
    mediaOwnerPreferredPrice: {
        totalPrice: number;
        status: string;
    };

};

interface Billboard {
    inventoryId: any;
    map(arg0: (billboardId: any) => {
        billboardId: any; // Use each individual billboardId
        negotiationSummary: { buyerPreferredPrice: { totalPrice: number; savingsPrice: number; savingsPerc: number; nowPayPrice: number; }; mediaOwnerPreferredPrice: { totalPrice: number; savingsPrice: number; savingsPerc: number; nowPayPrice: number; }; contentManagementFeePerc: number; platformFeePerc: number; taxPerc: number; discountPerc: number; customFees: never[]; summaryReport: { potentialViews: number; uniqueReach: number; }; status: string; accountType: string; requestFrom: string; };
    }): unknown;
    priceSummary: any;
    id: number;

    inventoryThumbnailUrl: string | undefined;
    inventoryName: string;
    inventoryType: string;
    negotiationSummary: {
        status: string;
        summaryReport: {
            potentialViews: { toLocaleString: () => string };
            uniqueReach: { toLocaleString: () => string };
        };
        mediaOwnerPreferredPrice: {
            nowPayPrice: any;
            totalPrice: number;
        };
        buyerPreferredPrice: {
            nowPayPrice: any;
            totalPrice: number;
        };
    };
}

interface Inventory {
    negotiationSummary: NegotiationSummary;

}

interface NegotiationDateFormat {
    date: string;
    dateStr: string;
    dateFmt: string;
    __typename: string;
}

interface CampaignData {
    priceSummary?: PriceSummary;
    name?: string;
    summaryReport?: {
        potentialViews?: number;
        uniqueReach?: number;
        averageFrequency?: number;
    };
    // priceSummary?: {
    //     netTotal?: number;
    // };
    duration?: string;
    startDate: NegotiationDateFormat;
    endDate: NegotiationDateFormat;
    date: number;
    status: string;

    // Updated negotiationSummary type to correctly define 'mediaOwnerPreferredPrice'
    negotiationSummary?: {
        mediaOwnerPreferredPrice: {
            totalPrice: number;
        };
    };

    mediaOwnerPreferredPrice?: {
        totalPrice: number;
    };
}


interface PropertyDetails {
    referenceId: string;
    latitude: number;
    longitude: number;
    name: string;
    displayName: string;
    price: Price;
}

interface PropertyResponse {
    propertyDetails: PropertyDetails;
}

interface Price {
    contentFrequencyPerHour: string | null;
    maximumSpotDurationPerDay: string | null;
    minimumSpotDurationPerDay: string | null;
}


const NegotiateCampaign: React.FC = () => {
    const token = localStorage.getItem('authToken');
    const selectedCampaignId = localStorage.getItem('selectedCampaignId');
    const [value, setValue] = useState<CampaignData | null>(null);

    const [inputValue, setInputValue] = useState('');

    const [date, setDate] = useState('')
    const [propertyValue, setPropertyValue] = useState<PropertyDetails | null>(null);

    const companyId = localStorage.getItem('companyId');
    const countryId = localStorage.getItem('countryId');
    const [selectedBillboard, setSelectedBillboard] = useState<Billboard | null>(null);
    const [selectedBillboardType, setSelectedBillboardType] = useState('digital');

    const [redirect, setRedirect] = useState<string | null>(null);






    useEffect(() => {
        if (selectedBillboard?.negotiationSummary?.buyerPreferredPrice?.nowPayPrice !== null) {
            setInputValue(selectedBillboard?.negotiationSummary?.buyerPreferredPrice?.nowPayPrice.toFixed(2));
        }
    }, [selectedBillboard]);


    console.log(selectedCampaignId);

    const { loading, error, data } = useQuery(NEGOTIATE_CAMPAIGN_QUERY, {
        variables: {
            accessToken: token,
            campaignId: selectedCampaignId,
        },
    });
    useEffect(() => {
        if (data && data.negotiateCampaign) {
            setValue(data.negotiateCampaign);
        }
    }, [data]);

    console.log(value, "value");


    const negotiation = data?.negotiateCampaign;

    useEffect(() => {
        if (negotiation?.startDate?.date && negotiation?.endDate?.date) {

            const startDate = new Date(negotiation.startDate.date);
            const endDate = new Date(negotiation.endDate.date);


            if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {

                const differenceInMillis = endDate.getTime() - startDate.getTime();

                // Convert milliseconds to days
                const totalDays = Math.ceil(differenceInMillis / (1000 * 3600 * 24));

                setDate(`${totalDays}`);
            } else {
                console.error('Invalid startDate or endDate');
            }
        }
    }, [negotiation]);

    const isMatchingCampaign = negotiation && negotiation?.id === selectedCampaignId;
    console.log(isMatchingCampaign, "isMatchingCampaign");
    const digitalBillboards: Billboard[] = isMatchingCampaign
        ? negotiation?.campaignInventories.filter((inventory: Billboard) => inventory.inventoryType === 'digital')
        : [];
    const classicBillboards: Billboard[] = isMatchingCampaign
        ? negotiation?.campaignInventories.filter((inventory: Billboard) => inventory.inventoryType === 'classic')
        : [];


    // Multiple to Total ad plays value

    const duration = parseInt(date, 10);

    const totalAdPlays =
        !isNaN(duration) && typeof propertyValue?.price?.contentFrequencyPerHour === 'number'
            ? duration * propertyValue.price.contentFrequencyPerHour
            : 'N/A';



    // Property details Query

    const { data: property, refetch } = useQuery(PROPERTY_DETAILS, {
        variables: {
            accessToken: token,
            id: selectedBillboard?.inventoryId,
            companyId: companyId,
            countryId: countryId,
            billboardType: selectedBillboardType,

        },
        skip: !token,
    });

    console.log(property, "property");


    //   useEffect(() => {
    //     if (property && property?.propertyDetails) {
    //         setPropertyValue(property?.propertyDetails);
    //     }
    // }, [property]);

    console.log(propertyValue, "value");

    // Duration calculation function
    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        // Check for invalid dates
        if (isNaN(date.getTime())) {
            return dateString;
        }
        // Define the options with the correct types
        const options: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        };
        return date.toLocaleDateString('en-GB', options);
    }

    useEffect(() => {
        if (property && property.propertyDetails) {
            setPropertyValue(property.propertyDetails);
        }
    }, [property]);

    const handleBillboardClick = (inventory: any) => {
        setSelectedBillboard(inventory);
        console.log(inventory, "sele");

        refetch({
            accessToken: token,
            id: inventory.inventoryId,
            companyId: companyId,
            countryId: countryId,
            billboardType: selectedBillboardType,
        });



        setSelectedBillboardType(inventory.inventoryType)
        setInputValue(inventory.negotiationSummary?.buyerPreferredPrice?.nowPayPrice || 'N/A');
    };



    const handleInputChange = (e: { target: { value: string } }) => {
        // Remove "AUD " from the input if present
        let value = e.target.value.replace('AUD ', '').trim();

        // Ensure that the value is a valid number or fallback to a default value (e.g., 0)
        const numericValue = parseFloat(value)



        // If the value is not a valid number, we will set it to an empty string or a fallback
        if (isNaN(numericValue)) {
            setInputValue('');
        } else {
            setInputValue(value);
        }
    };

    const [negotiate, { loading: sendRequest, error: sendError }] = useMutation(NEGOTIATE_CAMPAIGN_SEND_REQUEST);

    const handleClick = async () => {
        const token = localStorage.getItem('authToken');
        const selectedCampaignId = localStorage.getItem('selectedCampaignId');

        // Check if selectedBillboard is not empty or undefined
        if (!selectedBillboard || !selectedBillboard.inventoryId) {
            console.warn('No valid billboard selected');
            return;
        }

        // Ensure inputValue is a valid number
        const nowPayPrice = parseFloat(inputValue);

        // If the value is NaN, we can either default to a value like 0 or show an error
        if (isNaN(nowPayPrice)) {
            console.error('Invalid input value for nowPayPrice');
            return;  // Optionally show an error to the user
        }

        const duration = parseInt(date, 10);

        console.log(duration);

        if (isNaN(duration) || duration < 0) {
            console.error('Invalid value for duration');
            return;
        }

        const negotiationData = {
            duration: duration,

            billboards: negotiation.campaignInventories.map((billboard: any) => {
                const billboardId = billboard.inventoryId; // Extract the billboardId from the current object

                const nowPay = (billboardId === selectedBillboard.inventoryId)
                    ? nowPayPrice  // Value from elsewhere, e.g., a selected input or API call
                    : billboard.negotiationSummary.buyerPreferredPrice.nowPayPrice;

                console.log('Selected Billboard:', selectedBillboard.inventoryId);
                console.log('Billboard ID:', billboardId);
                console.log('nowPayPrice:', nowPayPrice); // log the value of nowPayPrice
                console.log('Buyer Preferred Price nowPayPrice:', billboard.negotiationSummary.buyerPreferredPrice.nowPayPrice);

                return {
                    billboardId: billboardId,
                    negotiationSummary: {
                        buyerPreferredPrice: {
                            totalPrice: 643,
                            savingsPrice: 0,
                            savingsPerc: 0,
                            dailyPrice: null,
                            nowPayPrice: nowPay,
                        },
                        mediaOwnerPreferredPrice: {
                            totalPrice: 643,
                            savingsPrice: 0,
                            savingsPerc: 0,
                            dailyPrice: 0,
                            nowPayPrice: 643,
                        },
                        contentManagementFeePerc: 0,
                        platformFeePerc: 15,
                        taxPerc: 20,
                        discountPerc: 0,
                        customFees: [],
                        summaryReport: {
                            potentialViews: 186240,
                            uniqueReach: 34612,
                        },
                        status: "PENDING",
                        accountType: "EXTERNAL",
                        requestFrom: "",
                    },
                };
            }),
        };

        try {
            const { data, errors } = await negotiate({
                variables: { negotiationData, accessToken: token, campaignId: selectedCampaignId }
            });

            // Check for errors from the GraphQL response
            if (errors) {
                console.error('GraphQL Errors:', errors);
            } else {
                // Check if the negotiation was successful
                const negotiationResponse = data.negotiate?.response;
                if (negotiationResponse?.success) {

                    toast.success(negotiationResponse.message);
                    setRedirect('/my-campaigns');
                } else {
                    console.error('Negotiation failed:', negotiationResponse?.message || 'Unknown error');
                    toast.error('Negotiation failed. Please try again.');
                }
            }
        } catch (error) {
            console.error('Network or unexpected error:', error);
            alert('An error occurred. Please try again.');
        }
    };


    if (redirect) {
        return <Navigate to={redirect} />;
    }

    return (
        <AuthLayout>
            <div className="container">
                <div className="mt-4">
                    <div className="navbar-back-btn"><img alt="" src={BackIcon} /> <Link to={`${process.env.REACT_APP_BASE_PATH}/my-campaigns`} >Back</Link></div>
                </div>

                <div className="card card-bottom p-4">
                    {loading ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: '40vh' }}>
                            <span className="loading-circle sp1">
                                <span className="loading-circle sp2">
                                    <span className="loading-circle sp3"></span>
                                </span>
                            </span>
                        </div>
                    ) : negotiation.length === 0 ? (
                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                            <h5>No data found</h5>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-md-8 invoice-full-page-right-side">
                                <div>
                                    <div className="row">
                                        <h4 className="col-12 negotiate-header mb-4">Negotiate Campaign</h4>

                                        {isMatchingCampaign && (
                                            <div className="row negotiate-campaign-container">
                                                <div className="col-md-2">
                                                    <p className="negotiate-field-name">Campaign Name (?)</p>
                                                    <p className="negotiate-field-value">{value?.name || 'No name available'}</p>
                                                </div>

                                                <div className="col-md-2">
                                                    <p className="negotiate-field-name">Potential Views (?)</p>
                                                    <p className="negotiate-field-value">
                                                        {value?.summaryReport?.potentialViews?.toLocaleString() || 'N/A'}
                                                    </p>
                                                </div>

                                                <div className="col-md-2">
                                                    <p className="negotiate-field-name">Unique Reach (?)</p>
                                                    <p className="negotiate-field-value">
                                                        {value?.summaryReport?.uniqueReach?.toLocaleString() || 'N/A'}
                                                    </p>
                                                </div>

                                                <div className="col-md-2">
                                                    <p className="negotiate-field-name">Average Frequency (?)</p>
                                                    <p className="negotiate-field-value">
                                                        {value?.summaryReport?.averageFrequency?.toFixed(2) || 'N/A'}
                                                    </p>
                                                </div>

                                                <div className="col-md-2">
                                                    <p className="negotiate-field-name">Duration (?)</p>
                                                    <p className="negotiate-field-value">
                                                        {value?.startDate?.date && value?.endDate?.date
                                                            ? `${formatDate(value.startDate.date)} to ${formatDate(value.endDate.date)}`
                                                            : 'N/A'}
                                                    </p>
                                                </div>

                                                <div className="col-md-2">
                                                    <p className="negotiate-field-name">Net Total (?)</p>
                                                    <p className="negotiate-field-value">
                                                        {value?.priceSummary?.currency?.code}
                                                        {value?.negotiationSummary?.mediaOwnerPreferredPrice?.totalPrice !== undefined
                                                            ? `${value?.negotiationSummary?.mediaOwnerPreferredPrice.totalPrice.toFixed(2)}`
                                                            : 'N/A'}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>




                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <hr className="my-4 nego-card_border_1" />
                                    </div>
                                </div>

                                <h4 className="nogosition_list_header">{digitalBillboards.length} Billboards</h4>

                                <div className="negosition_table_container" id="style">
                                    <h6 className="nogosition_list_header_1">Digital billboards <span className="negosition_header_notification badge">{digitalBillboards.length}</span></h6>
                                    <div className="table-scroll">
                                        <table className="table table-striped">
                                            <thead className="table-head">
                                                <tr>
                                                    <th scope="col" className="negosition_list_first_header">Billboard Name</th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                    {/* <th scope="col" className="negosition_list_first_header">Impressions</th> */}
                                                    <th scope="col" className="negosition_list_first_header">Impressions</th>
                                                    <th scope="col" className="negosition_list_first_header">Unique Reach</th>
                                                    <th scope="col" className="negosition_list_first_header">Actual Price</th>
                                                    <th scope="col" className="negosition_list_first_header">Preferred Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {digitalBillboards.length > 0 ? (
                                                    digitalBillboards.map((inventory: Billboard) => {
                                                        const currencyCode = inventory?.priceSummary?.currency.code || 'AUD'; // Default to AUD if undefined

                                                        return (
                                                            <tr key={inventory.id} onClick={() => handleBillboardClick(inventory)}>
                                                                <td>
                                                                    <img className="invoice-table-img negosition_list_img" src={inventory?.inventoryThumbnailUrl} />
                                                                </td>
                                                                <td>
                                                                    <b className="table-Sunnybank negosition_list_sunny">{inventory?.inventoryName}</b>
                                                                    <p className="table-available negosition_text_avail">
                                                                        {inventory?.negotiationSummary?.status || "Status not available"}
                                                                    </p>
                                                                </td>
                                                                <td></td>
                                                                {/* <td className="negosition_value_imp">{inventory?.inventoryType}</td> */}
                                                                <td className="negosition_value_imp">
                                                                    {inventory.negotiationSummary?.summaryReport?.potentialViews?.toLocaleString() ?? 'N/A'}
                                                                </td>
                                                                <td className="negosition_value_imp">
                                                                    {inventory.negotiationSummary?.summaryReport?.uniqueReach?.toLocaleString() ?? 'N/A'}
                                                                </td>
                                                                <td className="negosition_value_imp">
                                                                    AUD {inventory.negotiationSummary?.mediaOwnerPreferredPrice?.totalPrice.toFixed(2)}
                                                                </td>

                                                                <td className="negosition_value_imp">
                                                                    {selectedBillboard && selectedBillboard.id === inventory.id ? (
                                                                        inputValue ? (
                                                                            `${currencyCode} ${inputValue}`
                                                                        ) : (
                                                                            'N/A'
                                                                        )
                                                                    ) : (
                                                                        inventory?.negotiationSummary?.buyerPreferredPrice?.nowPayPrice != null ? (
                                                                            `${currencyCode} ${inventory?.negotiationSummary?.buyerPreferredPrice?.nowPayPrice.toFixed(2)}`
                                                                        ) : (
                                                                            'N/A'
                                                                        )
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan={8} className="text-center">No digital billboards available.</td>
                                                    </tr>
                                                )}
                                            </tbody>



                                        </table>
                                    </div>

                                    <h6 className="digital-billboards">Classic billboards <span className="negosition_header_notification badge">{classicBillboards.length}</span></h6>
                                    <div className="table-scroll">
                                        <table className="table table-striped">
                                            <thead className="table-head">
                                                <tr>
                                                    <th scope="col" className="negosition_list_first_header">Billboard Name</th>
                                                    <th scope="col" className="negosition_list_first_header"></th>
                                                    <th scope="col" className="negosition_list_first_header"></th>
                                                    {/* <th scope="col" className="negosition_list_first_header">Impressions</th> */}
                                                    <th scope="col" className="negosition_list_first_header">Impressions</th>
                                                    <th scope="col" className="negosition_list_first_header">Unique Reach</th>
                                                    <th scope="col" className="negosition_list_first_header">Actual Price</th>
                                                    <th scope="col" className="negosition_list_first_header">Preferred Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {classicBillboards.length > 0 ? (
                                                    classicBillboards.map((inventory: Billboard) => {
                                                        const currencyCode = inventory?.priceSummary?.currency.code || 'AUD'; // Default to AUD if undefined

                                                        return (
                                                            <tr key={inventory.id} onClick={() => handleBillboardClick(inventory)}>
                                                                <td>
                                                                    <img className="invoice-table-img negosition_list_img" src={inventory?.inventoryThumbnailUrl} />
                                                                </td>
                                                                <td>
                                                                    <b className="table-Sunnybank negosition_list_sunny">{inventory.inventoryName}</b>
                                                                    <p className="table-available negosition_text_avail">
                                                                        {inventory.negotiationSummary?.status || "Status not available"}
                                                                    </p>
                                                                </td>
                                                                <td></td>
                                                                {/* <td className="negosition_value_imp">{inventory.inventoryType}</td> */}
                                                                <td className="negosition_value_imp">
                                                                    {inventory?.negotiationSummary?.summaryReport?.potentialViews?.toLocaleString() ?? 'N/A'}
                                                                </td>
                                                                <td className="negosition_value_imp">
                                                                    {inventory?.negotiationSummary?.summaryReport?.uniqueReach?.toLocaleString() ?? 'N/A'}
                                                                </td>
                                                                <td className="negosition_value_imp">
                                                                    AUD {inventory?.negotiationSummary?.mediaOwnerPreferredPrice?.totalPrice.toFixed(2)}
                                                                </td>

                                                                <td className="negosition_value_imp">
                                                                    {selectedBillboard && selectedBillboard.id === inventory.id ? (
                                                                        inputValue ? (
                                                                            `${currencyCode} ${inputValue}`
                                                                        ) : (
                                                                            'N/A'
                                                                        )
                                                                    ) : (
                                                                        inventory.negotiationSummary?.buyerPreferredPrice?.nowPayPrice != null ? (
                                                                            `${currencyCode} ${inventory?.negotiationSummary?.buyerPreferredPrice?.nowPayPrice.toFixed(2)}`
                                                                        ) : (
                                                                            'N/A'
                                                                        )
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan={8} className="text-center">No digital billboards available.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div>
                                    <div className="negosition_right_side_container_1">
                                        <div className="row">
                                            <div style={{ textAlign: 'center' }} className="col-md-12">
                                                <p>Sports / Hour : {propertyValue?.price?.contentFrequencyPerHour ?? 'N/A'}</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 negosition_right_inside_container_1">
                                                    <p>Daily Ad Plays: {
                                                        Number(propertyValue?.price?.contentFrequencyPerHour) * 24 || 'N/A'
                                                    }</p>


                                                    <p>Total Ad Plays : {totalAdPlays}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="negosition_right_side_container_1">
                                        <div className="row">
                                            <div className="d-flex col-md-12">
                                                <div>
                                                    {selectedBillboard ? (
                                                        <img
                                                            className="invoice-table-img"
                                                            src={selectedBillboard?.inventoryThumbnailUrl}
                                                            alt="Billboard Logo"
                                                        />
                                                    ) : (
                                                        <img className="invoice-table-img" src={noImage} alt="No Images Seleted" />
                                                    )}
                                                </div>
                                                <div className="negosition_side_container_img">
                                                    <p>Billboard name: {selectedBillboard ? selectedBillboard?.inventoryName : 'N/A'}</p>
                                                    <p>
                                                        Actual price: AUD{' '}
                                                        {selectedBillboard
                                                            ? selectedBillboard?.negotiationSummary?.mediaOwnerPreferredPrice?.totalPrice?.toFixed(2)
                                                            : 'N/A'}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 negosition_side_container_con_2">
                                                    <p>Preferred price</p>
                                                    <p className="negotiation_side_container_con_3">
                                                        <div className="input-container">
                                                            <span>AUD</span>
                                                            <input
                                                                className="nego-input"
                                                                value={inputValue}
                                                                type="text"
                                                                onChange={handleInputChange}
                                                                placeholder="Enter amount"
                                                            />
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>
                                            <p className="mt-2 media-owner">Media Owner Feedback</p>
                                            <div className="row">
                                                <div className="col-md-12 negosition_side_container_con_2">
                                                    <p>
                                                        {selectedBillboard && selectedBillboard?.negotiationSummary?.mediaOwnerPreferredPrice?.nowPayPrice !== null
                                                            ? `AUD ${selectedBillboard?.negotiationSummary?.mediaOwnerPreferredPrice?.nowPayPrice.toFixed(2)}`
                                                            : 'Media Owner Counter Offer Will Be Shown Here'}
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer border-0 gap-4">
                                {/* <button type="button" className="btn negosition_footer_btn_1 mt-4" disabled>Save draft</button> */}
                                <button
                                    type="button"
                                    className="btn btn-primary negosition_footer_btn_2 mt-4"
                                    onClick={handleClick}
                                    disabled={loading}
                                >
                                    {sendRequest ? 'Sending...' : 'Send request'}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </AuthLayout >
    );
}

export default NegotiateCampaign;
